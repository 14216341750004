var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login wrapper section" },
    [
      _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
        _vm._v(
          _vm._s(
            _vm.$root.$data.store.mode === "create"
              ? "Create an account or login"
              : "Login to your account"
          )
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-in", mode: "out-in" } }, [
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loggedIn === false,
                expression: "loggedIn === false"
              }
            ],
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.checkLogin($event)
              }
            }
          },
          [
            _c("TextInput", { attrs: { slim: true } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Email address")
                ])
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                class: [
                  "input--underlined dusk-email",
                  "margin-bottom-30",
                  !_vm.isEmail ? "input--invalid" : ""
                ],
                style: { textTransform: "lowercase" },
                attrs: {
                  type: "text",
                  value: "",
                  autofocus: "",
                  tabindex: "1"
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              })
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-in", mode: "out-in" } }, [
        _vm.loggedIn === true
          ? _c("p", { staticClass: "slim text-center" }, [
              _vm._v(
                "You are logged in as " +
                  _vm._s(this.$root.$data.store.customer.first_name) +
                  " " +
                  _vm._s(this.$root.$data.store.customer.last_name) +
                  "!"
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-in", mode: "out-in" } }, [
        _vm.loggedIn === true
          ? _c(
              "a",
              {
                staticClass: "btn btn--grey btn--block btn--reset",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.logout($event)
                  }
                }
              },
              [_vm._v("Login as another user")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "ButtonFixed",
        {
          attrs: {
            loading: _vm.loading,
            active: _vm.nextButtonActive,
            clickEvent: _vm.checkLogin
          }
        },
        [_vm._v("Continue")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6281fdff", { render: render, staticRenderFns: staticRenderFns })
  }
}
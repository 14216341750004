var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: ["btn-list-item", _vm.gridClass],
      attrs: { href: "#", disabled: true },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.clickEvent(_vm.clickEventParams)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-75b91716", { render: render, staticRenderFns: staticRenderFns })
  }
}
import moment from 'moment'

export default {
  debug: true,
  state: {
    loggedIn: false,
  },
  customer: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    postcode: ''
  },
  ticket: {
    price: '40.00',
    entryDate: moment(),
    exitDate: moment().add(2, 'hour'),
    registration: 'BP65 OZE',
  },
  card: {
    cardName: 'Joe Bloggs',
    cardNumber: '4242424242424242',
    cardExpiryMonth: '08',
    cardExpiryYear: '2019',
    cardCVC: '123',
    cardPostcode: 'LS2 7ES'
  },
}

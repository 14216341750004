var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "car-park section mb-20" },
    [
      _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
        _vm._v("Enter your driver(s)")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drivers xs:w-full micro:W-2/3 m-auto" },
        [
          _vm._l(_vm.drivers, function(driver, index) {
            return _c("div", { key: index, staticClass: "flex mb-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: driver.driver,
                    expression: "driver.driver"
                  }
                ],
                staticClass:
                  "w-4/6 border-t-0 border-l-0 border-r-0 border-solid border-b mr-4 border-turquoise text-center xs:text-meta micro:text-sm",
                attrs: { type: "text", placeholder: "Driver's Name" },
                domProps: { value: driver.driver },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(driver, "driver", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: driver.registration,
                    expression: "driver.registration"
                  }
                ],
                staticClass:
                  "w-2/6 border-t-0 border-l-0 border-r-0 border-solid border-b mr-4 border-turquoise uppercase text-center xs:text-meta micro:text-sm",
                attrs: { type: "text", placeholder: "Reg Number" },
                domProps: { value: driver.registration },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(driver, "registration", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "bg-white w-1/30 flex flex-column p-0 justify-center hover:opacity-80",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.remove(index)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "w-full",
                    attrs: { src: "/img/red-cross.svg" }
                  })
                ]
              )
            ])
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "xs:w-full micro:w-1/2 text-white p-3 rounded-sm font-bold block mx-auto bg-blue xs:mt-10 micro:mt-16 hover:opacity-90 text-sm",
              attrs: { type: "submit" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.add($event)
                }
              }
            },
            [_vm._v("Add Another Driver")]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ButtonFixed",
        {
          attrs: {
            loading: _vm.loading,
            active: _vm.nextButtonActive,
            clickEvent: _vm.next
          }
        },
        [_vm._v("Continue")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-06509361", { render: render, staticRenderFns: staticRenderFns })
  }
}
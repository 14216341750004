<template>
  <div
    :class="[
      'payment section',
      $root.$data.store.mode === 'season-ticket' ? 'mx-3' : ''
    ]"
  >
    <h1 class="text-blue xs:text-lg micro:text-xl">Choose a payment method</h1>
    <ButtonList v-if="cards" gridClass="grid--one">
      <ButtonListItem
        :key="card.id"
        v-for="(card, index) in cards"
        :clickEvent="select"
        :clickEventParams="card"
        :gridClass="
          'grid--one--item' +
            (!cardValid(card.card) ? ' credit-card--invalid' : '') +
            ' cards' +
            ' dusk-card-' +
            index
        "
      >
        <div :class="['credit-card']">
          <div class="credit-card--brand">
            <img
              :src="
                '/img/' +
                  card.card.brand.replace('_', '-').toLowerCase() +
                  '.svg'
              "
              alt
            />
          </div>
          <div class="credit-card--last4">
            &bullet;&bullet;&bullet;&bullet; &bullet;&bullet;&bullet;&bullet;
            &bullet;&bullet;&bullet;&bullet; {{ card.card.last4 }}
          </div>
          <div class="clear"></div>
        </div>
      </ButtonListItem>
      <ButtonListItem
        v-if="applePayEnabled"
        :clickEvent="select"
        clickEventParams="apple"
        gridClass="grid--one--item"
      >
        <div :class="['credit-card']">
          <div class="credit-card--brand">
            <img src="/img/apple-pay.svg" alt />
          </div>
          <div class="credit-card--last4">Apple Pay</div>
          <div class="clear"></div>
        </div>
      </ButtonListItem>
    </ButtonList>
    <ButtonFixed buttonClass="cards" :active="true" :clickEvent="addPayment"
      >Add a new payment card</ButtonFixed
    >
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import ButtonList from "../objects/ButtonList.vue";
import ButtonListItem from "../objects/ButtonListItem.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import AutoFill from "../../store/autofill.js";
import moment from "moment";

export default {
  mounted() {
    this.$root.$data.store.setObject("progress", 6);
  },
  created() {
    if (this.$root.$data.store.autoFill) this.autoFill();

    if (
      this.$root.$data.store.carParks.length === 0 &&
      this.$root.$data.store.mode === "create"
    ) {
      this.$router.push({ name: "location" });
    }
  },
  computed: {
    seasonTicket: {
      get() {
        return this.$root.$data.store.seasonTicket;
      },
      set(value) {
        this.$root.$data.store.setObject("seasonTicket", value);
      }
    },
    ticketType() {
      return this.$root.$data.store.state.ticketType;
    },
    cards: {
      get() {
        return this.$root.$data.store.customer.cards;
      }
    },
    addPaymentRedirect: {
      get() {
        return this.$root.$data.store.state.addPaymentRedirect;
      },
      set(value) {
        this.$root.$data.store.setField("state", "addPaymentRedirect", value);
      }
    }
  },
  data() {
    return {
      applePayEnabled: false,
      applePay: false
    };
  },
  components: {
    SectionTitle,
    TextInput,
    ButtonFixed,
    ButtonList,
    ButtonListItem
  },
  methods: {
    select(card) {
      if (card === "apple") {
        this.applePay = true;
      }

      if (!this.cardValid(card.card)) {
        return;
      }

      this.$root.$data.store.setField("ticket", "card", card);

      if (this.$root.$data.store.mode === "ticket") {
        this.$parent.$data.confirming = true;
        this.$parent.$data.view = "ticket";
      } else if (this.$root.$data.store.mode == "season-ticket") {
        this.$router.push({ name: "seasonTicket" });
      } else {
        if (this.ticketType === "prebook") {
          this.$router.push({ name: "confirmation" });
        } else {
          this.$router.push({ name: "confirmation" });
        }
      }
    },
    addPayment() {
      this.addPaymentRedirect = "payment";

      if (this.$root.$data.store.mode === "ticket") {
        this.$parent.$data.view = "add-payment";
      } else {
        this.$router.push({ name: "addPayment" });
      }
    },
    cardValid(card) {
      const now = moment();
      const expiry = moment(
        `${card.exp_year}-${
          card.exp_month < 10 ? "0" + card.exp_month : card.exp_month
        }-01 00:00:00`
      ).add(1, "months");

      return !expiry.isBefore(now);
    },
    autoFill() {
      /*
            this.$root.$data.store.setField('customer', 'cards', [
                {id: 1, brand: 'visa', last4: '4567'},
                {id: 2, brand: 'mastercard', last4: '4983'},
                {id: 3, brand: 'amex', last4: '3423'},
            ])*/
    }
  }
};
</script>

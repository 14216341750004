var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input--checkbox",
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleCheck($event)
        }
      }
    },
    [_c("span", { style: { opacity: _vm.display } }, [_vm._v("✓")])]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8c954cd6", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div v-if="!isSelectingWithUrlParam" class="location section">
    <h1 class="text-blue xs:text-lg micro:text-xl">Choose City</h1>
    <ButtonList v-if="locations && Object.keys(locations).length > 0">
      <ButtonListItem
        :gridClass="'grid--item' + ' dusk-location-' + location.id"
        :key="location.id"
        v-for="location in locations"
        :clickEvent="select"
        :clickEventParams="location"
      >
        <span>{{ location.name }}</span>
      </ButtonListItem>
    </ButtonList>
    <div class="text-center" v-else>
      <p>Sorry, there are no available locations to choose from.</p>
    </div>
  </div>
</template>

<script>
import ButtonList from '../objects/ButtonList.vue';
import ButtonListItem from '../objects/ButtonListItem.vue';
import SectionTitle from '../objects/SectionTitle.vue';
import Vue from 'vue';

export default {
  components: {
    ButtonList,
    ButtonListItem,
    SectionTitle,
  },
  data() {
    return {
      isSelectingWithUrlParam: true,
      locations: [],
    };
  },
  computed: {
    ticketType: {
      get() {
        return this.$root.$data.store.state.ticketType;
      },
    },
    location: {
      get() {
        return this.$root.$data.store.ticket.location;
      },
    },
  },
  watch: {
    locations: function () {
      this.selectWithUrlParam();
    },
  },
  created() {
    this.fetchLocations();
  },
  mounted() {
    this.$root.$data.store.setObject('progress', 1);
  },
  methods: {
    fetchLocations() {
      Vue.http
        .get(
          `/locations?is_${
            this.ticketType === 'prebook' ? 'prebooking' : 'season'
          }=1`
        )
        .then((response) => {
          this.locations = Object.values(response.body);
        })
        .catch((error) => console.log(error));
    },
    select(location) {
      this.$root.$data.store.setLocation(location);
      this.$router.push({ name: 'carPark', params: { location: location.id } });
    },
    selectWithUrlParam() {
      const urlParams = new URLSearchParams(window.location.search);



      const location = this.locations.find(
        (location) => String(location.id) === urlParams.get('location')
      );

      if (location !== undefined && this.location === null) {
        this.select(location);
      } else {
        this.isSelectingWithUrlParam = false;
      }
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-header mb-10" }, [
    !_vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "w-full border-t border-solid border-dull-blue relative"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "py-12 lg:py-12 py-6 lg:px-0 micro:px-0 xs:px-6 w-full flex space-between items-center bg-gray-100"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "max-w-5xl micro:w-2/3 xs:w-full m-auto sm:flex sm:justify-between sm:items-center"
                  },
                  [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "xs:mb-6 sm:mb-0 lg:text-welcome sm:text-lg xs:text-md"
                      },
                      [
                        _vm._v("\n          Welcome back,\n          "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "md:text-lg xs:text-welcome lg:text-welcome xs:block sm:inline sm:text-lg"
                          },
                          [_vm._v(_vm._s(_vm.customer.first_name))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "self-center" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "text-center sm:inline xs:block sm:w-auto bg-blue text-white text-xs micro:px-20 xs:mb-3 micro:mb-0 no-underline py-3 rounded-sm font-bold self-center hover:opacity-90",
                          attrs: { href: "/" }
                        },
                        [_vm._v("Book now")]
                      ),
                      _vm._v(" "),
                      _vm.customer.citipass === 1
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "text-center sm:inline xs:block sm:w-auto bg-gray-800 text-white text-xs micro:px-6 no-underline py-3 rounded-sm font-bold self-center hover:opacity-90",
                              attrs: { href: "/my-account/citipass" }
                            },
                            [_vm._v("CitiPass")]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lg:w-full lg:mt-10 lg:p-0 micro:p-0 xs:p-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "max-w-5xl micro:w-2/3 xs:w-full m-auto flex md:flex-row md:flex-row flex-col flex-col-reverse justify-between"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md:w-5/12 md:border-r md:border-solid md:border-gray-200 lg:pr-16 md:pr-8"
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "xs:mb-4 sm:mb-4 md:mt-0 xs:mt-6 text-lg"
                          },
                          [_vm._v("Payment Methods")]
                        ),
                        _vm._v(" "),
                        _c(
                          "transition-group",
                          {
                            attrs: {
                              appear: "",
                              mode: "out-in",
                              name: "fade-in"
                            }
                          },
                          _vm._l(_vm.cards, function(card, index) {
                            return _c(
                              "div",
                              {
                                key: card.id,
                                staticClass:
                                  "flex justify-between items-center mb-2 bg-gray-400 p-3 rounded-sm"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-2/3 flex items-center" },
                                  [
                                    _c("img", {
                                      staticClass: "w-8 mr-2",
                                      attrs: {
                                        src:
                                          "/img/" +
                                          card.card.brand
                                            .replace("_", "-")
                                            .toLowerCase() +
                                          ".svg",
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "xs:text-meta tiny:text-sm font-bold lg:text-xs md:text-meta"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "capitalize" },
                                          [_vm._v(_vm._s(card.card.brand))]
                                        ),
                                        _vm._v(
                                          "\n                  ending in\n                  " +
                                            _vm._s(card.card.last4) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    class: [
                                      _vm.loading ? "opacity-50" : "",
                                      "leading-normal rounded-sm px-2 py-1 bg-bright-red text-white text-tiny font-bold uppercase hover:bg-red-600 " +
                                        "dusk-remove-card-" +
                                        index
                                    ],
                                    on: {
                                      click: function($event) {
                                        return _vm.removeCard(card)
                                      }
                                    }
                                  },
                                  [_vm._v("Remove")]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "dusk-add-new-card bg-blue rounded-sm pr-2 p-3 w-full block flex space-between text-white font-bold justify-between items-center hover:opacity-90",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.addPayment($event)
                              }
                            }
                          },
                          [
                            _vm._m(0),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "w-4 h-auto",
                              attrs: { src: "/img/plus.svg" }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "md:w-7/12 lg:pl-16 md:pl-8 mt-6 md:mt-0"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md:flex justify-between mb-3 items-center"
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass: "sm:mb-3 md:mb-0 lg:mb-0 text-lg"
                              },
                              [_vm._v("Season Tickets")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "-ml-1 text-sm md:flex justify-between"
                              },
                              _vm._l(_vm.seasonTicketFilters, function(
                                seasonTicketFilter
                              ) {
                                return _c(
                                  "button",
                                  {
                                    key: seasonTicketFilter.key,
                                    class: [
                                      _vm.activeSeasonTicketFilter.key ===
                                      seasonTicketFilter.key
                                        ? "uppercase rounded-sm bg-turquoise border-solid border-2 border-turquoise text-white text-micro font-bold px-2 py-1 ml-1"
                                        : "bg-white uppercase rounded-sm border-solid border-2 border-blue text-micro font-bold text-blue px-2 py-1 ml-1"
                                    ],
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.activeSeasonTicketFilter = seasonTicketFilter
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(seasonTicketFilter.label))]
                                )
                              }),
                              0
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "transition",
                          { attrs: { mode: "out-in", name: "fade-in" } },
                          [
                            _vm.seasonTicketsFiltered &&
                            _vm.seasonTicketsFiltered.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(_vm.seasonTicketsFiltered, function(
                                    seasonTicket
                                  ) {
                                    return _c(
                                      "a",
                                      {
                                        key: seasonTicket.id,
                                        class: [
                                          "xs:w-full xs:flex-col-reverse micro:flex-row block text-white flex  p-4 mb-2 items-center rounded-sm hover:opacity-90 xs:text-center tiny:text-left",
                                          !_vm.pastRenewal(
                                            seasonTicket.renewal_date
                                          )
                                            ? "bg-active-green"
                                            : "bg-red-500"
                                        ],
                                        attrs: {
                                          href:
                                            "/my-account/season-ticket/" +
                                            seasonTicket.id
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "xs:mx-auto micro:mx-0 tiny:w-1/4 xs:w-1/2 micro:w-1/4 xs:mr-auto micro:mr-8"
                                          },
                                          [
                                            seasonTicket.barcode
                                              ? [
                                                  _c("img", {
                                                    attrs: {
                                                      width: "100%",
                                                      src:
                                                        "/qr-code/" +
                                                        seasonTicket.barcode,
                                                      alt: "QR Code"
                                                    }
                                                  })
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "xs:w-full micro:w-2/3 xs:mb-6 micro:mb-0"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-bold text-md xs:mb-3 tiny:mb-1"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    seasonTicket.season &&
                                                      seasonTicket.season.title
                                                  ) +
                                                    " Season Ticket - £" +
                                                    _vm._s(
                                                      parseInt(
                                                        seasonTicket.price
                                                      ).toFixed(2)
                                                    )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "text-xs" },
                                              [
                                                _vm._v(
                                                  "Expires " +
                                                    _vm._s(
                                                      _vm.momentDate(
                                                        seasonTicket.renewal_date,
                                                        "Do MMMM YYYY"
                                                      )
                                                    )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex xs:justify-center tiny:justify-start xs:mt-3 micro:mt-8 mb-2"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "w-2 opacity-25 mr-2 xs:hidden tiny:inline",
                                                  attrs: {
                                                    src: "/img/small-pin.svg"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-bold text-meta"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        seasonTicket.car_park
                                                          .name
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex xs:justify-center tiny:justify-start items-start"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "w-2 opacity-25 mr-2 xs:hidden tiny:inline mt-nudge",
                                                  attrs: {
                                                    src: "/img/small-car.svg"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex flex-col"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-meta mb-1 m-0 p-0"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            seasonTicket.driver
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      seasonTicket.registrations,
                                                      function(reg, index) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "text-meta mb-1 m-0 p-0"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                reg.registration
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _c(
                                  "div",
                                  { key: "1", staticClass: "bg-gray-200 p-4" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "m-0 p-0 text-meta text-gray-600 tracking-normal"
                                      },
                                      [
                                        _vm._v(
                                          "No season tickets found under this filter."
                                        )
                                      ]
                                    )
                                  ]
                                )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md:flex justify-between mb-3 mt-10 items-center"
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass: "sm:mb-3 md:mb-0 lg:mb-0 text-lg"
                              },
                              [_vm._v("Prebooking")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "-ml-1 text-sm md:flex justify-between"
                              },
                              _vm._l(_vm.prebookingTicketFilters, function(
                                prebookingTicketFilter
                              ) {
                                return _c(
                                  "button",
                                  {
                                    key: prebookingTicketFilter.key,
                                    staticClass: "text-sm",
                                    class: [
                                      _vm.activePrebookingTicketFilter.key ===
                                      prebookingTicketFilter.key
                                        ? "uppercase rounded-sm bg-turquoise border-solid border-2 border-turquoise text-white text-micro font-bold px-2 py-1 ml-1"
                                        : "bg-white uppercase rounded-sm border-solid border-2 border-blue text-micro font-bold text-blue px-2 py-1 ml-1"
                                    ],
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.activePrebookingTicketFilter = prebookingTicketFilter
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(prebookingTicketFilter.label))]
                                )
                              }),
                              0
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "transition",
                          { attrs: { mode: "out-in", name: "fade-in" } },
                          [
                            _vm.prebookingFiltered &&
                            _vm.prebookingFiltered.length > 0
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: "1",
                                      staticClass:
                                        "md:flex flex-wrap justify-between lg:p-1 md:p-1 sm:p-0 pt-0"
                                    },
                                    _vm._l(_vm.prebookingFiltered, function(
                                      ticket
                                    ) {
                                      return _c(
                                        "a",
                                        {
                                          key: ticket.id,
                                          class: [
                                            _vm.prebookingTicketBgColor(ticket),
                                            "text-white block relative flex flex-col md:-mx-1 -mx-0 md:w-1/2 w-full p-4 mb-2 text-xs rounded-sm hover:opacity-90"
                                          ],
                                          attrs: {
                                            href:
                                              "/my-account/ticket/" + ticket.id
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between mb-3"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "font-bold" },
                                                [
                                                  _vm._v(
                                                    "PRE-" + _vm._s(ticket.id)
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-bold font-normal"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.momentDate(
                                                        ticket.valid_from,
                                                        "DD/MM/YY"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex mb-2" },
                                            [
                                              _c("div", {}, [
                                                _c("img", {
                                                  staticClass:
                                                    "w-full opacity-25",
                                                  attrs: { src: "/img/pin.svg" }
                                                })
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "ml-2" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "mb-1 font-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          ticket.car_park.name
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex mb-2" },
                                            [
                                              _c("div", {}, [
                                                _c("img", {
                                                  staticClass:
                                                    "w-full opacity-25",
                                                  attrs: { src: "/img/car.svg" }
                                                })
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "ml-2" },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.momentDate(
                                                            ticket.valid_from,
                                                            "DD/MM/YYYY - HH:mm"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    { staticClass: "m-0" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.momentDate(
                                                            ticket.valid_till,
                                                            "DD/MM/YYYY - HH:mm"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "flex" }, [
                                            _c("div", {}, [
                                              _c("img", {
                                                staticClass:
                                                  "w-full opacity-25",
                                                attrs: { src: "/img/car.svg" }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "ml-2" }, [
                                              _c("p", { staticClass: "mb-1" }, [
                                                _vm._v(
                                                  _vm._s(ticket.registration)
                                                )
                                              ])
                                            ])
                                          ])
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      key: "2",
                                      staticClass: "bg-gray-200 p-4 mt-3"
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "m-0 p-0 text-meta text-gray-600 tracking-normal"
                                        },
                                        [
                                          _vm._v(
                                            "No prebooked tickets found under this filter."
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center p-1" }, [
      _c("img", {
        staticClass: "w-4 h-auto mr-2",
        attrs: { src: "/img/card.svg" }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "text-xs tracking-fat" }, [
        _vm._v("Add new card")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-53a4b8e6", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div class="registration section">
    <form @submit.prevent="next">
      <div class>
        <h1 class="text-blue xs:text-lg micro:text-xl">Driver's Name</h1>
        <input
          type="text"
          v-model="driver"
          placeholder="Driver's Name"
          class="dusk-driver-name w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise text-center xs:text-meta micro:text-lg"
        />
      </div>
    </form>
    <ButtonFixed :active="nextButtonActive" :clickEvent="next"
      >Continue</ButtonFixed
    >
  </div>
</template>

<script>
import Vue from "vue";
import SectionTitle from "../objects/SectionTitle.vue";
import ButtonList from "../objects/ButtonList.vue";
import ButtonListItem from "../objects/ButtonListItem.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";

export default {
  mounted() {
    if (!this.driver) {
      this.driver = `${this.$root.$data.store.customer.first_name} ${this.$root.$data.store.customer.last_name}`;
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    nextButtonActive() {
      if (!this.driver) {
        return false;
      }
      if (this.driver.length === 0) {
        return false;
      }

      return true;
    },
    carPark() {
      return this.$root.$data.store.ticket.carPark;
    },
    driver: {
      get() {
        return this.$root.$data.store.ticket.driver;
      },
      set(driver) {
        this.$root.$data.store.setField("ticket", "driver", driver);
      }
    }
  },
  components: {
    SectionTitle,
    ButtonList,
    ButtonListItem,
    ButtonFixed
  },
  methods: {
    next() {
      this.$router.push({ name: "payment" });
    }
  }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confirmation wrapper section" },
    [
      _c(
        "Popup",
        { attrs: { "modal-id": "terms", title: "Terms & Conditions" } },
        [
          _vm._l(_vm.faqs, function(faq, index) {
            return [
              _c("div", { key: index }, [
                _c("strong", [_vm._v(_vm._s(faq.question))]),
                _vm._v(" "),
                _c("br"),
                _vm._v("\n        " + _vm._s(faq.answer) + "\n        "),
                _c("br"),
                _vm._v(" "),
                _c("br")
              ])
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
        _vm._v("Confirmation")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input--confirmation" },
        [
          _c("p", [
            _vm._v(
              "\n      To park with us you must tick the box below to show you agree with the\n      "
            ),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.openModal($event)
                  }
                }
              },
              [_vm._v("terms and conditions.")]
            )
          ]),
          _vm._v(" "),
          _vm.ticketType === "prebook"
            ? _c("p", [
                _vm._v(
                  "\n      Please note that your pre-booking purchase only allows you to enter and\n      exit the car park once. You cannot re-enter the car park again once you\n      have left. If you need to exit and re-enter the car park multiple times,\n      pre-booking is not the right option for you. Contact us for more\n      information.\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("Checkbox", {
            attrs: {
              object: "state",
              field: "terms",
              default: _vm.$root.$data.store.state.terms
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("TextInput", { attrs: { slim: "true" } }, [
        _c("div", { staticClass: "input-upper" }, [
          _c("div", { staticClass: "input-upper--left" }, [_vm._v("Car Park")]),
          _vm._v(" "),
          _c("div", { staticClass: "clear" })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "input--non-underlined" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$root.$data.store.ticket.carPark
                  ? _vm.$root.$data.store.ticket.carPark.name
                  : ""
              ) +
              ",\n      " +
              _vm._s(
                _vm.$root.$data.store.ticket.location
                  ? _vm.$root.$data.store.ticket.location.name
                  : ""
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _vm.ticketType == "prebook"
        ? [
            _c("TextInput", { attrs: { slim: "true" } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Car Park Entry")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "input--non-underlined" }, [
                _vm._v(_vm._s(_vm.entryDate) + " - " + _vm._s(_vm.entryTime))
              ])
            ]),
            _vm._v(" "),
            _c("TextInput", { attrs: { slim: "true" } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Car Park Exit")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "input--non-underlined" }, [
                _vm._v(_vm._s(_vm.exitDate) + " - " + _vm._s(_vm.exitTime))
              ])
            ])
          ]
        : [
            _c("TextInput", { attrs: { slim: "true" } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Season Ticket")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "input--non-underlined" }, [
                _vm._v(
                  "\n        £" +
                    _vm._s(_vm.seasonTicket.singlePrice) +
                    " " +
                    _vm._s(_vm.seasonTicket.title) +
                    "\n      "
                )
              ])
            ])
          ],
      _vm._v(" "),
      _vm.ticketType === "prebook"
        ? [
            _c("TextInput", { attrs: { slim: "true" } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Vehicle Registration")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "input--non-underlined" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$root.$data.store.ticket.registration.toUpperCase()
                    ) +
                    "\n      "
                )
              ])
            ])
          ]
        : [
            _c("TextInput", { attrs: { slim: "true" } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Start Date")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "input--non-underlined" }, [
                _vm._v(_vm._s(_vm.startDate))
              ])
            ]),
            _vm._v(" "),
            _c("TextInput", { attrs: { slim: "true" } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Driver's Name")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "input--non-underlined" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$root.$data.store.ticket.driver) +
                    "\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }),
            _vm._v(" "),
            _c("TextInput", { attrs: { slim: "true" } }, [
              _c("div", { staticClass: "input-upper" }, [
                _c("div", { staticClass: "input-upper--left" }, [
                  _vm._v("Vehicle #1")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "input--non-underlined" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$root.$data.store.ticket.registration.toUpperCase()
                    ) +
                    "\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }),
            _vm._v(" "),
            _vm.$root.$data.store.ticket.additional_registration
              ? _c("TextInput", { attrs: { slim: "true" } }, [
                  _c("div", { staticClass: "input-upper" }, [
                    _c("div", { staticClass: "input-upper--left" }, [
                      _vm._v("Vehicle #2")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clear" })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "input--non-underlined" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$root.$data.store.ticket.additional_registration.toUpperCase()
                        ) +
                        "\n      "
                    )
                  ])
                ])
              : _vm._e()
          ],
      _vm._v(" "),
      _vm.$root.$data.store.ticket.card
        ? _c("TextInput", { attrs: { slim: "true" } }, [
            _c("div", { staticClass: "input-upper" }, [
              _c("div", { staticClass: "input-upper--left" }, [
                _vm._v("Payment Card")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "clear" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c(
                "p",
                {
                  staticClass: "input--non-underlined m-0",
                  staticStyle: { "text-transform": "capitalize" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$root.$data.store.ticket.card.card.brand) +
                      " ending in\n        " +
                      _vm._s(_vm.$root.$data.store.ticket.card.card.last4) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "bg-blue text-micro text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start ml-2 mt-1 hover:opacity-75",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.goToPayment($event)
                    }
                  }
                },
                [_vm._v("\n        Change\n      ")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.ticketType === "prebook"
        ? _c(
            "ButtonFixed",
            {
              attrs: {
                loading: _vm.loading,
                active: _vm.nextButtonActive,
                clickEvent: _vm.addTicket
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.totalPrice != "0.00"
                    ? "Pay now - £" + _vm.totalPrice
                    : "Book Your Free Space"
                )
              )
            ]
          )
        : _c(
            "ButtonFixed",
            {
              attrs: {
                loading: _vm.loading,
                active: _vm.nextButtonActive,
                clickEvent: _vm.addSeasonTicket
              }
            },
            [
              _vm._v(
                _vm._s(
                  this.seasonTicket.price == 0
                    ? "Book Your Free Space"
                    : "Pay now - £" +
                        parseFloat(this.seasonTicket.price).toFixed(2)
                )
              )
            ]
          ),
      _vm._v(" "),
      _vm.ticketType === "prebook"
        ? [
            !_vm.codeApplied
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.applyPromotion()
                      }
                    }
                  },
                  [
                    _c("TextInput", [
                      _c("div", { staticClass: "input-upper" }, [
                        _c("div", { staticClass: "input-upper--left" }, [
                          _vm._v("Promotional code")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "clear" })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.code,
                            expression: "code"
                          }
                        ],
                        staticClass: "input--underlined",
                        attrs: { type: "text", value: "" },
                        domProps: { value: _vm.code },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.code = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn input--btn",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { type: "submit" }
                        },
                        [_vm._v("\n          Apply\n        ")]
                      )
                    ])
                  ],
                  1
                )
              : _c("TextInput", [
                  _c("div", { staticClass: "input-upper" }, [
                    _c("div", { staticClass: "input-upper--left" }, [
                      _vm._v("Promotional code")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clear" })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "input--non-underlined" }, [
                    _vm._v(_vm._s(this.code))
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn input--btn btn--remove",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removePromotion()
                        }
                      }
                    },
                    [_vm._v("\n        Remove\n      ")]
                  )
                ])
          ]
        : [
            !_vm.codeApplied
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.applySeasonTicketPromotion($event)
                      }
                    }
                  },
                  [
                    _c("TextInput", [
                      _c("div", { staticClass: "input-upper" }, [
                        _c("div", { staticClass: "input-upper--left" }, [
                          _vm._v("Promotional code")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "clear" })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.seasonTicketCode,
                            expression: "seasonTicketCode"
                          }
                        ],
                        staticClass: "input--underlined",
                        attrs: { type: "text", value: "" },
                        domProps: { value: _vm.seasonTicketCode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.seasonTicketCode = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn input--btn",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { type: "submit" }
                        },
                        [_vm._v("\n          Apply\n        ")]
                      )
                    ])
                  ],
                  1
                )
              : _c("TextInput", [
                  _c("div", { staticClass: "input-upper" }, [
                    _c("div", { staticClass: "input-upper--left" }, [
                      _vm._v("Promotional code")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clear" })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "input--non-underlined" }, [
                    _vm._v(_vm._s(this.seasonTicketCode))
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn input--btn btn--remove",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeSeasonTicketPromotion()
                        }
                      }
                    },
                    [_vm._v("\n        Remove\n      ")]
                  )
                ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36f1fa0f", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div class="confirmation wrapper section">
    <Popup modal-id="terms" title="Terms &amp; Conditions">
      <template v-for="(faq, index) in faqs">
        <div :key="index">
          <strong>{{ faq.question }}</strong>
          <br />
          {{ faq.answer }}
          <br />
          <br />
        </div>
      </template>
    </Popup>
    <h1 class="text-blue xs:text-lg micro:text-xl">Confirmation</h1>
    <div class="input--confirmation">
      <p>
        To park with us you must tick the box below to show you agree with the
        <a href="#" @click.prevent="openModal">terms and conditions.</a>
      </p>
      <p v-if="ticketType === 'prebook'">
        Please note that your pre-booking purchase only allows you to enter and
        exit the car park once. You cannot re-enter the car park again once you
        have left. If you need to exit and re-enter the car park multiple times,
        pre-booking is not the right option for you. Contact us for more
        information.
      </p>
      <Checkbox
        object="state"
        field="terms"
        :default="$root.$data.store.state.terms"
      ></Checkbox>
    </div>
    <TextInput slim="true">
      <div class="input-upper">
        <div class="input-upper--left">Car Park</div>
        <div class="clear"></div>
      </div>
      <p class="input--non-underlined">
        {{
          $root.$data.store.ticket.carPark
            ? $root.$data.store.ticket.carPark.name
            : ""
        }},
        {{
          $root.$data.store.ticket.location
            ? $root.$data.store.ticket.location.name
            : ""
        }}
      </p>
    </TextInput>
    <template v-if="ticketType == 'prebook'">
      <TextInput slim="true">
        <div class="input-upper">
          <div class="input-upper--left">Car Park Entry</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">{{ entryDate }} - {{ entryTime }}</p>
      </TextInput>
      <TextInput slim="true">
        <div class="input-upper">
          <div class="input-upper--left">Car Park Exit</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">{{ exitDate }} - {{ exitTime }}</p>
      </TextInput>
    </template>
    <template v-else>
      <TextInput slim="true">
        <div class="input-upper">
          <div class="input-upper--left">Season Ticket</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">
          £{{ seasonTicket.singlePrice }} {{ seasonTicket.title }}
        </p>
      </TextInput>
    </template>
    <template v-if="ticketType === 'prebook'">
      <TextInput slim="true">
        <div class="input-upper">
          <div class="input-upper--left">Vehicle Registration</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">
          {{ $root.$data.store.ticket.registration.toUpperCase() }}
        </p>
      </TextInput>
    </template>
    <template v-else>
      <TextInput slim="true">
        <div class="input-upper">
          <div class="input-upper--left">Start Date</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">{{ startDate }}</p>
      </TextInput>

      <TextInput slim="true">
        <div class="input-upper">
          <div class="input-upper--left">Driver's Name</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">
          {{ $root.$data.store.ticket.driver }}
        </p>
      </TextInput>

      <div class="mb-4"></div>

      <TextInput slim="true">
        <div class="input-upper">
          <div class="input-upper--left">Vehicle #1</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">
          {{ $root.$data.store.ticket.registration.toUpperCase() }}
        </p>
      </TextInput>

      <div class="mb-4"></div>

      <TextInput
        v-if="$root.$data.store.ticket.additional_registration"
        slim="true"
      >
        <div class="input-upper">
          <div class="input-upper--left">Vehicle #2</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">
          {{ $root.$data.store.ticket.additional_registration.toUpperCase() }}
        </p>
      </TextInput>
    </template>
    <TextInput slim="true" v-if="$root.$data.store.ticket.card">
      <div class="input-upper">
        <div class="input-upper--left">Payment Card</div>
        <div class="clear"></div>
      </div>
      <div class="flex">
        <p class="input--non-underlined m-0" style="text-transform: capitalize">
          {{ $root.$data.store.ticket.card.card.brand }} ending in
          {{ $root.$data.store.ticket.card.card.last4 }}
        </p>
        <button
          @click.prevent="goToPayment"
          type="button"
          class="bg-blue text-micro text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start ml-2 mt-1 hover:opacity-75"
        >
          Change
        </button>
      </div>
    </TextInput>
    <ButtonFixed
      v-if="ticketType === 'prebook'"
      :loading="loading"
      :active="nextButtonActive"
      :clickEvent="addTicket"
      >{{
        totalPrice != "0.00"
          ? `Pay now - £${totalPrice}`
          : `Book Your Free Space`
      }}</ButtonFixed
    >
    <ButtonFixed
      v-else
      :loading="loading"
      :active="nextButtonActive"
      :clickEvent="addSeasonTicket"
      >{{
        this.seasonTicket.price == 0
          ? `Book Your Free Space`
          : `Pay now - £${parseFloat(this.seasonTicket.price).toFixed(2)}`
      }}</ButtonFixed
    >
    <template v-if="ticketType === 'prebook'">
      <form @submit.prevent="applyPromotion()" v-if="!codeApplied">
        <TextInput>
          <div class="input-upper">
            <div class="input-upper--left">Promotional code</div>
            <div class="clear"></div>
          </div>
          <input type="text" class="input--underlined" value v-model="code" />
          <button
            type="submit"
            class="btn input--btn"
            style="margin-bottom: 0px;"
          >
            Apply
          </button>
        </TextInput>
      </form>
      <TextInput v-else>
        <div class="input-upper">
          <div class="input-upper--left">Promotional code</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">{{ this.code }}</p>
        <button
          type="submit"
          @click.prevent="removePromotion()"
          class="btn input--btn btn--remove"
        >
          Remove
        </button>
      </TextInput>
    </template>
    <template v-else>
      <form @submit.prevent="applySeasonTicketPromotion" v-if="!codeApplied">
        <TextInput>
          <div class="input-upper">
            <div class="input-upper--left">Promotional code</div>
            <div class="clear"></div>
          </div>
          <input
            type="text"
            class="input--underlined"
            value
            v-model="seasonTicketCode"
          />
          <button
            type="submit"
            class="btn input--btn"
            style="margin-bottom: 0px;"
          >
            Apply
          </button>
        </TextInput>
      </form>
      <TextInput v-else>
        <div class="input-upper">
          <div class="input-upper--left">Promotional code</div>
          <div class="clear"></div>
        </div>
        <p class="input--non-underlined">{{ this.seasonTicketCode }}</p>
        <button
          type="submit"
          @click.prevent="removeSeasonTicketPromotion()"
          class="btn input--btn btn--remove"
        >
          Remove
        </button>
      </TextInput>
    </template>
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import AutoFill from "../../store/autofill.js";
import Checkbox from "../objects/Checkbox.vue";
import Popup from "../objects/Modal.vue";
import moment from "moment";
import jQuery from "jquery";

export default {
  mounted() {
    $ = jQuery.noConflict();

    this.loadFaqs();

    this.$root.$data.store.setObject("progress", 7);

    $("html, body").scrollTop(0);
    var customer = this.$root.$data.store.customer;
    var ticket = this.$root.$data.store.ticket;
    var carParkId = ticket.carPark.id;
    var discountCodeToApply = "PREBOOKSALE";
    var carParkIds = [5];
    if (carParkIds.includes(carParkId) && this.ticketType === "prebook") {
      this.codeApplying = true;

      this.$http
        .post(
          `/promotion/apply`,
          {
            customer: customer,
            ticket: ticket,
            code: discountCodeToApply,
            car_park_id: carParkId,
            total: this.totalPrice
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            if (!response.body) response.body = response.bodyText;
            this.codeApplied = true;
            this.codeApplying = false;
            this.$root.$data.store.setField("ticket", "price", response.body);
            this.loading = false;
            this.$root.$data.store.setField(
              "ticket",
              "promotion",
              discountCodeToApply
            );
          },
          response => {
            if (!response.body) response.body = response.bodyText;
            this.$root.$data.store.showMessage(response.body);
            this.loading = false;
            this.codeApplying = false;
          }
        );
    }
  },
  data() {
    return {
      mode: this.$root.$data.store.mode,
      loading: false,
      faqs: null
    };
  },
  computed: {
    nextButtonActive: {
      get() {
        return this.terms;
      }
    },
    startDate() {
      const startDate = moment(this.$root.$data.store.state.startDate).format(
        "Do MMMM YYYY"
      );
      const today = moment().format("Do MMMM YYYY");

      if (today === startDate) {
        return "Today";
      } else {
        return startDate;
      }
    },
    terms: {
      get() {
        return this.$root.$data.store.state.terms;
      }
    },
    codeApplied: {
      get() {
        return this.$root.$data.store.promotion.codeApplied;
      },
      set(value) {
        this.$root.$data.store.setField("promotion", "codeApplied", value);
      }
    },
    codeApplying: {
      get() {
        return this.$root.$data.store.promotion.codeApplying;
      },
      set(value) {
        this.$root.$data.store.setField("promotion", "codeApplying", value);
      }
    },
    totalPrice: {
      get() {
        return this.$root.$data.store.ticket.price;
      },
      set(value) {
        this.$root.$data.store.setField("ticket", "price", value);
      }
    },
    seasonTicketTotalPrice: {
      get() {
        return this.$root.$data.store.seasonTicket.price;
      },
      set(value) {
        this.$root.$data.store.setField("seasonTicket", "price", value);
      }
    },
    seasonTicketInitialPrice: {
      get() {
        return this.$root.$data.store.seasonTicket.initialPrice;
      },
      set(value) {
        this.$root.$data.store.setField("seasonTicket", "initialPrice", value);
      }
    },
    code: {
      get() {
        return this.$root.$data.store.ticket.promotion;
      },
      set(value) {
        this.$root.$data.store.setField("ticket", "promotion", value);
      }
    },
    seasonTicketCode: {
      get() {
        return this.$root.$data.store.seasonTicket.promotion;
      },
      set(value) {
        this.$root.$data.store.setField("seasonTicket", "promotion", value);
      }
    },
    entryDate() {
      return moment(this.$root.$data.store.ticket.entryDate).format(
        "Do MMMM YYYY"
      );
    },
    entryTime() {
      return moment(this.$root.$data.store.ticket.entryDate).format("HH:mm");
    },
    exitDate() {
      return moment(this.$root.$data.store.ticket.exitDate).format(
        "Do MMMM YYYY"
      );
    },
    exitTime() {
      return moment(this.$root.$data.store.ticket.exitDate).format("HH:mm");
    },
    ticketType: {
      get() {
        return this.$root.$data.store.state.ticketType;
      },
      set(value) {
        this.$root.$data.store.setField("state", "ticketType", type);
      }
    },
    seasonTicket: {
      get() {
        return this.$root.$data.store.seasonTicket;
      },
      set(value) {
        this.$root.$data.store.setObject("seasonTicket", value);
      }
    }
  },
  components: {
    SectionTitle,
    TextInput,
    ButtonFixed,
    Checkbox,
    Popup
  },
  methods: {
    goToPayment() {
      this.$router.go(-1);
    },
    applySeasonTicketPromotion() {
      this.loading = true;

      if (this.seasonTicketCode === "") {
        this.$root.$data.store.showMessage("Please enter a promotional code.");
        this.loading = false;
        return;
      }

      if (this.codeApplied) {
        this.$root.$data.store.showMessage(
          "A promotional code has already been entered for this ticket."
        );
        this.loading = false;
        return;
      }

      if (!this.codeApplying) {
        this.codeApplying = true;

        this.$http
          .post(
            `/season-ticket/promotion/apply`,
            {
              customer: this.$root.$data.store.customer,
              ticket: this.seasonTicket,
              code: this.seasonTicketCode,
              season: this.seasonTicket.id,
              carpark: this.$root.$data.store.ticket.carPark.id
            },
            {
              headers: { "X-CSRF-TOKEN": csrfToken }
            }
          )
          .then(
            response => {
              this.codeApplied = true;
              this.codeApplying = false;
              this.seasonTicketInitialPrice = this.seasonTicket.price;
              this.seasonTicketTotalPrice = parseFloat(response.body);
              this.loading = false;
              this.$root.$data.store.setField(
                "seasonTicket",
                "promotion",
                this.seasonTicketCode
              );
            },
            response => {
              if (!response.body) response.body = response.bodyText;
              this.$root.$data.store.showMessage(response.body, "bg-red-500");
              this.loading = false;
              this.codeApplying = false;
            }
          );
      }
    },
    applyPromotion() {
      this.loading = true;
      if (
        this.$root.$data.store.ticket.carPark.id != 5 &&
        this.code.toLowerCase() == "prebooksale"
      ) {
        this.$root.$data.store.showMessage(
          "Please enter a valid promotional code"
        );
        this.loading = false;
        return;
      }
      if (this.code === "") {
        this.$root.$data.store.showMessage("Please enter a promotional code.");
        this.loading = false;
        return;
      }

      if (this.codeApplied) {
        this.$root.$data.store.showMessage(
          "A promotional code has already been entered for this ticket."
        );
        this.loading = false;
        return;
      }

      if (!this.codeApplying) {
        this.codeApplying = true;

        let hours = moment(this.$root.$data.store.ticket.exitDate).diff(
          moment(this.$root.$data.store.ticket.entryDate),
          "hours"
        );

        this.$http
          .post(
            `/promotion/apply`,
            {
              ticket: this.$root.$data.store.ticket,
              code: this.code,
              total: this.totalPrice,
              car_park_id: this.$root.$data.store.ticket.carPark.id,
              hours
            },
            {
              headers: { "X-CSRF-TOKEN": csrfToken }
            }
          )
          .then(
            response => {
              this.codeApplied = true;
              this.codeApplying = false;
              this.totalPrice = response.body;
              this.loading = false;
              this.$root.$data.store.setField("ticket", "promotion", this.code);
            },
            response => {
              if (!response.body) response.body = response.bodyText;
              this.$root.$data.store.showMessage(response.body, "bg-red-500");
              this.loading = false;
              this.codeApplying = false;
            }
          );
      }
    },
    removeSeasonTicketPromotion() {
      this.codeApplying = this.codeApplied = false;
      this.seasonTicketCode = "";
      this.seasonTicketTotalPrice = this.seasonTicketInitialPrice;
    },
    removePromotion() {
      this.codeApplying = this.codeApplied = false;
      this.code = "";
      this.totalPrice = this.$root.$data.store.ticket.initialPrice;
    },
    addTicket(paymentIntent = null) {
      this.$root.$data.store.showLoader();
      //bypass loading check if payment intent is present
      if (this.loading && !paymentIntent) return;

      this.loading = true;

      if (!this.terms) {
        this.$root.$data.store.showMessage(
          "You must accept the terms and conditions"
        );
        this.$root.$data.store.hideLoader();
        this.loading = false;
        return;
      }

      let ticketBody = paymentIntent
        ? {
            data: this.$root.$data.store,
            payment_intent: paymentIntent
          }
        : {
            data: this.$root.$data.store
          };

      this.$http
        .post(`/ticket/add`, ticketBody, {
          headers: { "X-CSRF-TOKEN": csrfToken }
        })
        .then(
          response => {
            if (response.body.requires_action) {
              return this.handlePaymentIntent(response.body.intent_secret);
            }
            window.ga("ecommerce:addTransaction", {
              id: response.body.ticketId,
              revenue: (this.totalPrice - this.totalPrice * 0.8).toFixed(2),
              tax: (this.totalPrice * 0.8).toFixed(2)
            });
            window.location.href = `/my-account/ticket/${response.body.ticketId}`;
          },
          response => {
            if (!response.body) response.body = response.bodyText;

            this.loading = false;
            this.$root.$data.store.hideLoader();
            console.error(response);
            if (response.body.errorMessage) {
              this.$root.$data.store.showMessage(
                response.body.errorMessage,
                "bg-red-500",
                120000
              );
              return;
            }
            this.$root.$data.store.showMessage(
              response.body,
              "bg-red-500",
              120000
            );
          }
        );
    },
    addSeasonTicket(paymentIntent = null) {
      this.$root.$data.store.showLoader();

      //bypass loading check if payment intent is present
      if (this.loading && !paymentIntent) return;

      this.loading = true;

      if (!this.terms) {
        this.$root.$data.store.showMessage(
          "You must accept the terms and conditions"
        );
        this.$root.$data.store.hideLoader();
        this.loading = false;
        return;
      }

      let ticketBody = paymentIntent
        ? {
            data: this.$root.$data.store,
            payment_intent: paymentIntent
          }
        : {
            data: this.$root.$data.store
          };

      this.$http
        .post(`/season-ticket/add`, ticketBody, {
          headers: { "X-CSRF-TOKEN": csrfToken }
        })
        .then(
          response => {
            if (response.body.client_secret) {
              this.$root.$data.store.setField(
                "customer",
                "stripe",
                response.body.stripe
              );
              return this.handleSeasonTicketPaymentIntent(
                response.body.client_secret
              );
            }

            window.ga("ecommerce:addTransaction", {
              id: response.body.ticketId,
              revenue: (
                this.seasonTicket.price -
                this.seasonTicket.price * 0.8
              ).toFixed(2),
              tax: (this.seasonTicket.price * 0.8).toFixed(2)
            });

            window.location.href = `/my-account/season-ticket/${response.body.seasonTicketId}`;
          },
          response => {
            this.loading = false;
            this.$root.$data.store.hideLoader();

            if (!response.body) response.body = response.bodyText;

            if (response.body.errorMessage) {
              this.$root.$data.store.showMessage(
                response.body.errorMessage,
                "bg-red-500",
                120000
              );
              return;
            }
            this.$root.$data.store.showMessage(
              response.body,
              "bg-red-500",
              120000
            );
          }
        );
    },
    handleSeasonTicketPaymentIntent(secret) {
      let stripe = Stripe(stripePublicKey);

      stripe.handleCardAction(secret).then(result => {
        if (result.error) {
          this.loading = false;
          this.$root.$data.store.showMessage(
            result.error.message,
            "bg-red-500"
          );
          this.$root.$data.store.hideLoader();
        } else {
          this.addSeasonTicket(result);
        }
      });
    },
    handlePaymentIntent(secret) {
      let stripe = Stripe(stripePublicKey);

      stripe.handleCardAction(secret).then(result => {
        if (result.error) {
          this.loading = false;
          this.$root.$data.store.showMessage(
            result.error.message,
            "bg-red-500"
          );
          this.$root.$data.store.hideLoader();
        } else {
          this.addTicket(result);
        }
      });
    },
    openModal() {
      this.$root.$data.store.openModal("terms");
    },
    loadFaqs() {
      this.$http.get(`/faqs`).then(
        response => {
          this.faqs = response.body;
        },
        err => {
          console.log("faq's didn't load");
        }
      );
    }
  }
};
</script>

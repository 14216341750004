<template>
  <div class="reset-password section">
    <h1 class="text-blue xs:text-lg micro:text-xl">Check your email {{ email }}</h1>
    <TextInput slim="true">
      <div class="input-upper">
        <div class="input-upper--left">Verification Code</div>
      </div>
      <input
        type="text"
        :class="['input--underlined', 'margin-bottom-30', verificationCode !== null && verificationCode.length === 0 && formSubmitted ? 'input--invalid' : '']"
        placeholder
        value
        v-model="verificationCode"
      />
    </TextInput>
    <TextInput slim="true">
      <div class="input-upper">
        <div class="input-upper--left">New Password</div>
      </div>
      <input
        type="password"
        :class="['input--underlined', 'margin-bottom-30', password !== null && password.length === 0 && formSubmitted ? 'input--invalid' : '']"
        value
        v-model="password"
      />
    </TextInput>
    <TextInput slim="true">
      <div class="input-upper">
        <div class="input-upper--left">New Password Confirmation</div>
      </div>
      <input
        type="password"
        :class="['input--underlined', 'margin-bottom-30', passwordConfirm !== null && passwordConfirm.length === 0 && formSubmitted ? 'input--invalid' : '']"
        value
        v-model="passwordConfirm"
      />
    </TextInput>
    <ButtonFixed :loading="loading" :active="nextButtonActive" :clickEvent="reset">Change password</ButtonFixed>
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";

export default {
  created() {
    if (
      !this.$root.$data.store.customer.email &&
      this.$root.$data.store.mode === "create"
    ) {
      this.$router.push({ name: "location" });
    }
  },
  data() {
    return {
      email: this.$root.$data.store.customer.email,
      password: "",
      passwordConfirm: "",
      verificationCode: "",
      formSubmitted: false,
      loading: false
    };
  },
  computed: {
    nextButtonActive() {
      if (
        this.password === "" ||
        this.passwordConfirm === "" ||
        this.verificationCode === ""
      ) {
        return false;
      }

      if (this.password !== this.passwordConfirm) {
        return false;
      }

      if (this.password.length <= 6 || this.passwordConfirm.length <= 6) {
        return false;
      }

      return true;
    }
  },
  components: {
    SectionTitle,
    TextInput,
    ButtonFixed
  },
  methods: {
    reset() {
      this.formSubmitted = true;
      this.loading = true;

      if (
        this.password === "" ||
        this.passwordConfirm === "" ||
        this.verificationCode === ""
      ) {
        this.$root.$data.store.showMessage("Please fill in all fields");
        this.loading = false;
        return;
      }

      if (this.password !== this.passwordConfirm) {
        this.loading = false;
        return this.$root.$data.store.showMessage("Passwords do not match");
      }

      if (this.password.length <= 6 || this.passwordConfirm.length <= 6) {
        this.loading = false;
        return this.$root.$data.store.showMessage(
          "Your password must contain 6 characters or more"
        );
      }

      this.$http
        .post(
          "/password/ajax/confirm",
          {
            username: this.$root.$data.store.customer.email,
            code: this.verificationCode,
            password: this.password,
            password_confirm: this.passwordConfirm
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.$root.$data.store.showMessage(
              "You have successfully reset your password"
            );
            this.$router.push({ name: "login" });
          },
          err => {
            this.loading = false;
            this.$root.$data.store.showMessage(err.body.error);
          }
        );
    }
  }
};
</script>

<template>
  <div class="account-details wrapper section">
    <SectionTitle>We just need a few details</SectionTitle>
    <TextInput>
      <div class="input-upper">
        <div class="input-upper--left">Your First Name</div>
        <div class="clear"></div>
      </div>
      <input
        type="text"
        class="input--underlined dusk-account-first-name"
        value
        v-model="first_name"
        @keyup.enter="go"
      />
    </TextInput>
    <TextInput>
      <div class="input-upper">
        <div class="input-upper--left">Your Last Name</div>
        <div class="clear"></div>
      </div>
      <input
        type="text"
        class="input--underlined dusk-account-last-name"
        value
        v-model="last_name"
        @keyup.enter="go"
      />
    </TextInput>
    <TextInput>
      <div class="input-upper">
        <div class="input-upper--left">Password</div>
        <div class="clear"></div>
      </div>
      <input
        type="password"
        class="input--underlined dusk-account-password"
        placeholder="▪▪▪▪▪▪▪"
        value
        v-model="password"
        @keyup.enter="go"
      />
    </TextInput>
    <TextInput>
      <div class="input-upper">
        <div class="input-upper--left">Password Confirmation</div>
        <div class="clear"></div>
      </div>
      <input
        type="password"
        class="input--underlined dusk-account-password-confirmation"
        placeholder="▪▪▪▪▪▪▪"
        value
        v-model="passwordConfirm"
        @keyup.enter="go"
      />
    </TextInput>
    <TextInput>
      <div class="input-upper">
        <div class="input-upper--left">Postcode</div>
        <div class="clear"></div>
      </div>
      <input
        type="text"
        class="input--underlined dusk-account-postcode"
        value
        v-model="postcode"
        @keyup.enter="go"
      />
    </TextInput>
    <ButtonFixed :active="nextButtonActive" :clickEvent="go">Continue</ButtonFixed>
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import AutoFill from "../../store/autofill.js";

export default {
  mounted() {
    this.password = "";

    if (this.$root.$data.store.autoFill) this.autoFill();
  },
  data() {
    return {
      passwordConfirm: ""
    };
  },
  computed: {
    nextButtonActive() {
      if (
        this.first_name === "" ||
        this.last_name === "" ||
        this.email === "" ||
        this.password === "" ||
        this.postcode === ""
      ) {
        return false;
      }

      if (this.password !== this.passwordConfirm || this.password.length < 6) {
        return false;
      }

      return true;
    },
    first_name: {
      get() {
        return this.$root.$data.store.customer.first_name;
      },
      set(value) {
        this.$root.$data.store.setField("customer", "first_name", value);
      }
    },
    last_name: {
      get() {
        return this.$root.$data.store.customer.last_name;
      },
      set(value) {
        this.$root.$data.store.setField("customer", "last_name", value);
      }
    },
    password: {
      get() {
        return this.$root.$data.store.customer.password;
      },
      set(value) {
        this.$root.$data.store.setField("customer", "password", value);
      }
    },
    postcode: {
      get() {
        return this.$root.$data.store.customer.postcode;
      },
      set(value) {
        this.$root.$data.store.setField("customer", "postcode", value);
      }
    },
    addPaymentRedirect: {
      get() {
        return this.$root.$data.store.state.addPaymentRedirect;
      },
      set(value) {
        this.$root.$data.store.setField("state", "addPaymentRedirect", value);
      }
    }
  },
  components: {
    SectionTitle,
    TextInput,
    ButtonFixed
  },
  methods: {
    go(location) {
      if (
        this.first_name === "" ||
        this.last_name === "" ||
        this.email === "" ||
        this.password === "" ||
        this.postcode === ""
      ) {
        return;
      }

      if (this.password !== this.passwordConfirm || this.password.length < 6) {
        return;
      }

      this.addPaymentRedirect = "registration";

      this.$router.push({ name: "addPayment" });
    },
    autoFill() {
      this.first_name = AutoFill.customer.first_name;
      this.last_name = AutoFill.customer.last_name;
      this.password = "Test123*";
      this.passwordConfirm = "Test123*";
      this.postcode = AutoFill.customer.postcode;
    }
  }
};
</script>

<template>
  <div class="vat-receipt">
    <div class="vat-receipt--title">VAT Receipt for parking - PRE-{{ ticket.id }}</div>
    <div class="vat-receipt--attributes">
      <ul>
        <li v-for="atts in attributes" :key="atts.label">
          <span :class="['vat-receipt--attributes__label', 'bold']">{{ atts.label }}</span>
          <span
            :class="['vat-receipt--attributes__value', atts.bold ? 'bold' : '']"
          >{{ atts.value }}</span>
          <div class="clear"></div>
        </li>
      </ul>
    </div>
    <p class="vat-receipt--content">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["ticket", "attributes"]
};
</script>

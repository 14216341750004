var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ticket && !_vm.loading
    ? _c(
        "div",
        { staticClass: "mt-header w-full dusk-ticket" },
        [
          _c("transition", { attrs: { mode: "out-in", name: "fade-in" } }, [
            _vm.view === "ticket"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      class: [
                        "datepicker-title",
                        _vm.datepicker !== "" ? "open" : ""
                      ]
                    },
                    [_c("h1", [_vm._v(_vm._s(_vm.datePickerTitle))])]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "bg-blue w-full pb-50" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "max-w-5xl sm:w-2/3 xs:w-auto xs:mx-6 sm:mx-auto flex sm:flex-row xs:flex-col justify-between items-center bg-blue xs:mb-16 sm:mb-20 py-8"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex flex-col xs:mb-5 sm:mb-0" },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "text-sm text-white font-bold block flex items-center mb-2",
                                attrs: { href: "/my-account" }
                              },
                              [
                                _c("img", {
                                  staticClass: "w-2 h-auto mr-1",
                                  attrs: {
                                    src: "/img/small-chevron-white.svg",
                                    alt: ""
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-meta mr-1" }, [
                                  _vm._v("Back to my account")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "font-bold text-white font-normal text-lg m-0 xs:text-center sm:text-left"
                              },
                              [_vm._v("PRE-" + _vm._s(_vm.ticket.id))]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.ticket.car_park.uses_zatpark != 1 &&
                        _vm.ticket.car_park.uses_license != 1
                          ? _c(
                              "div",
                              {
                                staticClass: "flex justify-between items-center"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "bg-transparent block p-0",
                                    attrs: {
                                      href: "/ticket/passbook/" + _vm.ticket.id
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "h-wallet block mx-auto",
                                      attrs: { src: "/img/apple-wallet.svg" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex justify-between -mt-overlap" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "max-w-5xl micro:w-2/3 xs:w-auto mini:mx-6 xs:mx-0 bg-white sm:flex-row xs:flex xs:flex-col-reverse shadow-sm sm:p-0 xs:p-8 micro:mx-auto rounded-sm"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col justify-between xs:w-full sm:w-4/12 rounded-sm relative"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center border border-solid border-gray-300 flex-grow rounded-sm"
                                },
                                [
                                  _vm.ticket.barcode
                                    ? [
                                        _c("img", {
                                          staticClass: "w-full h-auto block",
                                          attrs: {
                                            src:
                                              "/qr-code/" + _vm.ticket.barcode,
                                            alt: ""
                                          }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "relative" }, [
                                _vm.active
                                  ? _c("img", {
                                      staticClass:
                                        "pointer-events-none absolute top-0 left-0 w-3 opacity-25 ml-2 mt-2",
                                      attrs: {
                                        src: "/img/pencil-black.png",
                                        alt: ""
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.registration,
                                      expression: "registration"
                                    }
                                  ],
                                  class: [
                                    !_vm.registrationValid()
                                      ? "bg-red-300 border-red-400 text-red-800 border-solid border-1 text-center font-bold text-md uppercase p-4 text-black -mt-1"
                                      : "bg-yolk border-yolk-dark border-solid border-1 text-center font-bold text-md uppercase p-4 text-black -mt-1"
                                  ],
                                  attrs: { disabled: !_vm.active },
                                  domProps: { value: _vm.registration },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.registration = $event.target.value
                                    }
                                  }
                                })
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "xs:w-full sm:w-8/12 text-sm sm:p-10 xs:p-0 xs:pb-10 sm:p-4"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-center mb-1" },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "p-2 text-white font-bold uppercase text-tiny mr-2 rounded-sm inline",
                                        _vm.status == "Active"
                                          ? "bg-active-green"
                                          : "bg-red-500"
                                      ]
                                    },
                                    [_vm._v(_vm._s(_vm.status))]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-xs" }, [
                                    _vm._v(
                                      "Created " +
                                        _vm._s(
                                          _vm.momentDate(
                                            _vm.ticket.created_at,
                                            "Do MMMM YYYY"
                                          )
                                        )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h1",
                                {
                                  staticClass:
                                    "mt-0 mb-1 m-0 xs:text-lg tiny:text-welcome text-left"
                                },
                                [_vm._v(_vm._s(_vm.ticket.car_park.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex mb-2 items-center" },
                                [
                                  _c("div", { staticClass: "mr-1" }, [
                                    _c("img", {
                                      attrs: { src: "/img/pin-turquoise.svg" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "leading-tighter text-xs m-0 block"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.ticket.car_park.address_1) +
                                          ", " +
                                          _vm._s(
                                            _vm.ticket.car_park.address_2
                                          ) +
                                          ", " +
                                          _vm._s(_vm.ticket.car_park.postcode)
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.active
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "rounded-sm p-3 border-1 border-gray-300 border-solid mt-4 text-meta font-bold text-blue leading-normal"
                                    },
                                    [
                                      _vm._v(
                                        "You can edit details by clicking on car park entry, exit and registration number."
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex flex-col mt-3" }, [
                                _c("div", { staticClass: "flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-col mr-1" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/img/time-circle.svg",
                                          width: "12"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "bg-top bg-repeat-y flex-grow",
                                        staticStyle: {
                                          "background-image":
                                            "url('/img/time-line.svg')"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      class: [
                                        "bg-white focus:outline-none text-left text-blue tracking-fat -mt-nudge mb-1",
                                        !_vm.active ? "pointer-events-none" : ""
                                      ],
                                      attrs: {
                                        type: "button",
                                        disabled: !_vm.active,
                                        id: "edit-entry-time"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.toggleDatePicker(
                                            "edit-entry-time",
                                            "Entry Time"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-2 m-0 font-bold text-xs"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Car park entry\n                    "
                                          ),
                                          _vm.active
                                            ? _c("img", {
                                                staticClass: "w-2 ml-1",
                                                attrs: {
                                                  src: "/img/pencil.png",
                                                  alt: ""
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.initialEntryDate !=
                                      _vm.ticket.valid_from
                                        ? _c(
                                            "p",
                                            { staticClass: "mb-2 text-xs" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.momentDate(
                                                    _vm.ticket.valid_from,
                                                    "DD/MM/YYYY - HH:mm"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          class: [
                                            "mb-2 text-xs",
                                            _vm.initialEntryDate !=
                                            _vm.ticket.valid_from
                                              ? "line-through text-gray-400"
                                              : ""
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.momentDate(
                                                _vm.initialEntryDate,
                                                "DD/MM/YYYY - HH:mm"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-col mr-1" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/img/time-circle.svg",
                                          width: "12"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      class: [
                                        "bg-white focus:outline-none text-left text-blue tracking-fat -mt-nudge mb-1",
                                        !_vm.active ? "pointer-events-none" : ""
                                      ],
                                      attrs: {
                                        type: "button",
                                        disabled: !_vm.active,
                                        id: "edit-exit-time"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.toggleDatePicker(
                                            "edit-exit-time",
                                            "Exit Time"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-2 m-0 font-bold text-xs"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Car park exit\n                    "
                                          ),
                                          _vm.active
                                            ? _c("img", {
                                                staticClass: "w-2 ml-1",
                                                attrs: {
                                                  src: "/img/pencil.png",
                                                  alt: ""
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.initialExitDate !=
                                      _vm.ticket.valid_till
                                        ? _c(
                                            "p",
                                            { staticClass: "mb-2 text-xs" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.momentDate(
                                                    _vm.ticket.valid_till,
                                                    "DD/MM/YYYY - HH:mm"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          class: [
                                            "mb-2 text-xs",
                                            _vm.initialExitDate !=
                                            _vm.ticket.valid_till
                                              ? "line-through text-gray-400"
                                              : ""
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.momentDate(
                                                _vm.initialExitDate,
                                                "DD/MM/YYYY - HH:mm"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-2 flex flex-col" })
                              ]),
                              _vm._v(" "),
                              _vm.ticket.promotion ||
                              (_vm.pmsPermit && _vm.pmsPermit.data.promotion)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "leading-tight text-xs block flex"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mr-2 mt-nudge" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/img/voucher.svg",
                                              width: "14"
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "leading-tight text-xs m-0 block -mt-nudge"
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-2 m-0 font-bold text-xs"
                                            },
                                            [_vm._v("Promotion")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "m-0 p-0 text-meta"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.pmsPermit
                                                      ? _vm.pmsPermit.data
                                                          .promotion.code
                                                      : _vm.ticket.promotion.code.toUpperCase()
                                                  ) +
                                                  "\n                  "
                                              ),
                                              !_vm.pmsPermit
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-turquoise text-meta"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            (
                                                              100 -
                                                              _vm.ticket
                                                                .promotion
                                                                .discount *
                                                                100
                                                            ).toFixed(0)
                                                          ) +
                                                          "% discount)"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "border-b-1 border-t-0 border-solid border-gray-200 my-6"
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex xs:flex-col sm:flex-row justify-between"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center xs:justify-center tiny:justify-start"
                                    },
                                    [
                                      _vm.ticket.promotion &&
                                      _vm.ticket.promotion.discount == 0
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-1 text-xs leading-normal font-bold"
                                              },
                                              [_vm._v("Free Ticket")]
                                            )
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-xs leading-normal"
                                              },
                                              [_vm._v("Total")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-1 text-xs leading-normal font-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "£" + _vm._s(_vm.ticket.price)
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.priceDiff != 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ml-1 text-xs text-gray-500"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          _vm.priceDiff > 0
                                                            ? "+"
                                                            : "-"
                                                        ) +
                                                        "£" +
                                                        _vm._s(
                                                          _vm.priceDiff > 0
                                                            ? _vm.priceDiff.toFixed(
                                                                2
                                                              )
                                                            : (-_vm.priceDiff).toFixed(
                                                                2
                                                              )
                                                        ) +
                                                        ")"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex xs:flex-col tiny:flex-row justify-between items-center xs:mt-3 tiny:mt-0"
                                    },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            mode: "out-in",
                                            name: "fade-in"
                                          }
                                        },
                                        [
                                          _vm.saveButtonActive
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "dusk-ticket-save-changes xs:mb-2 tiny:mb-0 xs:w-full tiny:w-auto xs:mr-0 tiny:mr-1 bg-turquoise hover:opacity-90 text-white px-3 py-2 rounded-sm text-meta font-bold tracking-fat block",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.confirmUpdate(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Save Changes")]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.active
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "dusk-confirm-cancel-ticket-button xs:mt-3 sm:mt-0 xs:w-full tiny:w-auto bg-red-600 hover:bg-red-500 text-white px-3 py-2 rounded-sm text-meta font-bold tracking-fat",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.confirmCancel(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Cancel Ticket")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "max-w-5xl xs:w-full tiny:w-2/3 mx-auto my-10 sm:flex xs:flex-0 justify-between xs:px-6 sm:px-16 py-2 text-xs"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "sm:w-1/2 xs:w-auto sm:mr-6 xs:mr-0 sm:mb-0 xs:mb-6"
                        },
                        [
                          _c("h2", { staticClass: "text-lg" }, [
                            _vm._v("Entering the car park")
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "list-outside pl-3 text-base leading-normal"
                            },
                            [
                              _c("li", { staticClass: "mb-1 text-xs" }, [
                                _vm._v(
                                  "Our car parks are equipped with ANPR equipment which should recognise your number plate."
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "mb-1 text-xs" }, [
                                _vm._v(
                                  "In case this doesn’t work (or you come in a different vehicle or your car is very muddy!) please scan the QR code at the entry barrier."
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "text-xs" }, [
                                _vm._v(
                                  "There’s no need to print anything - our barriers are able to read QR codes."
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "sm:w-1/2 xs:w-auto sm:ml-6 xs:ml-0" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "text-lg border-b-2 border-blue border-solid pb-3"
                            },
                            [_vm._v("VAT Receipt")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex items-center justify-between"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mr-4 font-bold xs:text-meta tiny:text-meta"
                                },
                                [_vm._v("£" + _vm._s(_vm.ticket.price))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "xs:text-meta tiny:text-meta" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.momentDate(
                                        _vm.ticket.valid_from,
                                        "Do MMMM YYYY"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "bg-blue text-tiny text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start ml-2 hover:opacity-75",
                                  attrs: {
                                    href: _vm.ticket.invoice_url,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Download")]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { mode: "out-in", name: "fade-in" } },
            [
              _vm.view === "ticket" && _vm.confirming === true
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "fixed top-0 left-0 w-full h-full z-1 fixed flex justify-center items-center"
                      },
                      [
                        _c("div", {
                          staticClass:
                            "fixed top-0 left-0 w-full h-full bg-white opacity-75",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.cancelConfirmation($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "scale-up-center-slow border-2 border-solid border-dull-blue elative z-10 bg-blue rounded-sm text-white shadow-lg",
                            staticStyle: { width: "355px" }
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "m-0 p-0 text-white p-3 border-b border-solid border-dull-blue text-center"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.confirmationType === "update"
                                      ? "Ticket Update"
                                      : "Ticket Cancellation"
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            (_vm.initialEntryDate != _vm.ticket.valid_from ||
                              _vm.initialExitDate != _vm.ticket.valid_till) !=
                              0 && _vm.confirmationType === "update"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between m-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex flex-col" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-2 m-0 p-0 text-white text-meta font-bold"
                                            },
                                            [_vm._v("Car park entry")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "m-0 p-0 text-white text-meta mb-2"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.momentDate(
                                                    _vm.ticket.valid_from,
                                                    "DD/MM/YYYY - HH:mm"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.initialEntryDate !=
                                          _vm.ticket.valid_from
                                            ? _c(
                                                "p",
                                                {
                                                  class: [
                                                    "m-0 p-0  text-meta",
                                                    _vm.initialEntryDate !=
                                                    _vm.ticket.valid_from
                                                      ? "line-through text-dullish-blue"
                                                      : ""
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.momentDate(
                                                        _vm.initialEntryDate,
                                                        "DD/MM/YYYY - HH:mm"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex flex-col" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-2 m-0 p-0 text-white text-meta font-bold"
                                            },
                                            [_vm._v("Car park exit")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "m-0 p-0 text-white text-meta mb-2"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.momentDate(
                                                    _vm.ticket.valid_till,
                                                    "DD/MM/YYYY - HH:mm"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.initialExitDate !=
                                          _vm.ticket.valid_till
                                            ? _c(
                                                "p",
                                                {
                                                  class: [
                                                    "m-0 p-0 text-meta",
                                                    _vm.initialExitDate !=
                                                    _vm.ticket.valid_till
                                                      ? "line-through text-dullish-blue"
                                                      : ""
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.momentDate(
                                                        _vm.initialExitDate,
                                                        "DD/MM/YYYY - HH:mm"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.initialRegistration != _vm.registration &&
                            _vm.confirmationType === "update"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-between items-center m-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "opacity-50 bg-yolk border-solid p-plate text-black rounded-sm border-black border-1 text-small-btn font-bold uppercase"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.initialRegistration)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "w-3 h-auto opacity-50",
                                        attrs: { src: "/img/right-arrow.svg" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bg-yolk border-solid p-plate rounded-sm text-black border-black border-1 text-small-btn font-bold uppercase"
                                        },
                                        [_vm._v(_vm._s(_vm.registration))]
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.priceDiff != 0 &&
                            _vm.confirmationType === "update"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-between items-center px-4 py-3 bg-dark-blue border-t-1 border-b-1 border-solid border-dull-blue"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-xs text-white font-bold"
                                        },
                                        [_vm._v(_vm._s(_vm.duration))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex justify-between" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ml-2 font-bold text-xs text-white leading-normal"
                                            },
                                            [
                                              _vm._v(
                                                "£" + _vm._s(_vm.ticket.price)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.priceDiff != 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-1 text-xs text-dullish-blue leading-normal"
                                                },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.priceDiff > 0
                                                          ? "+"
                                                          : "-"
                                                      ) +
                                                      "£" +
                                                      _vm._s(
                                                        _vm.priceDiff > 0
                                                          ? _vm.priceDiff.toFixed(
                                                              2
                                                            )
                                                          : (-_vm.priceDiff).toFixed(
                                                              2
                                                            )
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.confirmationType == "cancel" &&
                            _vm.ticket.price != 0
                              ? _c("div", { staticClass: "m-4" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-xs leading-normal text-center"
                                    },
                                    [
                                      _vm._v(
                                        "\n              You will be refunded\n              "
                                      ),
                                      _c("span", { staticClass: "font-bold" }, [
                                        _vm._v("£" + _vm._s(_vm.existingPrice))
                                      ]),
                                      _vm._v(".\n            ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-4" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "dusk-ticket-confirm-button w-full font-bold text-small-btn tracking-fat mb-1 uppercase bg-turquoise hover:opacity-90 p-2-1/2 block text-white rounded-sm",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.confirmButtonEvent($event)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.confirmButtonText))]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "w-full font-bold text-small-btn tracking-fat mb-1 uppercase p-2-1/2 bg-red-600 hover:bg-red-700 block text-white rounded-sm",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.cancelConfirmation($event)
                                    }
                                  }
                                },
                                [_vm._v("Nevermind")]
                              )
                            ])
                          ],
                          2
                        )
                      ]
                    )
                  ]
                : _vm.view === "add-payment"
                ? [_c("AddPayment")]
                : _vm.view === "payment"
                ? [_c("Payment")]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-39a985ec", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
    <div :class="className">
        {{ message }}
    </div>
</template>

<script>
import jQuery from 'jquery'

export default {
    mounted () {
        $ = jQuery.noConflict()
    },
    data () {
        return {

        }
    },
    computed: {
        message: {
            get () {
                return this.$root.$data.store.state.message
            }
        },
    },
    methods: {
        showMessage () {
            $('.alert-message').slideDown(500, () => {
                setTimeout(() => {
                    $('.alert-message').slideUp(500)
                }, 3000)
            })
        }
    },
    props: {
        className: {
            type: String,
            default: 'alert-message'
        }
    }
}
</script>

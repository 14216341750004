<template>
  <div class="mt-header mb-10">
    <div v-if="!loading" class="w-full border-t border-solid border-dull-blue relative">
      <div
        class="py-12 lg:py-12 py-6 lg:px-0 micro:px-0 xs:px-6 w-full flex space-between items-center bg-gray-100"
      >
        <div
          class="max-w-5xl micro:w-2/3 xs:w-full m-auto sm:flex sm:justify-between sm:items-center"
        >
          <h1 class="xs:mb-6 sm:mb-0 lg:text-welcome sm:text-lg xs:text-md">
            Welcome back,
            <span
              class="md:text-lg xs:text-welcome lg:text-welcome xs:block sm:inline sm:text-lg"
            >{{ customer.first_name }}</span>
          </h1>
          <div class="self-center">
            <a
              href="/"
              class="text-center sm:inline xs:block sm:w-auto bg-blue text-white text-xs micro:px-20 xs:mb-3 micro:mb-0 no-underline py-3 rounded-sm font-bold self-center hover:opacity-90"
            >Book now</a>
            <a
              v-if="customer.citipass === 1"
              href="/my-account/citipass"
              class="text-center sm:inline xs:block sm:w-auto bg-gray-800 text-white text-xs micro:px-6 no-underline py-3 rounded-sm font-bold self-center hover:opacity-90"
            >CitiPass</a>
          </div>
        </div>
      </div>
      <div class="lg:w-full lg:mt-10 lg:p-0 micro:p-0 xs:p-6">
        <div
          class="max-w-5xl micro:w-2/3 xs:w-full m-auto flex md:flex-row md:flex-row flex-col flex-col-reverse justify-between"
        >
          <div class="md:w-5/12 md:border-r md:border-solid md:border-gray-200 lg:pr-16 md:pr-8">
            <h2 class="xs:mb-4 sm:mb-4 md:mt-0 xs:mt-6 text-lg">Payment Methods</h2>
            <transition-group appear mode="out-in" name="fade-in">
              <div
                class="flex justify-between items-center mb-2 bg-gray-400 p-3 rounded-sm"
                v-for="(card, index) in cards"
                :key="card.id"
              >
                <div class="w-2/3 flex items-center">
                  <img
                    :src="'/img/' + card.card.brand.replace('_', '-').toLowerCase() + '.svg'"
                    alt
                    class="w-8 mr-2"
                  />
                  <div class="xs:text-meta tiny:text-sm font-bold lg:text-xs md:text-meta">
                    <span class="capitalize">{{ card.card.brand }}</span>
                    ending in
                    {{ card.card.last4 }}
                  </div>
                </div>
                <button
                  @click="removeCard(card)"
                  :class="[loading ? 'opacity-50' : '', 'leading-normal rounded-sm px-2 py-1 bg-bright-red text-white text-tiny font-bold uppercase hover:bg-red-600 ' + 'dusk-remove-card-' + index]"
                >Remove</button>
              </div>
            </transition-group>
            <button
              class="dusk-add-new-card bg-blue rounded-sm pr-2 p-3 w-full block flex space-between text-white font-bold justify-between items-center hover:opacity-90"
              @click.prevent="addPayment"
            >
              <div class="flex items-center p-1">
                <img class="w-4 h-auto mr-2" src="/img/card.svg" />
                <span class="text-xs tracking-fat">Add new card</span>
              </div>
              <img class="w-4 h-auto" src="/img/plus.svg" />
            </button>
          </div>
          <div class="md:w-7/12 lg:pl-16 md:pl-8 mt-6 md:mt-0">
            <div class="md:flex justify-between mb-3 items-center">
              <h2 class="sm:mb-3 md:mb-0 lg:mb-0 text-lg">Season Tickets</h2>
              <div class="-ml-1 text-sm md:flex justify-between">
                <button
                  v-for="seasonTicketFilter in seasonTicketFilters"
                  :key="seasonTicketFilter.key"
                  type="button"
                  @click.prevent="activeSeasonTicketFilter = seasonTicketFilter"
                  :class="[activeSeasonTicketFilter.key === seasonTicketFilter.key ? 'uppercase rounded-sm bg-turquoise border-solid border-2 border-turquoise text-white text-micro font-bold px-2 py-1 ml-1' : 'bg-white uppercase rounded-sm border-solid border-2 border-blue text-micro font-bold text-blue px-2 py-1 ml-1']"
                >{{ seasonTicketFilter.label }}</button>
              </div>
            </div>
            <transition mode="out-in" name="fade-in">
              <div v-if="seasonTicketsFiltered && seasonTicketsFiltered.length > 0">
                <a
                  :href="`/my-account/season-ticket/${seasonTicket.id}`"
                  :class="['xs:w-full xs:flex-col-reverse micro:flex-row block text-white flex  p-4 mb-2 items-center rounded-sm hover:opacity-90 xs:text-center tiny:text-left', !pastRenewal(seasonTicket.renewal_date) ? 'bg-active-green' : 'bg-red-500']"
                  v-for="seasonTicket in seasonTicketsFiltered"
                  :key="seasonTicket.id"
                >
                  <div
                    class="xs:mx-auto micro:mx-0 tiny:w-1/4 xs:w-1/2 micro:w-1/4 xs:mr-auto micro:mr-8"
                  >
                    <template v-if="seasonTicket.barcode">
                      <img
                        width="100%"
                        :src="`/qr-code/${seasonTicket.barcode}`"
                        alt="QR Code"
                      />
                    </template>
                  </div>
                  <div class="xs:w-full micro:w-2/3 xs:mb-6 micro:mb-0">
                    <div
                      class="font-bold text-md xs:mb-3 tiny:mb-1"
                    >{{ seasonTicket.season && seasonTicket.season.title }} Season Ticket - £{{ parseInt(seasonTicket.price).toFixed(2) }}</div>
                    <div
                      class="text-xs"
                    >Expires {{ momentDate(seasonTicket.renewal_date, "Do MMMM YYYY") }}</div>

                    <div class="flex xs:justify-center tiny:justify-start xs:mt-3 micro:mt-8 mb-2">
                      <img
                        src="/img/small-pin.svg"
                        class="w-2 opacity-25 mr-2 xs:hidden tiny:inline"
                      />
                      <span class="font-bold text-meta">{{ seasonTicket.car_park.name }}</span>
                    </div>
                    <div class="flex xs:justify-center tiny:justify-start items-start">
                      <img
                        src="/img/small-car.svg"
                        class="w-2 opacity-25 mr-2 xs:hidden tiny:inline mt-nudge"
                      />

                      <div class="flex flex-col">
                        <p class="text-meta mb-1 m-0 p-0">{{ seasonTicket.driver }}</p>
                        <p
                          v-for="(reg, index) in seasonTicket.registrations"
                          :key="index"
                          class="text-meta mb-1 m-0 p-0"
                        >{{reg.registration}}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div v-else key="1" class="bg-gray-200 p-4">
                <p
                  class="m-0 p-0 text-meta text-gray-600 tracking-normal"
                >No season tickets found under this filter.</p>
              </div>
            </transition>

            <div class="md:flex justify-between mb-3 mt-10 items-center">
              <h2 class="sm:mb-3 md:mb-0 lg:mb-0 text-lg">Prebooking</h2>
              <div class="-ml-1 text-sm md:flex justify-between">
                <button
                  class="text-sm"
                  v-for="prebookingTicketFilter in prebookingTicketFilters"
                  :key="prebookingTicketFilter.key"
                  type="button"
                  @click.prevent="activePrebookingTicketFilter = prebookingTicketFilter"
                  :class="[activePrebookingTicketFilter.key === prebookingTicketFilter.key ? 'uppercase rounded-sm bg-turquoise border-solid border-2 border-turquoise text-white text-micro font-bold px-2 py-1 ml-1' : 'bg-white uppercase rounded-sm border-solid border-2 border-blue text-micro font-bold text-blue px-2 py-1 ml-1']"
                >{{ prebookingTicketFilter.label }}</button>
              </div>
            </div>
            <transition mode="out-in" name="fade-in">
              <template v-if="prebookingFiltered && prebookingFiltered.length > 0">
                <div key="1" class="md:flex flex-wrap justify-between lg:p-1 md:p-1 sm:p-0 pt-0">
                  <a
                    :href="`/my-account/ticket/${ticket.id}`"
                    :class="[prebookingTicketBgColor(ticket), 'text-white block relative flex flex-col md:-mx-1 -mx-0 md:w-1/2 w-full p-4 mb-2 text-xs rounded-sm hover:opacity-90']"
                    v-for="ticket in prebookingFiltered"
                    :key="ticket.id"
                  >
                    <div class="flex justify-between mb-3">
                      <div class="font-bold">PRE-{{ ticket.id }}</div>
                      <div
                        class="text-bold font-normal"
                      >{{ momentDate(ticket.valid_from, 'DD/MM/YY') }}</div>
                    </div>

                    <div class="flex mb-2">
                      <div class>
                        <img src="/img/pin.svg" class="w-full opacity-25" />
                      </div>
                      <div class="ml-2">
                        <p class="mb-1 font-bold">{{ ticket.car_park.name }}</p>
                      </div>
                    </div>

                    <div class="flex mb-2">
                      <div class>
                        <img src="/img/car.svg" class="w-full opacity-25" />
                      </div>
                      <div class="ml-2">
                        <p class="mb-2">{{ momentDate(ticket.valid_from, 'DD/MM/YYYY - HH:mm') }}</p>
                        <p class="m-0">{{ momentDate(ticket.valid_till, 'DD/MM/YYYY - HH:mm') }}</p>
                      </div>
                    </div>

                    <div class="flex">
                      <div class>
                        <img src="/img/car.svg" class="w-full opacity-25" />
                      </div>
                      <div class="ml-2">
                        <p class="mb-1">{{ ticket.registration }}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </template>
              <template v-else>
                <div key="2" class="bg-gray-200 p-4 mt-3">
                  <p
                    class="m-0 p-0 text-meta text-gray-600 tracking-normal"
                  >No prebooked tickets found under this filter.</p>
                </div>
              </template>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  mounted() {
    this.activePrebookingTicketFilter = this.prebookingTicketFilters[0];
    this.activeSeasonTicketFilter = this.seasonTicketFilters[0];
    this.$root.$data.store.showLoader();
    this.$http.get(`/ticket/all`).then(
      response => {
        this.loading = false;
        this.tickets = response.body.tickets;
        this.seasonTickets = response.body.seasonTickets;


        this.$root.$data.store.hideLoader();
      },
      err => {}
    );
  },
  data() {
    return {
      loading: true,
      tickets: null,
      seasonTickets: null,
      activePrebookingTicketFilter: null,
      activeSeasonTicketFilter: null,
      prebookingTicketFilters: [
        {
          key: "active",
          filter(data) {
            return (
              data.status === "valid" &&
              moment(data.valid_till).isAfter(moment())
            );
          },
          label: "Active"
        },
        {
          key: "expired",
          filter(data) {
            return (
              data.status === "valid" &&
              moment(data.valid_till).isBefore(moment())
            );
          },
          label: "Expired"
        },
        {
          key: "cancelled",
          filter(data) {
            return data.status === "invalid";
          },
          label: "Cancelled"
        }
      ],
      seasonTicketFilters: [
        {
          key: "active",
          filter(data) {
            return moment(data).isAfter(moment());
          },
          label: "Active"
        },
        {
          key: "expired",
          filter(data) {
            return moment(data).isBefore(moment());
          },
          label: "Expired"
        }
      ]
    };
  },
  computed: {
    customer: {
      get() {
        return this.$root.$data.store.customer;
      },
      set(value) {
        this.$root.$data.store.setObject("customer", value);
      }
    },
    cards: {
      get() {
        return this.$root.$data.store.customer.cards;
      },
      set(value) {
        this.$root.$data.store.setField("customer", "cards", value);
      }
    },
    seasonTicketsFiltered() {
      if (!this.seasonTickets) return [];
      return this.seasonTickets.filter(seasonTicket => {
        return this.activeSeasonTicketFilter.filter(seasonTicket.renewal_date);
      });
    },
    prebookingFiltered() {
      if (!this.tickets) return [];
      return this.tickets.filter(ticket => {
        return this.activePrebookingTicketFilter.filter(ticket);
      });
    }
  },
  methods: {
    prebookingTicketBgColor(ticket) {
      if (ticket.status == "valid") {
        if (
          moment(ticket.valid_from).isBefore(moment()) &&
          moment(ticket.valid_till).isAfter(moment())
        ) {
          return "bg-active-green";
        } else {
          return "bg-turquoise";
        }
      } else {
        return "bg-red-500";
      }
    },
    momentDate(date, format) {
      return moment(date).format(format);
    },
    pastRenewal(renewalDate) {
      return moment(renewalDate).isBefore(moment());
    },
    addPayment() {
      this.$router.push({ name: "addPayment" });
    },
    removeCard(card) {
      var confirm = window.confirm(
        "Are you sure you want to remove this card?"
      );
      if (!confirm) {
        return false;
      }

      this.$root.$data.store.showLoader();

      this.$http
        .post(
          "/card/remove",
          {
            customer: this.customer.stripe,
            card: card.id
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.cards = response.body;
            this.$root.$data.store.showMessage(
              "Card ending in " +
                card.card.last4 +
                " has been removed from your account successfully.",
              "bg-active-green"
            );
            this.removingCard = false;
            this.$root.$data.store.hideLoader();
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(err.body.error, "bg-red-600");
            this.removingCard = false;
          }
        );
    }
  }
};
</script>

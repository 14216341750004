<template>
  <div class="registration section">
    <transition name="fade-in" mode="out-in">
      <form v-if="view === 'answer'" @submit.prevent="go">
        <div class="center">
          <h1 class="text-blue xs:text-lg micro:text-xl">Vehicle Registration</h1>
          <TextInput>
            <input
              class="reg-number-input"
              value
              v-model="registrationDisplayText"
              autofocus
              tabindex="1"
            />
          </TextInput>
        </div>
      </form>
      <div v-else class="center">
        <h1 class="text-blue xs:text-lg micro:text-xl">Do you wish to add another vehicle?</h1>
        <p
          class="text-meta text-center leading-normal text-blue -mt-4 font-bold"
        >You may add an additional registration, but only one vehicle can be in the car park at one time.</p>
        <div class="sm:flex sm:flex-row xs:flex-col justify-between">
          <button
            type="button"
            class="dusk-additionalreg-yes sm:w-1/2 xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue xs:mr-0 sm:mr-2 xs:mb-1 sm:mb-0 hover:opacity-90"
            @click.prevent="answer(true)"
          >Yes</button>
          <button
            type="button"
            class="dusk-additionalreg-no sm:w-1/2 xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue hover:opacity-90"
            @click.prevent="answer(false)"
          >No</button>
        </div>
      </div>
    </transition>
    <transition name="fade-in" mode="out-in">
      <ButtonFixed v-if="view === 'answer'" :active="nextButtonActive" :clickEvent="go">Continue</ButtonFixed>
    </transition>
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import AutoFill from "../../store/autofill.js";

export default {
  mounted() {
    this.registrationText = "";
    this.$root.$data.store.setObject("progress", 3);
  },
  created() {
    if (this.$root.$data.store.autoFill) this.autoFill();

    if (this.$root.$data.store.carParks.length === 0) {
      this.$router.push({ name: "location" });
    }
  },
  data() {
    return {
      registrationText: "",
      view: "question"
    };
  },
  computed: {
    registrationDisplayText: {
      get() {
        return this.registrationText;
      },
      set(value) {
        this.registrationText = value;
        value = value
          .toUpperCase()
          .replace(" ", "")
          .trim();
        this.$root.$data.store.setField(
          "ticket",
          "additional_registration",
          value
        );
      }
    },
    registration() {
      if (!this.$root.$data.store.ticket.additional_registration) return "";
      return this.$root.$data.store.ticket.additional_registration;
    },
    otherRegistration() {
      return this.$root.$data.store.ticket.registration;
    },
    nextButtonActive() {
      return (
        this.registration !== null &&
        this.registrationValid() &&
        this.registration.length > 0
      );
    },
    ticketType() {
      return this.$root.$data.store.state.ticketType;
    },
    carPark() {
      return this.$root.$data.store.ticket.carPark;
    }
  },
  components: {
    SectionTitle,
    TextInput,
    ButtonFixed
  },
  methods: {
    answer(answer) {
      if (answer === true) {
        this.view = "answer";
      } else {
        this.$router.push({ name: "driver" });
      }
    },
    go() {
      if (!this.nextButtonActive) {
        return false;
      }

      this.$router.push({ name: "driver" });
    },
    registrationValid() {
      // let regex = RegExp(
      //   "(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)"
      // );

      let regex = new RegExp(/^[A-Za-z0-9\s]{2,}$/);

      return (
        regex.test(this.registration) &&
        this.otherRegistration
          .toUpperCase()
          .replace(" ", "")
          .trim() !==
          this.registration
            .toUpperCase()
            .replace(" ", "")
            .trim()
      );
    },
    autoFill() {
      this.$root.$data.store.setField(
        "ticket",
        "registration",
        AutoFill.ticket.registration
      );
    }
  }
};
</script>

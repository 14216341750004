var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-password section" },
    [
      _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
        _vm._v("Check your email " + _vm._s(_vm.email))
      ]),
      _vm._v(" "),
      _c("TextInput", { attrs: { slim: "true" } }, [
        _c("div", { staticClass: "input-upper" }, [
          _c("div", { staticClass: "input-upper--left" }, [
            _vm._v("Verification Code")
          ])
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.verificationCode,
              expression: "verificationCode"
            }
          ],
          class: [
            "input--underlined",
            "margin-bottom-30",
            _vm.verificationCode !== null &&
            _vm.verificationCode.length === 0 &&
            _vm.formSubmitted
              ? "input--invalid"
              : ""
          ],
          attrs: { type: "text", placeholder: "", value: "" },
          domProps: { value: _vm.verificationCode },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.verificationCode = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("TextInput", { attrs: { slim: "true" } }, [
        _c("div", { staticClass: "input-upper" }, [
          _c("div", { staticClass: "input-upper--left" }, [
            _vm._v("New Password")
          ])
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          class: [
            "input--underlined",
            "margin-bottom-30",
            _vm.password !== null &&
            _vm.password.length === 0 &&
            _vm.formSubmitted
              ? "input--invalid"
              : ""
          ],
          attrs: { type: "password", value: "" },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("TextInput", { attrs: { slim: "true" } }, [
        _c("div", { staticClass: "input-upper" }, [
          _c("div", { staticClass: "input-upper--left" }, [
            _vm._v("New Password Confirmation")
          ])
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordConfirm,
              expression: "passwordConfirm"
            }
          ],
          class: [
            "input--underlined",
            "margin-bottom-30",
            _vm.passwordConfirm !== null &&
            _vm.passwordConfirm.length === 0 &&
            _vm.formSubmitted
              ? "input--invalid"
              : ""
          ],
          attrs: { type: "password", value: "" },
          domProps: { value: _vm.passwordConfirm },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.passwordConfirm = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "ButtonFixed",
        {
          attrs: {
            loading: _vm.loading,
            active: _vm.nextButtonActive,
            clickEvent: _vm.reset
          }
        },
        [_vm._v("Change password")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-012c7520", { render: render, staticRenderFns: staticRenderFns })
  }
}
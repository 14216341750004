<template>
    <a href="#" @click.prevent="clickEvent(clickEventParams)" :class="['btn-list-item', gridClass]" :disabled="true">
        <slot></slot>
    </a>
</template>

<script>

export default {
    data () {
        return {
        }
    },
    props: {
        clickEvent: Function,
        clickEventParams: [Object, Array, String],
        gridClass: {
            default: 'grid--item',
            type: [String, Array]
        }
    }
}
</script>

<template>
  <div class="car-park section mb-20">
    <h1 class="text-blue xs:text-lg micro:text-xl">Enter your driver(s)</h1>
    <div class="drivers xs:w-full micro:W-2/3 m-auto">
      <div v-for="(driver, index) in drivers" :key="index" class="flex mb-4">
        <input
          type="text"
          v-model="driver.driver"
          placeholder="Driver's Name"
          class="w-4/6 border-t-0 border-l-0 border-r-0 border-solid border-b mr-4 border-turquoise text-center xs:text-meta micro:text-sm"
        />
        <input
          v-model="driver.registration"
          type="text"
          placeholder="Reg Number"
          class="w-2/6 border-t-0 border-l-0 border-r-0 border-solid border-b mr-4 border-turquoise uppercase text-center xs:text-meta micro:text-sm"
        />
        <button
          type="button"
          @click.prevent="remove(index)"
          class="bg-white w-1/30 flex flex-column p-0 justify-center hover:opacity-80"
        >
          <img src="/img/red-cross.svg" class="w-full" />
        </button>
      </div>
      <button
        type="submit"
        class="xs:w-full micro:w-1/2 text-white p-3 rounded-sm font-bold block mx-auto bg-blue xs:mt-10 micro:mt-16 hover:opacity-90 text-sm"
        @click.prevent="add"
      >Add Another Driver</button>
    </div>
    <ButtonFixed :loading="loading" :active="nextButtonActive" :clickEvent="next">Continue</ButtonFixed>
  </div>
</template>

<script>
import Vue from "vue";
import SectionTitle from "../objects/SectionTitle.vue";
import ButtonList from "../objects/ButtonList.vue";
import ButtonListItem from "../objects/ButtonListItem.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";

export default {
  mounted() {
    this.$root.$data.store.setObject("progress", 3);

    if (this.drivers.length == 0) {
      this.add();
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    nextButtonActive() {
      if (this.drivers.length === 0) {
        return false;
      }

      return (
        this.drivers.filter(driver => {
          return driver.driver === "" || driver.registration === "";
        }).length == 0
      );
    },
    carPark() {
      return this.$root.$data.store.ticket.carPark;
    },
    drivers: {
      get() {
        return this.$root.$data.store.ticket.drivers;
      },
      set(drivers) {
        drivers = drivers.map(driver => {
          driver.registration = driver.registration;
          return driver;
        });

        this.$root.$data.store.setField("ticket", "drivers", drivers);
      }
    }
  },
  components: {
    SectionTitle,
    ButtonList,
    ButtonListItem,
    ButtonFixed
  },
  methods: {
    next() {
      if (this.drivers.length === 0) {
        return false;
      }

      if (
        this.drivers.filter(driver => {
          return driver.driver === "" || driver.registration === "";
        }).length > 0
      ) {
        return false;
      }

      this.drivers = this.drivers.map(driver => {
        driver.registration = driver.registration
          .toUpperCase()
          .replace(/[^A-Za-z0-9]+/, "")
          .trim();
        return driver;
      });

      this.$router.push({
        name: "seasons",
        params: { carpark: this.carPark.id }
      });
    },
    add() {
      let drivers = this.drivers || [];

      drivers.push({
        driver: "",
        registration: ""
      });

      this.drivers = drivers;
    },
    remove(index) {
      let drivers = this.drivers;

      drivers.splice(index, 1);

      this.drivers = drivers;
    },
    registrationValid(reg) {
      // let regex = RegExp(
      //   "(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)"
      // );

      let regex = new RegExp(/^[A-Za-z0-9\s]{2,}$/);

      return regex.test(reg);
    }
  }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vat-receipt" }, [
    _c("div", { staticClass: "vat-receipt--title" }, [
      _vm._v("VAT Receipt for parking - PRE-" + _vm._s(_vm.ticket.id))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vat-receipt--attributes" }, [
      _c(
        "ul",
        _vm._l(_vm.attributes, function(atts) {
          return _c("li", { key: atts.label }, [
            _c("span", { class: ["vat-receipt--attributes__label", "bold"] }, [
              _vm._v(_vm._s(atts.label))
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                class: [
                  "vat-receipt--attributes__value",
                  atts.bold ? "bold" : ""
                ]
              },
              [_vm._v(_vm._s(atts.value))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "clear" })
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "vat-receipt--content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-12852421", { render: render, staticRenderFns: staticRenderFns })
  }
}
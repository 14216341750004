<template>
    <div class="section confirm">
        <SectionTitle :underlined="true">{{ title }}</SectionTitle>
        <slot></slot>
        <ButtonFixed :buttonClass="buttonClass" :loading="buttonLoading" :active="true" :clickEvent="clickEvent" :clickEventParams="clickEventParams">{{ buttonLabel }}</ButtonFixed>
    </div>
</template>

<script>
import SectionTitle from '../objects/SectionTitle.vue'
import ButtonFixed from '../objects/ButtonFixed.vue'

export default {
    props: ['title', 'buttonLabel', 'buttonClass', 'buttonLoading', 'buttonActive', 'clickEvent', 'clickEventParams'],
    components: {
        SectionTitle,
        ButtonFixed
    }
}
</script>

<template>
    <div class="button--fixed">
        <a :class="['btn', (loading ? 'btn--loading' : ''), (!active ? 'btn--disabled' : ''), buttonClass]" @click.prevent="clickEvent(clickEventParams)" href="#">
            <span class="btn--text">
                <slot></slot>
            </span>
            <span class="btn--loader">
                <img src="/img/loader.svg" width="40" alt="" />
            </span>
        </a>
    </div>
</template>

<script>

export default {
    data () {
        return {
        }
    },
    props: {
        className: {
            type: String,
            required: false
        },
        clickEvent: {
            type: Function
        },
        clickEventParams: [Object, Array, String, Number],
        active: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: ''
        }
    }
}
</script>

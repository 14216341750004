<template>
    <div :class="['btn-list', gridClass]">
        <slot></slot>
    </div>
</template>

<script>
import ButtonListItem from './ButtonListItem.vue'

export default {
    data () {
        return {
        }
    },
    components: {
        ButtonListItem
    },
    props: {
        gridClass: {
            type: String,
            default: 'grid'
        }
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isSelectingWithUrlParam
    ? _c(
        "div",
        { staticClass: "location section" },
        [
          _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
            _vm._v("Choose City")
          ]),
          _vm._v(" "),
          _vm.locations && Object.keys(_vm.locations).length > 0
            ? _c(
                "ButtonList",
                _vm._l(_vm.locations, function(location) {
                  return _c(
                    "ButtonListItem",
                    {
                      key: location.id,
                      attrs: {
                        gridClass:
                          "grid--item" + " dusk-location-" + location.id,
                        clickEvent: _vm.select,
                        clickEventParams: location
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(location.name))])]
                  )
                }),
                1
              )
            : _c("div", { staticClass: "text-center" }, [
                _c("p", [
                  _vm._v(
                    "Sorry, there are no available locations to choose from."
                  )
                ])
              ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d41302e2", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div v-if="!isSelectingWithUrlParam" class="car-park section">
    <h1 class="text-blue xs:text-lg micro:text-xl">Choose Car Park</h1>
    <ButtonList gridClass="grid justify-center">
      <ButtonListItem
        :key="carPark.id"
        :gridClass="'grid--item' + ' dusk-carpark-' + carPark.id"
        v-for="carPark in carParks"
        :clickEvent="select"
        :clickEventParams="carPark"
      >
        <span>{{ carPark.name }}</span>
      </ButtonListItem>
    </ButtonList>
  </div>
</template>

<script>
import ButtonList from '../objects/ButtonList.vue';
import ButtonListItem from '../objects/ButtonListItem.vue';
import SectionTitle from '../objects/SectionTitle.vue';
import Vue from 'vue';

export default {
  components: {
    ButtonList,
    ButtonListItem,
    SectionTitle,
  },
  data() {
    return {
      isSelectingWithUrlParam: true,
      carParks: [],
    };
  },
  computed: {
    ticketType: {
      get() {
        return this.$root.$data.store.state.ticketType;
      },
    },
    location: {
      get() {
        return this.$root.$data.store.ticket.location;
      },
    },
    carPark: {
      get() {
        return this.$root.$data.store.ticket.carPark;
      },
    },
  },
  watch: {
    carParks: function () {
      this.selectWithUrlParam();
    },
  },
  created() {
    this.fetchCarParks();
  },
  mounted() {
    this.$root.$data.store.setObject('progress', 2);
  },
  methods: {
    fetchCarParks() {
      Vue.http
        .get(
          `/locations/${this.location.id}/car_parks?${
            this.ticketType === 'prebook' ? 'is_prebooking' : 'is_season'
          }=1`
        )
        .then((response) => {
          if (!response.body) {
            this.$router.push({ name: 'location' });
            return;
          }

          this.carParks = Object.values(response.body);
        })
        .catch((error) => console.log(error));
    },
    select(carPark) {
      this.$root.$data.store.setCarPark(carPark);
      if (this.ticketType === 'prebook') {
        this.$router.push({ name: 'registration' });
      } else {
        this.$router.push({ name: 'startDate' });
      }
    },
    selectWithUrlParam() {
      const urlParams = new URLSearchParams(window.location.search);

      const carPark = this.carParks.find(
        (carPark) => String(carPark.id) === urlParams.get('car_park')
      );

      if (carPark !== undefined && this.carPark === null) {
        this.select(carPark);
      } else {
        this.isSelectingWithUrlParam = false;
      }
    },
  },
};
</script>

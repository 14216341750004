var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress" }, [
    _c(
      "div",
      { staticClass: "progress--blocks" },
      _vm._l(_vm.blocks, function(block) {
        return _c("div", {
          key: block,
          staticClass: "progress--block",
          class: [_vm.active(block) ? "active" : ""]
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4701baff", { render: render, staticRenderFns: staticRenderFns })
  }
}
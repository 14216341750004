var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isEntryDatePickerSetup && _vm.isExitDatePickerSetup,
          expression: "isEntryDatePickerSetup && isExitDatePickerSetup"
        }
      ],
      staticClass: "dates wrapper section"
    },
    [
      _c(
        "div",
        { class: ["datepicker-title", _vm.datepicker !== "" ? "open" : ""] },
        [
          _c(
            "h1",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.hideDatePicker($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.datePickerTitle))]
          )
        ]
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
        _vm._v("Entry and Exit")
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { "line-height": "30px" } }, [
        _vm._v(
          "\n    Choose an entry and exit date and time by clicking on them.\n  "
        )
      ]),
      _vm._v(" "),
      _c("TextInput", { attrs: { slim: "true" } }, [
        _c("div", { staticClass: "input-upper" }, [
          _c("div", { staticClass: "input-upper--right" }, [
            _vm._v("Entry time")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-upper--left" }, [
            _vm._v("Car park entry")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: ["input--datepicker", !_vm.isEntryDateValid ? "invalid" : ""]
          },
          [
            _c("span", { staticClass: "input--datepicker__time" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.moment(_vm.entryDate).format("HH:mm")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "input--datepicker__date" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.moment(_vm.entryDate).format("Do MMMM YYYY")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "input--datepicker__hitbox",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showDatePicker("entry-time", "Entry Time")
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              class: [
                "input--underlined",
                !_vm.isEntryDateValid ? "input--invalid" : ""
              ],
              attrs: { readonly: "", type: "text", id: "entry-time" }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("TextInput", { attrs: { slim: "true" } }, [
        _c("div", { staticClass: "input-upper" }, [
          _c("div", { staticClass: "input-upper--right" }, [
            _vm._v("Exit time")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-upper--left" }, [
            _vm._v("Car park exit")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: ["input--datepicker", !_vm.isExitDateValid ? "invalid" : ""]
          },
          [
            _c("span", { staticClass: "input--datepicker__time" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.moment(_vm.exitDate).format("HH:mm")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "input--datepicker__date" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.moment(_vm.exitDate).format("Do MMMM YYYY")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "input--datepicker__hitbox",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showDatePicker("exit-time", "Exit Time")
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              class: [
                "input--underlined",
                !_vm.isExitDateValid ? "input--invalid" : ""
              ],
              attrs: { readonly: "", type: "text", id: "exit-time" }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _vm.duration !== null
        ? _c("TextInput", { attrs: { slim: "true" } }, [
            _c("div", { staticClass: "input-upper" }, [
              _c("div", { staticClass: "input-upper--left" }, [
                _vm._v("Reservation duration")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "clear" })
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "input--non-underlined" }, [
              _vm._v(_vm._s(_vm.duration))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("TextInput", { attrs: { slim: "true" } }, [
        _c("div", { staticClass: "input-upper" }, [
          _c("div", { staticClass: "input-upper--left" }, [_vm._v("Price")]),
          _vm._v(" "),
          _c("div", { staticClass: "clear" })
        ]),
        _vm._v(" "),
        _vm.isCalculatingPrice
          ? _c("p", { staticClass: "input--non-underlined" }, [
              _vm._v("\n      Calculating...\n    ")
            ])
          : _c("p", { staticClass: "input--non-underlined" }, [
              _vm._v("£" + _vm._s(_vm.totalPrice))
            ])
      ]),
      _vm._v(" "),
      _vm.exceptionsMet
        ? _c("p", { staticStyle: { "line-height": "30px", color: "red" } }, [
            _vm._v(
              "\n    We have opening hours at Cheltenham of 09:00 - 18:00. When prebooking a\n    parking space for our John Lewis & Partners Car Park Cheltenham,\n    please take note of the car parks opening hours. A charge of £50 is\n    payable for out of hours release.\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isCarParkFull
        ? _c("p", { staticStyle: { "line-height": "30px", color: "red" } }, [
            _vm._v(
              "\n    Sorry, there are no available spaces for the requested time, please select\n    a different time.\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ButtonFixed",
        {
          attrs: {
            dusk: "continuebutton",
            loading: _vm.isCalculatingPrice || _vm.isCheckingCapacity,
            active: _vm.isDatesValid,
            clickEvent: _vm.submit
          }
        },
        [_vm._v("\n    Continue\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a9d649f0", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div v-if="!isSubmittingWithUrlParam" class="registration section">
    <form @submit.prevent="submit">
      <div class="center">
        <h1 class="text-blue xs:text-lg micro:text-xl">Vehicle Registration</h1>
        <TextInput>
          <input
            class="reg-number-input"
            value
            v-model="registration"
            autofocus
            tabindex="1"
          />
        </TextInput>
      </div>
    </form>
    <ButtonFixed :active="isRegistrationValid" :clickEvent="submit">
      Continue
    </ButtonFixed>
  </div>
</template>

<script>
import ButtonFixed from '../objects/ButtonFixed.vue';
import SectionTitle from '../objects/SectionTitle.vue';
import TextInput from '../objects/TextInput.vue';

export default {
  components: {
    ButtonFixed,
    SectionTitle,
    TextInput,
  },
  data() {
    return {
      isSubmittingWithUrlParam: true,
    };
  },
  computed: {
    ticketType: {
      get() {
        return this.$root.$data.store.state.ticketType;
      },
    },
    carPark: {
      get() {
        return this.$root.$data.store.ticket.carPark;
      },
    },
    registration: {
      get() {
        return this.$root.$data.store.ticket.registration;
      },
      set(value) {
        this.$root.$data.store.setField(
          'ticket',
          'registration',
          value.toUpperCase().replace(/\s/g, '')
        );
      },
    },
    isRegistrationValid() {
      return RegExp(/^[0-9A-Z]{2,}$/).test(this.registration);
    },
  },
  created() {
    this.submitWithUrlParam();
  },
  mounted() {
    this.$root.$data.store.setObject('progress', 3);
  },
  methods: {
    submit() {
      if (!this.isRegistrationValid) {
        return;
      }

      if (this.ticketType === 'prebook') {
        this.$router.push({ name: 'dates' });
      } else {
        this.$router.push({
          name: 'additionalRegistration',
          params: { carpark: this.carPark.id },
        });
      }
    },
    submitWithUrlParam() {
      if (this.registration === null) {
        const urlParams = new URLSearchParams(window.location.search);

        this.registration =
          typeof urlParams.get('registration') === 'string'
            ? urlParams.get('registration')
            : '';

        if (this.isRegistrationValid) {
          this.submit();
          return;
        }
      }

      this.isSubmittingWithUrlParam = false;
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration section" },
    [
      _c("transition", { attrs: { name: "fade-in", mode: "out-in" } }, [
        _vm.view === "answer"
          ? _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.go($event)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _c(
                      "h1",
                      { staticClass: "text-blue xs:text-lg micro:text-xl" },
                      [_vm._v("Vehicle Registration")]
                    ),
                    _vm._v(" "),
                    _c("TextInput", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registrationDisplayText,
                            expression: "registrationDisplayText"
                          }
                        ],
                        staticClass: "reg-number-input",
                        attrs: { value: "", autofocus: "", tabindex: "1" },
                        domProps: { value: _vm.registrationDisplayText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.registrationDisplayText = $event.target.value
                          }
                        }
                      })
                    ])
                  ],
                  1
                )
              ]
            )
          : _c("div", { staticClass: "center" }, [
              _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
                _vm._v("Do you wish to add another vehicle?")
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-meta text-center leading-normal text-blue -mt-4 font-bold"
                },
                [
                  _vm._v(
                    "You may add an additional registration, but only one vehicle can be in the car park at one time."
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "sm:flex sm:flex-row xs:flex-col justify-between"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "dusk-additionalreg-yes sm:w-1/2 xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue xs:mr-0 sm:mr-2 xs:mb-1 sm:mb-0 hover:opacity-90",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.answer(true)
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "dusk-additionalreg-no sm:w-1/2 xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue hover:opacity-90",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.answer(false)
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ]
              )
            ])
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-in", mode: "out-in" } },
        [
          _vm.view === "answer"
            ? _c(
                "ButtonFixed",
                { attrs: { active: _vm.nextButtonActive, clickEvent: _vm.go } },
                [_vm._v("Continue")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0138202c", { render: render, staticRenderFns: staticRenderFns })
  }
}
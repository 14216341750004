<template>
  <div
    class="mt-header w-full border-t border-solid border-dull-blue mb-10"
    v-if="!loading"
  >
    <div :class="['datepicker-title', datepicker !== '' ? 'open' : '']">
      <h1>{{ datePickerTitle }}</h1>
    </div>
    <div class="bg-blue w-full pb-50">
      <div
        class="max-w-5xl sm:w-2/3 xs:w-auto xs:mx-6 sm:mx-auto flex sm:flex-row xs:flex-col justify-between items-center bg-blue xs:mb-16 sm:mb-20 py-8"
      >
        <div
          class="flex flex-col xs:justify-center sm:justify-start xs:mb-4 sm:mb-0"
        >
          <a
            class="text-sm text-white font-bold block flex items-center mb-2 xs:justify-center sm:justify-start"
            href="/my-account"
          >
            <img
              src="/img/small-chevron-white.svg"
              class="w-2 h-auto mr-1"
              alt
            />
            <span class="text-meta mr-1">Back to my account</span>
          </a>
          <h2 class="text-white font-bold text-lg m-0 text-center">
            CitiPass Administration
          </h2>
        </div>
        <div
          class="flex sm:flex-row xs:flex-col justify-between xs:w-full sm:w-auto flex sm:flex-row xs:flex-col justify-between"
        ></div>
      </div>
    </div>

    <div class="flex sm:flex-row xs:flex-col justify-between -mt-overlap">
      <div
        class="max-w-5xl micro:w-2/3 xs:w-auto mini:mx-6 xs:mx-0 bg-white sm:flex-row shadow-sm sm:p-10 xs:p-6 micro:mx-auto rounded-sm"
      >
        <div>
          <template v-if="isEditing">
            <h2 class="mt-0 text-lg">Edit Whitelist</h2>
            <form class="pb-10" @submit.prevent="updateCitiPass">
              <h3 class="text-sm font-bold">Name</h3>
              <input
                class="text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4"
                v-model="citiPassToUpdate.name"
              />
              <h3 class="text-sm font-bold">Email (Optional)</h3>
              <input
                class="text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4"
                v-model="citiPassToUpdate.email"
              />
              <h3 class="text-sm font-bold">Registration</h3>
              <input
                class="text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 uppercase"
                v-model="citiPassToUpdate.registration"
              />
              <h3 class="text-sm font-bold">Valid From</h3>
              <button
                id="edit-entry-time"
                @click.prevent="
                  toggleDatePicker('edit-entry-time', 'Valid From')
                "
                class="p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white"
              >
                {{
                  momentDate(citiPassToUpdate.valid_from, "Do MMMM YYYY HH:mm")
                }}
              </button>
              <h3 class="text-sm font-bold">Valid Till</h3>
              <button
                id="edit-exit-time"
                @click.prevent="
                  toggleDatePicker('edit-exit-time', 'Valid Till')
                "
                class="p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white"
              >
                {{
                  momentDate(citiPassToUpdate.valid_till, "Do MMMM YYYY HH:mm")
                }}
              </button>
              <h3 class="text-sm font-bold pb-3">Car Park</h3>
              <select
                class="w-full mb-3 block"
                v-model="citiPassToUpdate.carpark"
              >
                <option disabled value>Select a car park...</option>
                <option
                  v-for="carPark in carParks"
                  :key="carPark.id"
                  v-bind:value="carPark.id"
                  >{{ carPark.name }}</option
                >
              </select>
              <button
                type="submit"
                class="text-sm xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue hover:opacity-90"
              >
                Update Entry
              </button>
              <button
                @click.prevent="cancelCitiPassEdit"
                class="mt-2 text-sm xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-red-700 hover:opacity-90"
              >
                Cancel Editing
              </button>
            </form>
          </template>
          <template v-else>
            <h2 class="mt-0 text-md">Add To Whitelist</h2>
            <form class="pb-10" @submit.prevent="addCitiPass">
              <h3 class="text-sm font-bold">Name</h3>
              <input
                class="text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4"
                v-model="citiPassToAdd.name"
              />
              <h3 class="text-sm font-bold">Email (Optional)</h3>
              <input
                class="text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4"
                v-model="citiPassToAdd.email"
              />
              <h3 class="text-sm font-bold">Registration</h3>
              <input
                class="text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 uppercase"
                v-model="citiPassToAdd.registration"
              />
              <h3 class="text-sm font-bold">Valid From</h3>
              <button
                id="edit-entry-time"
                @click.prevent="
                  toggleDatePicker('edit-entry-time', 'Valid From')
                "
                class="p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white"
              >
                {{ momentDate(citiPassToAdd.valid_from, "Do MMMM YYYY HH:mm") }}
              </button>
              <h3 class="text-sm font-bold">Valid Till</h3>
              <button
                id="edit-exit-time"
                @click.prevent="
                  toggleDatePicker('edit-exit-time', 'Valid Till')
                "
                class="p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white"
              >
                {{ momentDate(citiPassToAdd.valid_till, "Do MMMM YYYY HH:mm") }}
              </button>
              <h3 class="text-sm font-bold pb-3">Car Park</h3>
              <select class="w-full mb-3 block" v-model="citiPassToAdd.carpark">
                <option disabled value>Select a car park...</option>
                <option
                  v-for="carPark in carParks"
                  :key="carPark.id"
                  v-bind:value="carPark.id"
                  >{{ carPark.name }}</option
                >
              </select>
              <button
                type="submit"
                class="text-sm xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue hover:opacity-90"
              >
                Add Entry
              </button>
            </form>
          </template>

          <h2 class="xs:mb-4 text-md">Entries</h2>
          <template v-if="entries && entries.length > 0">
            <div class>
              <div
                class="flex flex-row text-center border-l-0 border-r-0 border-solid border-t border-b py-3 border-turquoise"
              >
                <div class="w-1/6 font-bold text-sm">Name</div>
                <div class="w-1/6 font-bold px-2 text-sm">Registration</div>
                <div class="w-1/6 font-bold px-2 text-sm">Valid From</div>
                <div class="w-1/6 font-bold px-2 text-sm">Valid Till</div>
                <div class="w-1/6 font-bold px-2 text-sm">Car Park</div>
                <div class="w-1/6 font-bold text-sm">Manage</div>
              </div>
              <div
                class="text-center flex flex-row border-t-0 border-l-0 border-r-0 border-solid border-b py-6 border-turquoise"
                v-for="entry in entries"
                :key="entry.id"
              >
                <template v-if="entries.isEditing"></template>
                <template></template>
                <div class="w-1/6 text-xs leading-snug">{{ entry.name }}</div>
                <div class="w-1/6 px-2 text-xs leading-none">
                  {{ entry.registration }}
                  <button
                    v-if="
                      entry.email != '' && entry.barcode && entry.barcode != ''
                    "
                    @click.prevent="resendQR(entry)"
                    class="text-tiny bg-gray-600 text-white ml-1 px-1 py-1 rounded-sm uppercase font-bold self-start hover:opacity-75"
                  >
                    QR
                  </button>
                </div>
                <div class="w-1/6 px-2 text-xs leading-snug">
                  {{ momentDate(entry.valid_from, "HH:mm Do MMMM YYYY") }}
                </div>
                <div class="w-1/6 px-2 text-xs leading-snug">
                  {{ momentDate(entry.valid_till, "HH:mm Do MMMM YYYY") }}
                </div>
                <div class="w-1/6 px-2 text-xs leading-snug">
                  {{ entry.car_park ? entry.car_park.name : 'Car park not found' }}
                </div>
                <div class="w-1/6 text-xs flex justify-between">
                  <button
                    @click.prevent="editCitiPass(entry)"
                    class="text-micro mx-auto bg-blue text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start hover:opacity-75"
                  >
                    Edit
                  </button>
                  <button
                    @click.prevent="removeCitiPass(entry.id)"
                    class="text-small-btn mx-auto bg-red-700 text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start hover:opacity-75"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p>No entries found.</p>
          </template>
          <div class>
            <div class="flex flex-row">
              <div class="w-1/2">
                <h2 class="xs:mb-4 sm:mb-4 md:mt-6 xs:mt-6 text-md">
                  Vehicle Logs
                </h2>
              </div>
              <div class="w-1/2">
                <form action="/citipass/export" method="POST" class="xs:mb-4 sm:mb-4 md:mt-6 xs:mt-6 md:float-right">
                  <input type="hidden" name="_token" :value="csrf" />
                  <button class="text-sm mx-auto bg-blue text-white px-2 py-1-h rounded-sm font-bold block self-start hover:opacity-75" type="submit">Export Historical Logs</button>
                </form>
              </div>
            </div>
            <div
              class="flex flex-row text-center border-l-0 border-r-0 border-solid border-t border-b py-3 border-turquoise"
            >
              <div class="w-1/4 font-bold text-xs">Movement</div>
              <div class="w-1/4 font-bold px-2 text-xs">Time</div>
              <div class="w-1/4 font-bold px-2 text-xs">Registration</div>
              <div class="w-1/4 font-bold px-2 text-xs">Car Park</div>
            </div>
            <div
              class="text-center flex flex-row border-t-0 border-l-0 border-r-0 border-solid border-b py-6 border-turquoise"
              v-for="(log, index) in vehicleLogs"
              :key="index"
            >
              <div class="w-1/4 px-2 text-xs leading-snug">
                {{ log.movement }}
              </div>
              <div class="w-1/4 px-2 text-xs leading-snug">
                {{ momentDate(log.captured_at, 'Do MMM YYYY - HH:mm')}}
              </div>
              <div class="w-1/4 px-2 text-xs leading-snug">
                {{ log.vehicle }}
              </div>
              <div class="w-1/4 px-2 text-xs leading-snug">
                {{ log.car_park }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleButton from "../objects/Button.vue";
import SectionTitle from "../objects/SectionTitle.vue";
import moment from "moment";
import VatReceipt from "../objects/VatReceipt.vue";
import jQuery from "jquery";

export default {
  components: {
    SectionTitle,
    VatReceipt,
    SimpleButton,
  },
  mounted() {
    if (this.$root.$data.store.autoFill) this.autoFill();

    this.$root.$data.store.showLoader();
    this.$http.get(`/citipass/load`).then((response) => {
      this.loading = false;
      this.$root.$data.store.hideLoader();
      this.entries = response.body.entries;
      this.vehicleLogs = response.body.vehicleLogs;
      this.carParks = response.body.carParks;
      this.citiPassToAdd.carpark = "";

      let entryDate = moment();
      let exitDate = moment().add(2, "hours");
      this.citiPassToAdd.valid_from = entryDate.format("YYYY-MM-DD HH:00:00");
      this.citiPassToAdd.valid_till = exitDate.format("YYYY-MM-DD HH:00:00");

      $ = jQuery.noConflict();

      $(document).ready(() => {
        $("#edit-entry-time")
          .datetimepicker({
            format: "yyyy-mm-dd hh:00",
            maxView: 3,
            minView: 1,
            minuteStep: 0,
          })
          .on("changeDate", (ev) => {
            this.citiPassToAdd.valid_from = moment(ev.date).format(
              "YYYY-MM-DD HH:00:00"
            );
            this.datepicker = "";
            $("#edit-entry-time").datetimepicker("hide");
          });

        $("#edit-exit-time")
          .datetimepicker({
            format: "yyyy-mm-dd hh:00",
            maxView: 3,
            minView: 1,
            minuteStep: 0,
          })
          .on("changeDate", (ev) => {
            this.citiPassToAdd.valid_till = moment(ev.date).format(
              "YYYY-MM-DD HH:00:00"
            );
            this.datepicker = "";
            $("#edit-exit-time").datetimepicker("hide");
          });
      });
    });
  },
  data() {
    return {
      loading: true,
      datepicker: "",
      datePickerTitle: "",
      isEditing: "",
      originalReg: "",
      vehicleLogs: [],
      csrf: csrfToken,
      citiPassToUpdate: {
        name: "",
        email: "",
        registration: "",
        valid_from: "",
        valid_till: "",
        carpark: "",
      },
      citiPassToAdd: {
        name: "",
        email: "",
        registration: "",
        valid_from: "",
        valid_till: "",
        carpark: "",
      },
      entries: [],
      carParks: [],
    };
  },
  computed: {},
  methods: {
    resendQR(entry) {
      this.$root.$data.store.showLoader();

      this.$http
        .post(
          `/citipass/resend-qr`,
          {
            citipass: entry.id,
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken },
          }
        )
        .then(
          (response) => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              `The QR Code for ${entry.registration} has been sent successfully to ${entry.email}.`,
              "bg-active-green",
              6000
            );
          },
          (err) => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
          }
        );
    },
    editCitiPass(entry) {
      this.isEditing = true;
      this.originalReg = entry.registration;

      $ = jQuery.noConflict();

      $("html, body").animate({ scrollTop: "0px" });

      entry.carpark = entry.carpark_id;
      this.citiPassToUpdate = Object.assign({}, entry);

      $(document).ready(() => {
        $("#edit-entry-time")
          .datetimepicker({
            format: "yyyy-mm-dd hh:00",
            maxView: 3,
            minView: 1,
            minuteStep: 0,
          })
          .on("changeDate", (ev) => {
            this.citiPassToUpdate.valid_from = moment(ev.date).format(
              "YYYY-MM-DD HH:00:00"
            );
            this.datepicker = "";
            $("#edit-entry-time").datetimepicker("hide");
          });

        $("#edit-exit-time")
          .datetimepicker({
            format: "yyyy-mm-dd hh:00",
            maxView: 3,
            minView: 1,
            minuteStep: 0,
          })
          .on("changeDate", (ev) => {
            this.citiPassToUpdate.valid_till = moment(ev.date).format(
              "YYYY-MM-DD HH:00:00"
            );
            this.datepicker = "";
            $("#edit-exit-time").datetimepicker("hide");
          });
      });
    },
    cancelCitiPassEdit() {
      $ = jQuery.noConflict();
      this.isEditing = false;
      this.originalReg = "";
      this.citiPassToUpdate = Object.assign(
        {},
        {
          name: "",
          registration: "",
          valid_from: "",
          valid_till: "",
          carpark: "",
        }
      );

      let entryDate = moment();
      let exitDate = moment().add(2, "hours");

      this.citiPassToAdd.valid_from = entryDate.format("YYYY-MM-DD HH:00:00");
      this.citiPassToAdd.valid_till = exitDate.format("YYYY-MM-DD HH:00:00");

      $ = jQuery.noConflict();

      $(document).ready(() => {
        $("#edit-entry-time")
          .datetimepicker({
            format: "yyyy-mm-dd hh:00",
            maxView: 3,
            minView: 1,
            minuteStep: 0,
          })
          .on("changeDate", (ev) => {
            this.citiPassToAdd.valid_from = moment(ev.date).format(
              "YYYY-MM-DD HH:00:00"
            );
            this.datepicker = "";
            $("#edit-entry-time").datetimepicker("hide");
          });

        $("#edit-exit-time")
          .datetimepicker({
            format: "yyyy-mm-dd hh:00",
            maxView: 3,
            minView: 1,
            minuteStep: 0,
          })
          .on("changeDate", (ev) => {
            this.citiPassToAdd.valid_till = moment(ev.date).format(
              "YYYY-MM-DD HH:00:00"
            );
            this.datepicker = "";
            $("#edit-exit-time").datetimepicker("hide");
          });
      });
    },
    toggleDatePicker(picker, title) {
      this.datepicker = picker;
      this.datePickerTitle = title;
      $ = jQuery.noConflict();
      $("#" + picker).datetimepicker("show");
    },
    addCitiPass() {
      this.$root.$data.store.showLoader();

      if (
        this.citiPassToAdd.name == "" ||
        this.citiPassToAdd.registration == "" ||
        this.citiPassToAdd.valid_from == "" ||
        this.citiPassToAdd.valid_till == "" ||
        this.citiPassToAdd.carpark == ""
      ) {
        this.$root.$data.store.hideLoader();
        this.$root.$data.store.showMessage(
          "Please fill in all fields.",
          "bg-turquoise",
          6000
        );
        return;
      }

      let entryDate = moment(this.citiPassToAdd.valid_from);
      let exitDate = moment(this.citiPassToAdd.valid_till);
      let currentDate = moment();

      if (entryDate.isBefore(currentDate)) {
        this.$root.$data.store.showMessage(
          "Entry date must be after the current hour.",
          "bg-red-500"
        );
        this.$root.$data.store.hideLoader();
        return false;
      }

      if (exitDate.isBefore(entryDate) || exitDate.isSame(entryDate)) {
        this.$root.$data.store.showMessage(
          "Exit date must be after the entry date.",
          "bg-red-500"
        );
        this.$root.$data.store.hideLoader();
        return false;
      }

      this.citiPassToAdd.registration = this.citiPassToAdd.registration.toUpperCase();

      this.$http
        .post(
          `/citipass/add`,
          {
            citipass: this.citiPassToAdd,
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken },
          }
        )
        .then(
          (response) => {
            this.entries = response.body.entries;
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              "Your entry has been added successfully",
              "bg-active-green",
              6000
            );
            this.citiPassToAdd = {
              name: "",
              email: "",
              registration: "",
              carpark: "",
            };
            this.citiPassToAdd.valid_from = entryDate.format(
              "YYYY-MM-DD HH:00:00"
            );
            this.citiPassToAdd.valid_till = exitDate.format(
              "YYYY-MM-DD HH:00:00"
            );
          },
          (err) => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
          }
        );
    },
    updateCitiPass() {
      this.$root.$data.store.showLoader();

      console.log(this.citiPassToUpdate);

      if (
        this.citiPassToUpdate.name == "" ||
        this.citiPassToUpdate.registration == "" ||
        this.citiPassToUpdate.valid_from == "" ||
        this.citiPassToUpdate.valid_till == "" ||
        this.citiPassToUpdate.carpark == ""
      ) {
        this.$root.$data.store.hideLoader();
        this.$root.$data.store.showMessage(
          "Please fill in all fields.",
          "bg-turquoise",
          6000
        );
        return;
      }

      let entryDate = moment(this.citiPassToUpdate.valid_from);
      let exitDate = moment(this.citiPassToUpdate.valid_till);
      let currentDate = moment();

      if (entryDate.isBefore(currentDate)) {
        this.$root.$data.store.showMessage(
          "Entry date must be after the current hour.",
          "bg-red-500"
        );
        this.$root.$data.store.hideLoader();
        return false;
      }

      if (exitDate.isBefore(entryDate) || exitDate.isSame(entryDate)) {
        this.$root.$data.store.showMessage(
          "Exit date must be after the entry date.",
          "bg-red-500"
        );
        this.$root.$data.store.hideLoader();
        return false;
      }

      this.citiPassToUpdate.registration = this.citiPassToUpdate.registration.toUpperCase();

      this.$http
        .post(
          `/citipass/update`,
          {
            citipass: this.citiPassToUpdate,
            original_reg: this.originalReg,
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken },
          }
        )
        .then(
          (response) => {
            this.entries = response.body.entries;
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              "Your entry has been updated successfully",
              "bg-active-green",
              6000
            );
            this.cancelCitiPassEdit();
          },
          (err) => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
          }
        );
    },
    removeCitiPass(citipass) {
      this.$root.$data.store.showLoader();

      this.$http
        .post(
          `/citipass/remove`,
          {
            citipass,
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken },
          }
        )
        .then(
          (response) => {
            this.entries = response.body.entries;
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              "Your entry has been removed successfully",
              "bg-active-green",
              6000
            );
          },
          (err) => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
          }
        );
    },
    momentDate(date, format) {
      return moment(date).format(format);
    },
    autoFill() {
      this.citiPassToAdd = {
        name: "George Baker",
        registration: "YP65OZE",
      };
    },
  },
};
</script>

<template>
  <div class="payment wrapper section">
    <h1 class="text-blue xs:text-lg micro:text-xl">Add a new payment method</h1>

    <TextInput :slim="true">
      <div class="input-upper">
        <div class="input-upper--left">Name On Card</div>
      </div>
      <input
        type="text"
        :class="['input--underlined dusk-payment-name', cardName !== null && cardName.length === 0 && formSubmitted ? 'input--invalid' : '']"
        value
        v-model="cardName"
        @keyup.enter="addPayment"
      />
    </TextInput>

    <TextInput :slim="true">
      <div class="input-upper">
        <div>Card Details</div>
      </div>
      <div id="stripe-elements"></div>
    </TextInput>

    <TextInput :slim="true">
      <div class="input-upper">
        <div class="input-upper--left">Billing postcode</div>
      </div>
      <input
        type="text"
        :class="['input--underlined dusk-payment-postcode', cardPostcode !== null && cardPostcode.length === 0 && formSubmitted ? 'input--invalid' : '', 'uppercase']"
        value
        v-model="cardPostcode"
        @keyup.enter="addPayment"
      />
    </TextInput>

    <ButtonFixed :loading="loading" :active="nextButtonActive" :clickEvent="addPayment">Continue</ButtonFixed>
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import CreditCardType from "credit-card-type";
import AutoFill from "../../store/autofill.js";

const stripe = Stripe(stripePublicKey);
const elements = stripe.elements();

var style = {
  base: {
    color: "#3042A6",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#5BA3DC"
    },
    letterSpacing: "-0.5px"
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};
let card = elements.create("card", { hidePostalCode: true, style: style });

export default {
  mounted() {
    if (this.$root.$data.store.autoFill) this.autoFill();

    card.mount("#stripe-elements");
  },
  props: ["registration"],
  data() {
    return {
      cardType: null,
      element: null,
      acceptedCardTypes: ["visa", "master-card", "american-express"],
      formSubmitted: false,
      loading: false
    };
  },
  computed: {
    nextButtonActive: {
      get() {
        if (this.cardName === "" || this.cardPostcode === "") {
          return false;
        }

        return true;
      }
    },
    loggedIn: {
      get() {
        return this.$root.$data.store.state.loggedIn;
      },
      set(value) {
        this.$root.$data.store.setState("loggedIn", value);
      }
    },
    mode() {
      return this.$root.$data.store.mode;
    },
    cardName: {
      get() {
        return this.$root.$data.store.payment.cardName;
      },
      set(value) {
        this.$root.$data.store.setField("payment", "cardName", value);
      }
    },
    customer: {
      get() {
        if (
          this.$root.$data.store.mode != "account" &&
          this.$root.$data.store.mode != "season-ticket"
        )
          return null;
        return this.$root.$data.store.customer;
      },
      set(value) {
        this.$root.$data.store.setObject("customer", value);
      }
    },
    cards: {
      get() {
        if (
          this.$root.$data.store.mode != "account" &&
          this.$root.$data.store.mode != "season-ticket"
        )
          return null;
        return this.$root.$data.store.customer.cards;
      },
      set(value) {
        this.$root.$data.store.setField("customer", "cards", value);
      }
    },
    addPaymentRedirect: {
      get() {
        return this.$root.$data.store.state.addPaymentRedirect;
      },
      set(value) {
        this.$root.$data.store.setField("state", "addPaymentRedirect", value);
      }
    },
    ticketType() {
      return this.$root.$data.store.state.ticketType;
    },
    cardPostcode: {
      get() {
        return this.$root.$data.store.payment.cardPostcode;
      },
      set(value) {
        value = value.toUpperCase();
        this.$root.$data.store.setField("payment", "cardPostcode", value);
      }
    },
    token: {
      get() {
        return this.$root.$data.store.payment.cardToken;
      },
      set(value) {
        this.$root.$data.store.setField("payment", "cardToken", value);
      }
    }
  },
  watch: {
    cardNumber: function(cardNo) {
      this.detectCardType(cardNo);
    }
  },
  components: {
    SectionTitle,
    TextInput,
    ButtonFixed
  },
  methods: {
    detectCardType(cardNo) {
      let creditCard = CreditCardType(cardNo);

      if (
        creditCard.length &&
        this.acceptedCardTypes.includes(creditCard[0].type) &&
        cardNo !== ""
      ) {
        this.cardType = creditCard[0].type;
      } else {
        this.cardType = null;
      }
    },
    goToNextSection() {
      if (this.mode == "account") {
        this.$router.push({ name: "account" });
      } else if (this.mode === "ticket") {
        this.$parent.$data.view = "payment";
      } else {
        if (this.ticketType == "prebook") {
          this.$router.push({ name: "payment" });
        } else {
          if (this.addPaymentRedirect === "registration") {
            this.$router.push({ name: "registration" });
          } else {
            this.$router.push({ name: "payment" });
          }
        }
      }
    },
    addPayment() {
      this.formSubmitted = true;
      this.loading = true;

      if (this.cardName === "" || this.cardPostcode === "") {
        this.$root.$data.store.showMessage("Please fill in all fields.");
        this.loading = false;
        return;
      }

      stripe
        .createPaymentMethod("card", card, {
          billing_details: {
            name: this.cardName,
            address: {
              postal_code: this.cardPostcode
            }
          }
        })
        .then(result => {
          if (result.error) {
            this.loading = false;
            this.$root.$data.store.showMessage(result.error.message);
          } else {
            if (this.mode == "account" || this.mode == "season-ticket") {
              this.$http
                .post(
                  "/card/add",
                  {
                    customer: this.customer.id,
                    card: result.paymentMethod.id
                  },
                  {
                    headers: { "X-CSRF-TOKEN": csrfToken }
                  }
                )
                .then(
                  response => {
                    this.cardName = "";
                    this.cards = response.body.cards;
                    this.$root.$data.store.setField(
                      "customer",
                      "stripe",
                      response.body.stripe
                    );
                    this.goToNextSection();
                  },
                  err => {
                    this.$root.$data.store.showMessage(err.body);
                    this.loading = false;
                  }
                );
            } else {
              this.token = result.paymentMethod;
              this.$root.$data.store.addCard(result.paymentMethod);
              this.$root.$data.store.addToken(result.paymentMethod.id);

              this.cardName = "";
              this.cardPostcode = "";

              this.goToNextSection();
            }
          }
        });
    },
    autoFill() {
      this.$root.$data.store.setObject("payment", AutoFill.card);
    }
  }
};
</script>

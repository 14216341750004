var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isSubmittingWithUrlParam
    ? _c(
        "div",
        { staticClass: "registration section" },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "center" },
                [
                  _c(
                    "h1",
                    { staticClass: "text-blue xs:text-lg micro:text-xl" },
                    [_vm._v("Vehicle Registration")]
                  ),
                  _vm._v(" "),
                  _c("TextInput", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.registration,
                          expression: "registration"
                        }
                      ],
                      staticClass: "reg-number-input",
                      attrs: { value: "", autofocus: "", tabindex: "1" },
                      domProps: { value: _vm.registration },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.registration = $event.target.value
                        }
                      }
                    })
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "ButtonFixed",
            {
              attrs: { active: _vm.isRegistrationValid, clickEvent: _vm.submit }
            },
            [_vm._v("\n    Continue\n  ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1d747693", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div class="login wrapper section">
    <h1
      class="text-blue xs:text-lg micro:text-xl"
      v-if="!isAdmin"
    >{{ $root.$data.store.mode === 'create' ? 'You already have an account' : 'Enter your password' }}</h1>
    <h1 v-else>Administration</h1>

    <form @submit.prevent="checkLogin" v-if="!isAdmin">
      <TextInput>
        <div class="input-upper">
          <div class="input-upper--left">Password</div>
        </div>
        <input
          type="password"
          :class="['input--underlined dusk-password', password !== null && password.length === 0 && formSubmitted ? 'input--invalid' : '']"
          value
          v-model="password"
        />
      </TextInput>
    </form>

    <SimpleButton
      v-if="!isAdmin"
      class="btn btn--grey btn--block btn--reset center-align"
      :clickEvent="reset"
    >Reset Password</SimpleButton>
    <ButtonFixed :loading="loading" :active="nextButtonActive" :clickEvent="checkLogin">Continue</ButtonFixed>
    <transition name="fade-in" mode="out-in">
      <div class="users" v-if="isAdmin === true">
        <p class="text-center">Login as another user or press continue</p>
        <input
          type="text"
          class="input--underlined text-center"
          placeholder="Search by last name, email or user id"
          value
          v-model="userSearch"
          @keyup="searchUsers"
        />
        <div>
          <div class="user" v-for="user in users" :key="user.id">
            {{ user.first_name }} {{ user.last_name}} {{ user.email }}
            <button
              class="btn"
              type="button"
              @click.prevent="loginInAs(user.id)"
            >Select</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import SimpleButton from "../objects/Button.vue";
import AutoFill from "../../store/autofill.js";

export default {
  created() {
    if (this.$root.$data.store.autoFill) this.autoFill();

    if (this.loggedIn && !this.isAdmin) {
      this.$router.go(-1);
    }

    if (
      this.$root.$data.store.carParks.length === 0 &&
      this.$root.$data.store.mode === "create"
    ) {
      this.$router.push({ name: "location" });
    }
  },
  data() {
    return {
      formSubmitted: false,
      loading: false,
      users: [],
      userSearch: "",
      searchTimeout: null
    };
  },
  computed: {
    nextButtonActive: {
      get() {
        if (!this.password || this.password === "") {
          return false;
        }

        return true;
      }
    },
    password: {
      get() {
        return this.$root.$data.store.customer.password;
      },
      set(value) {
        this.$root.$data.store.setField("customer", "password", value);
      }
    },
    loggedIn: {
      get() {
        return this.$root.$data.store.state.loggedIn;
      },
      set(value) {
        this.$root.$data.store.setState("loggedIn", value);
      }
    },
    isAdmin: {
      get() {
        return this.$root.$data.store.state.isAdmin;
      },
      set(value) {
        this.$root.$data.store.setState("isAdmin", value);
      }
    },
    ticketType() {
      return this.$root.$data.store.state.ticketType;
    }
  },
  components: {
    SectionTitle,
    TextInput,
    SimpleButton,
    ButtonFixed
  },
  methods: {
    checkLogin() {
      if (this.loading) return;

      if (this.loggedIn && this.isAdmin) {
        this.redirect();
        return;
      }

      this.formSubmitted = true;
      this.loading = true;

      if (this.password && this.password.length === 0) {
        this.loading = false;
        this.$root.$data.store.showMessage(
          "Please enter your password",
          "bg-red-500"
        );
        return;
      }

      this.$http
        .post(
          "/login/ajax",
          {
            email: this.$root.$data.store.customer.email,
            password: this.password
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.$root.$data.store.setState("loggedIn", true);
            this.$root.$data.store.setObject(
              "customer",
              Object.assign(this.$root.$data.store.customer, response.body)
            );
            this.isAdmin = response.body.isAdmin;

            if (!this.isAdmin) {
              this.redirect();
            } else {
              this.loading = false;
            }
          },
          err => {
            console.log(err);
            this.loading = false;

            this.$root.$data.store.showMessage(err.body.error, "bg-red-500");
          }
        );
    },
    reset() {
      if (this.loading) return;

      this.loading = true;

      this.$http
        .post(
          "/password/ajax/reset",
          {
            email: this.$root.$data.store.customer.email
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.$router.push({ name: "reset" });
          },
          err => {
            this.loading = false;
            this.$root.$data.store.showMessage(err.body.error);
          }
        );
    },
    autoFill() {
      this.$root.$data.store.setField(
        "customer",
        "password",
        AutoFill.customer.password
      );
    },
    redirect() {
      switch (this.$root.$data.store.mode) {
        case "create":
          if (this.ticketType === "prebook") {
            this.$router.push({ name: "payment" });
          } else {
            this.$router.push({ name: "registration" });
          }
          break;
        case "login":
          window.location.href = "/my-account";
          break;
      }
    },
    searchUsers() {
      clearTimeout(this.searchTimeout);

      if (this.userSearch.length < 2) return;

      this.searchTimeout = setTimeout(() => {
        this.$http
          .post(
            "/user-search",
            {
              query: this.userSearch
            },
            {
              headers: { "X-CSRF-TOKEN": csrfToken }
            }
          )
          .then(
            response => {
              console.log(response.body.users);
              this.users = response.body.users;
            },
            err => {
              this.$root.$data.store.showMessage(err.body.error);
              console.log(err);
            }
          );
      }, 1000);
    },
    loginInAs(userId) {
      this.$http
        .post(
          "/login-user",
          {
            id: userId
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.$root.$data.store.setState("loggedIn", true);
            this.$root.$data.store.setObject(
              "customer",
              Object.assign(this.$root.$data.store.customer, response.body)
            );
            this.isAdmin = false;

            if (!this.isAdmin) {
              this.redirect();
            } else {
              this.loading = false;
            }
          },
          err => {
            console.log(err);
            this.loading = false;
            this.$root.$data.store.showMessage(err.body.error);
          }
        );
    }
  }
};
</script>

<template>
  <div class="section">
    <h1 class="text-blue xs:text-lg micro:text-xl">Choose a Season Ticket</h1>
    <ButtonList>
      <ButtonListItem
        :key="ticket.id"
        :gridClass="'grid--item' + ' dusk-season-' + ticket.id"
        v-for="ticket in tickets"
        :clickEvent="select"
        :clickEventParams="{ticket}"
      >
        <span>{{ ticket.title }}</span>
        <p
          style="font-weight: bold; font-size: 18px; margin-top: 10px; margin-bottom: 0px;"
        >£{{ ticket.price }}</p>
      </ButtonListItem>
    </ButtonList>
  </div>
</template>

<script>
import jQuery from "jquery";
import moment from "moment";
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import AutoFill from "../../store/autofill.js";
import Vue from "vue";
import ButtonList from "../objects/ButtonList.vue";
import ButtonListItem from "../objects/ButtonListItem.vue";

export default {
  components: {
    SectionTitle,
    TextInput,
    ButtonList,
    ButtonListItem,
    ButtonFixed
  },
  mounted() {
    this.$root.$data.store.setObject("progress", 4);
    this.$root.$data.store.setField("seasonTicket", "promotion", "");
  },
  data() {
    return {
      tickets: null,
      formSubmitted: false
    };
  },
  computed: {
    nextButtonActive: {
      get() {
        return false;
      }
    },
    driversCount() {
      return this.$root.$data.store.ticket.drivers.length;
    },
    isLoading() {
      if (this.priceCalculating) return true;
    },
    initialPrice: {
      get() {
        return this.$root.$data.store.seasonTicket.initialPrice;
      },
      set(value) {
        this.$root.$data.store.setField("seasonTicket", "initialPrice", value);
      }
    },
    seasonTicket: {
      get() {
        return this.$root.$data.store.seasonTicket;
      },
      set(value) {
        this.$root.$data.store.setObject("seasonTicket", value);
      }
    },
    codeApplied: {
      get() {
        return this.$root.$data.store.promotion.codeApplied;
      },
      set(value) {
        this.$root.$data.store.setField("promotion", "codeApplied", value);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    Vue.http.get(`/carpark/${to.params.carpark}/seasons`).then(
      response => {
        next(vm => vm.setTickets(response.body));
      },
      response => {
        console.log("Could not load season tickets.");
      }
    );
  },
  methods: {
    setTickets(tickets) {
      this.tickets = tickets;
    },
    select({ ticket }) {
      this.codeApplied = false;
      ticket.initialPrice = ticket.price;
      ticket.singlePrice = ticket.price;
      ticket.price = ticket.price;
      this.seasonTicket = ticket;
      this.$router.push({ name: "login" });
    }
  }
};
</script>

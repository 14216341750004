<template>
  <div v-if="!isSubmittingWithUrlParam" class="section">
    <div :class="['datepicker-title', datepicker !== '' ? 'open' : '']">
      <h1 @click.prevent="hideDatePicker">{{ datePickerTitle }}</h1>
    </div>
    <h1 class="text-blue xs:text-lg micro:text-xl">Choose a Start Date</h1>
    <div class="justify-between sm:flex sm:flex-row xs:flex-col">
      <button
        type="button"
        :disabled="!isNowAfterEarliestStartDate"
        :class="[
          'dusk-startdate-today sm:w-1/2 xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue xs:mr-0 sm:mr-2 xs:mb-1 sm:mb-0',
          !isNowAfterEarliestStartDate ? 'opacity-50' : 'hover:opacity-90',
        ]"
        @click.prevent="selectToday"
      >
        Today
      </button>
      <button
        type="button"
        class="block p-3 font-bold text-white rounded-sm dusk-startdate-future sm:w-1/2 xs:w-full tracking-fat bg-blue hover:opacity-90"
        @click.prevent="showDatePicker('start-date', 'Start Date')"
      >
        Future Date
      </button>
    </div>
    <p class="mt-10 text-center" v-if="!isNowAfterEarliestStartDate">
      Booking Available From:
      <strong>{{ earliestStartDate.format('Do MMMM YYYY') }}</strong
      >. Please select the future date option.
    </p>
    <input readonly type="hidden" id="start-date" />
  </div>
</template>

<script>
import ButtonFixed from '../objects/ButtonFixed.vue';
import ButtonList from '../objects/ButtonList.vue';
import ButtonListItem from '../objects/ButtonListItem.vue';
import SectionTitle from '../objects/SectionTitle.vue';
import jQuery from 'jquery';
import moment from 'moment-timezone';

export default {
  components: {
    ButtonFixed,
    ButtonList,
    ButtonListItem,
    SectionTitle,
  },
  data() {
    return {
      datepicker: '',
      datePickerTitle: '',
      isSubmittingWithUrlParam: true,
    };
  },
  computed: {
    startDate: {
      get() {
        return this.$root.$data.store.state.startDate;
      },
      set(value) {
        this.$root.$data.store.setField('state', 'startDate', value);
      },
    },
    carPark() {
      return this.$root.$data.store.ticket.carPark;
    },
    earliestStartDate() {
      return this.carPark.not_before !== null
        ? moment(this.carPark.not_before)
        : moment().startOf('hour');
    },
    isNowAfterEarliestStartDate() {
      return moment().isAfter(this.earliestStartDate);
    },
    isStartDateValid() {
      return (
        this.startDate !== null &&
        moment(this.startDate).isSameOrAfter(this.earliestStartDate)
      );
    },
  },
  created() {
    this.submitWithUrlParam();
  },
  mounted() {
    this.setupDatePicker();
  },
  methods: {
    submit() {
      this.$router.push({
        name: 'seasons',
        params: { carpark: this.carPark.id },
      });
    },
    submitWithUrlParam() {
      if (this.startDate === null) {
        const urlParams = new URLSearchParams(window.location.search);

        if (typeof urlParams.get('start_date') === 'string') {
          const startDate = moment.tz(
            urlParams.get('start_date'),
            'Europe/London'
          );

          if (startDate.isValid()) {
            this.startDate = startDate
              .startOf('hour')
              .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
          }
        }

        if (this.isStartDateValid) {
          this.submit();
          return;
        }
      }

      this.isSubmittingWithUrlParam = false;
    },
    selectToday() {
      this.startDate = moment()
        .startOf('hour')
        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      this.submit();
    },
    setupDatePicker() {
      $ = jQuery.noConflict();

      $('#start-date')
        .datetimepicker({
          format: 'yyyy-mm-dd hh:00',
          maxView: 3,
          minView: 2,
          minuteStep: 0,
          startDate: this.earliestStartDate.toDate(),
          initialDate: moment().startOf('hour').toDate(),
        })
        .on('changeDate', (event) => {
          this.startDate = moment(event.date)
            .startOf('hour')
            .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
          this.datepicker = '';
          $('#start-date').datetimepicker('hide');
          $('#start-date').datetimepicker(
            'setDate',
            $('#start-date').datetimepicker('getDate')
          );

          setTimeout(() => {
            this.submit();
          }, 200);
        });
    },
    showDatePicker(picker, title) {
      $ = jQuery.noConflict();
      this.datepicker = '#' + picker;
      this.datePickerTitle = title;
      $(this.datepicker).datetimepicker('show');
    },
    hideDatePicker() {
      $ = jQuery.noConflict();
      $(this.datepicker).datetimepicker('hide');
      this.datepicker = '';
    },
  },
};
</script>

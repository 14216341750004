var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section" },
    [
      _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
        _vm._v("Choose a Season Ticket")
      ]),
      _vm._v(" "),
      _c(
        "ButtonList",
        _vm._l(_vm.tickets, function(ticket) {
          return _c(
            "ButtonListItem",
            {
              key: ticket.id,
              attrs: {
                gridClass: "grid--item" + " dusk-season-" + ticket.id,
                clickEvent: _vm.select,
                clickEventParams: { ticket: ticket }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(ticket.title))]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "18px",
                    "margin-top": "10px",
                    "margin-bottom": "0px"
                  }
                },
                [_vm._v("£" + _vm._s(ticket.price))]
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-77e4cb3d", { render: render, staticRenderFns: staticRenderFns })
  }
}
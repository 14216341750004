<template>
  <div class="mt-header w-full dusk-ticket" v-if="ticket && !loading">
    <transition mode="out-in" name="fade-in">
      <div v-if="view === 'ticket'">
        <div :class="['datepicker-title', datepicker !== '' ? 'open' : '']">
          <h1>{{ datePickerTitle }}</h1>
        </div>
        <div class="bg-blue w-full pb-50">
          <div
            class="max-w-5xl sm:w-2/3 xs:w-auto xs:mx-6 sm:mx-auto flex sm:flex-row xs:flex-col justify-between items-center bg-blue xs:mb-16 sm:mb-20 py-8"
          >
            <div class="flex flex-col xs:mb-5 sm:mb-0">
              <a
                class="text-sm text-white font-bold block flex items-center mb-2"
                href="/my-account"
              >
                <img src="/img/small-chevron-white.svg" class="w-2 h-auto mr-1" alt />
                <span class="text-meta mr-1">Back to my account</span>
              </a>
              <h2
                class="font-bold text-white font-normal text-lg m-0 xs:text-center sm:text-left"
              >PRE-{{ ticket.id }}</h2>
            </div>
            <div
              v-if="ticket.car_park.uses_zatpark != 1 && ticket.car_park.uses_license != 1"
              class="flex justify-between items-center"
            >
              <a :href="'/ticket/passbook/' + ticket.id" class="bg-transparent block p-0">
                <img src="/img/apple-wallet.svg" class="h-wallet block mx-auto" />
              </a>
            </div>
          </div>
        </div>

        <div class="flex justify-between -mt-overlap">
          <div
            class="max-w-5xl micro:w-2/3 xs:w-auto mini:mx-6 xs:mx-0 bg-white sm:flex-row xs:flex xs:flex-col-reverse shadow-sm sm:p-0 xs:p-8 micro:mx-auto rounded-sm"
          >
            <div class="flex flex-col justify-between xs:w-full sm:w-4/12 rounded-sm relative">
              <div
                class="flex items-center border border-solid border-gray-300 flex-grow rounded-sm"
              >
                <template v-if="ticket.barcode">
                  <img
                    class="w-full h-auto block" 
                    :src="`/qr-code/${ticket.barcode}`"
                    alt
                  />
                </template>
              </div>
              <div class="relative">
                <img
                  v-if="active"
                  class="pointer-events-none absolute top-0 left-0 w-3 opacity-25 ml-2 mt-2"
                  src="/img/pencil-black.png"
                  alt
                />
                <input
                  :disabled="!active"
                  :class="[!registrationValid() ? 'bg-red-300 border-red-400 text-red-800 border-solid border-1 text-center font-bold text-md uppercase p-4 text-black -mt-1' : 'bg-yolk border-yolk-dark border-solid border-1 text-center font-bold text-md uppercase p-4 text-black -mt-1']"
                  v-model="registration"
                />
              </div>
            </div>
            <div class="xs:w-full sm:w-8/12 text-sm sm:p-10 xs:p-0 xs:pb-10 sm:p-4">
              <div class="flex items-center mb-1">
                <div
                  :class="['p-2 text-white font-bold uppercase text-tiny mr-2 rounded-sm inline', status == 'Active' ? 'bg-active-green' : 'bg-red-500']"
                >{{ status }}</div>
                <div class="text-xs">Created {{ momentDate(ticket.created_at, 'Do MMMM YYYY') }}</div>
              </div>
              <h1
                class="mt-0 mb-1 m-0 xs:text-lg tiny:text-welcome text-left"
              >{{ ticket.car_park.name }}</h1>
              <div class="flex mb-2 items-center">
                <div class="mr-1">
                  <img src="/img/pin-turquoise.svg" />
                </div>
                <p
                  class="leading-tighter text-xs m-0 block"
                >{{ ticket.car_park.address_1 }}, {{ ticket.car_park.address_2 }}, {{ ticket.car_park.postcode }}</p>
              </div>
              <p
                v-if="active"
                class="rounded-sm p-3 border-1 border-gray-300 border-solid mt-4 text-meta font-bold text-blue leading-normal"
              >You can edit details by clicking on car park entry, exit and registration number.</p>
              <div class="flex flex-col mt-3">
                <div class="flex">
                  <div class="flex flex-col mr-1">
                    <img src="/img/time-circle.svg" width="12" />
                    <div
                      style="background-image: url('/img/time-line.svg');"
                      class="bg-top bg-repeat-y flex-grow"
                    ></div>
                  </div>
                  <button
                    type="button"
                    :disabled="!active"
                    :class="['bg-white focus:outline-none text-left text-blue tracking-fat -mt-nudge mb-1', !active ? 'pointer-events-none' : '']"
                    id="edit-entry-time"
                    @click.prevent="toggleDatePicker('edit-entry-time', 'Entry Time')"
                  >
                    <p class="mb-2 m-0 font-bold text-xs">
                      Car park entry
                      <img v-if="active" class="w-2 ml-1" src="/img/pencil.png" alt />
                    </p>
                    <p
                      v-if="initialEntryDate != ticket.valid_from"
                      class="mb-2 text-xs"
                    >{{ momentDate(ticket.valid_from, 'DD/MM/YYYY - HH:mm') }}</p>
                    <p
                      :class="['mb-2 text-xs', initialEntryDate != ticket.valid_from ? 'line-through text-gray-400' : '']"
                    >{{ momentDate(initialEntryDate, 'DD/MM/YYYY - HH:mm') }}</p>
                  </button>
                </div>

                <div class="flex">
                  <div class="flex flex-col mr-1">
                    <img src="/img/time-circle.svg" width="12" />
                  </div>

                  <button
                    type="button"
                    :disabled="!active"
                    :class="['bg-white focus:outline-none text-left text-blue tracking-fat -mt-nudge mb-1', !active ? 'pointer-events-none' : '']"
                    id="edit-exit-time"
                    @click.prevent="toggleDatePicker('edit-exit-time', 'Exit Time')"
                  >
                    <p class="mb-2 m-0 font-bold text-xs">
                      Car park exit
                      <img v-if="active" class="w-2 ml-1" src="/img/pencil.png" alt />
                    </p>
                    <p
                      v-if="initialExitDate != ticket.valid_till"
                      class="mb-2 text-xs"
                    >{{ momentDate(ticket.valid_till, 'DD/MM/YYYY - HH:mm') }}</p>
                    <p
                      :class="['mb-2 text-xs', initialExitDate != ticket.valid_till ? 'line-through text-gray-400' : '']"
                    >{{ momentDate(initialExitDate, 'DD/MM/YYYY - HH:mm') }}</p>
                  </button>
                </div>
                <div class="ml-2 flex flex-col"></div>
              </div>

              <div v-if="ticket.promotion || (pmsPermit && pmsPermit.data.promotion)" class="leading-tight text-xs block flex">
                <div class="mr-2 mt-nudge">
                  <img src="/img/voucher.svg" width="14" />
                </div>
                <div class="leading-tight text-xs m-0 block -mt-nudge">
                  <p class="mb-2 m-0 font-bold text-xs">Promotion</p>
                  <p class="m-0 p-0 text-meta">
                    {{ pmsPermit ? pmsPermit.data.promotion.code : ticket.promotion.code.toUpperCase() }}
                    <span
                      v-if="!pmsPermit"
                      class="text-turquoise text-meta"
                    >({{ (100 - (ticket.promotion.discount * 100)).toFixed(0) }}% discount)</span>
                  </p>
                </div>
              </div>

              <div class="border-b-1 border-t-0 border-solid border-gray-200 my-6"></div>

              <div class="flex xs:flex-col sm:flex-row justify-between">
                <div class="flex items-center xs:justify-center tiny:justify-start">
                  <template v-if="ticket.promotion && ticket.promotion.discount == 0">
                    <div class="ml-1 text-xs leading-normal font-bold">Free Ticket</div>
                  </template>
                  <template v-else>
                    <div class="text-xs leading-normal">Total</div>
                    <div class="ml-1 text-xs leading-normal font-bold">£{{ ticket.price }}</div>
                    <div
                      v-if="priceDiff != 0"
                      class="ml-1 text-xs text-gray-500"
                    >({{ priceDiff > 0 ? '+' : '-'}}£{{ priceDiff > 0 ? (priceDiff).toFixed(2) : (-priceDiff).toFixed(2) }})</div>
                  </template>
                </div>
                <div
                  class="flex xs:flex-col tiny:flex-row justify-between items-center xs:mt-3 tiny:mt-0"
                >
                  <transition mode="out-in" name="fade-in">
                    <button
                      @click.prevent="confirmUpdate"
                      v-if="saveButtonActive"
                      class="dusk-ticket-save-changes xs:mb-2 tiny:mb-0 xs:w-full tiny:w-auto xs:mr-0 tiny:mr-1 bg-turquoise hover:opacity-90 text-white px-3 py-2 rounded-sm text-meta font-bold tracking-fat block"
                    >Save Changes</button>
                  </transition>
                  <button
                    @click.prevent="confirmCancel"
                    v-if="active"
                    class="dusk-confirm-cancel-ticket-button xs:mt-3 sm:mt-0 xs:w-full tiny:w-auto bg-red-600 hover:bg-red-500 text-white px-3 py-2 rounded-sm text-meta font-bold tracking-fat"
                  >Cancel Ticket</button>
                </div>
              </div>

              <!-- <h3 class="font-bold mt-4 mb-2">Payment method</h3>
          <div class="w-6/12 flex justify-between items-center mb-2 bg-gray-400 p-2 rounded-sm">
            <div class="flex items-center">
              <img
                :src="'/img/' + seasonTicket.card.brand.replace('_', '-').toLowerCase() + '.svg'"
                alt
                class="w-10 mr-2"
              />
              <div class="font-bold text-xs">
                <span class="capitalize">{{ seasonTicket.card.brand }}</span>
                ending in
                {{ seasonTicket.card.last4 }}
              </div>
            </div>
            <button
              @click="goToPayment()"
              :class="[loading ? 'opacity-50' : '', 'leading-normal rounded-sm px-2 py-1 bg-blue text-white text-tiny font-bold uppercase hover:opacity-90']"
            >Change</button>
              </div>-->
            </div>
          </div>
        </div>
        <div
          class="max-w-5xl xs:w-full tiny:w-2/3 mx-auto my-10 sm:flex xs:flex-0 justify-between xs:px-6 sm:px-16 py-2 text-xs"
        >
          <div class="sm:w-1/2 xs:w-auto sm:mr-6 xs:mr-0 sm:mb-0 xs:mb-6">
            <h2 class="text-lg">Entering the car park</h2>

            <ul class="list-outside pl-3 text-base leading-normal">
              <li
                class="mb-1 text-xs"
              >Our car parks are equipped with ANPR equipment which should recognise your number plate.</li>
              <li
                class="mb-1 text-xs"
              >In case this doesn’t work (or you come in a different vehicle or your car is very muddy!) please scan the QR code at the entry barrier.</li>
              <li
                class="text-xs"
              >There’s no need to print anything - our barriers are able to read QR codes.</li>
            </ul>
          </div>
          <div class="sm:w-1/2 xs:w-auto sm:ml-6 xs:ml-0">
            <h2 class="text-lg border-b-2 border-blue border-solid pb-3">VAT Receipt</h2>
            <!-- <ul>
                <li
                  v-for="atts in receipt"
                  :key="atts.label"
                  class="flex justify-between mb-3 sm:text-xs xs:text-xs"
                >
                  <span class="font-bold text-xs">{{ atts.label }}</span>
                  <span class="text-xs">{{ atts.value }}</span>
                </li>
            </ul>-->
            <div class="flex items-center justify-between">
              <div class="mr-4 font-bold xs:text-meta tiny:text-meta">£{{ ticket.price }}</div>
              <div
                class="xs:text-meta tiny:text-meta"
              >{{ momentDate(ticket.valid_from, 'Do MMMM YYYY') }}</div>
              <a
                class="bg-blue text-tiny text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start ml-2 hover:opacity-75"
                :href="ticket.invoice_url"
                target="_blank"
              >Download</a>
            </div>
            <!-- <p
              class="vat-receipt--content"
            >VAT Registration: GB170156192, Registered Address: CitiPark, Town Centre House, The Merrion Centre, Leeds, LS2 8LY</p>-->
          </div>
        </div>
      </div>
    </transition>
    <transition mode="out-in" name="fade-in">
      <template v-if="view === 'ticket' && confirming === true">
        <div class="fixed top-0 left-0 w-full h-full z-1 fixed flex justify-center items-center">
          <div
            @click.prevent="cancelConfirmation"
            class="fixed top-0 left-0 w-full h-full bg-white opacity-75"
          ></div>
          <div
            class="scale-up-center-slow border-2 border-solid border-dull-blue elative z-10 bg-blue rounded-sm text-white shadow-lg"
            style="width: 355px"
          >
            <h2
              class="m-0 p-0 text-white p-3 border-b border-solid border-dull-blue text-center"
            >{{ confirmationType === 'update' ? "Ticket Update" : 'Ticket Cancellation' }}</h2>
            <template
              v-if="(initialEntryDate != ticket.valid_from || initialExitDate != ticket.valid_till) != 0 && confirmationType === 'update'"
            >
              <div class="flex justify-between m-4">
                <div class="flex flex-col">
                  <p class="mb-2 m-0 p-0 text-white text-meta font-bold">Car park entry</p>
                  <p
                    class="m-0 p-0 text-white text-meta mb-2"
                  >{{ momentDate(ticket.valid_from, 'DD/MM/YYYY - HH:mm') }}</p>
                  <p
                    v-if="initialEntryDate != ticket.valid_from"
                    :class="['m-0 p-0  text-meta', initialEntryDate != ticket.valid_from ? 'line-through text-dullish-blue' : '']"
                  >{{ momentDate(initialEntryDate, 'DD/MM/YYYY - HH:mm') }}</p>
                </div>
                <div class="flex flex-col">
                  <p class="mb-2 m-0 p-0 text-white text-meta font-bold">Car park exit</p>
                  <p
                    class="m-0 p-0 text-white text-meta mb-2"
                  >{{ momentDate(ticket.valid_till, 'DD/MM/YYYY - HH:mm') }}</p>
                  <p
                    v-if="initialExitDate != ticket.valid_till"
                    :class="['m-0 p-0 text-meta', initialExitDate != ticket.valid_till ? 'line-through text-dullish-blue' : '']"
                  >{{ momentDate(initialExitDate, 'DD/MM/YYYY - HH:mm') }}</p>
                </div>
              </div>
            </template>
            <template v-if="initialRegistration != registration && confirmationType === 'update'">
              <div class="flex justify-between items-center m-4">
                <div
                  class="opacity-50 bg-yolk border-solid p-plate text-black rounded-sm border-black border-1 text-small-btn font-bold uppercase"
                >{{ initialRegistration }}</div>
                <img src="/img/right-arrow.svg" class="w-3 h-auto opacity-50" />
                <div
                  class="bg-yolk border-solid p-plate rounded-sm text-black border-black border-1 text-small-btn font-bold uppercase"
                >{{ registration }}</div>
              </div>
            </template>
            <template v-if="priceDiff != 0 && confirmationType === 'update'">
              <div
                class="flex justify-between items-center px-4 py-3 bg-dark-blue border-t-1 border-b-1 border-solid border-dull-blue"
              >
                <div class="text-xs text-white font-bold">{{ duration }}</div>
                <div class="flex justify-between">
                  <div class="ml-2 font-bold text-xs text-white leading-normal">£{{ ticket.price }}</div>
                  <div
                    v-if="priceDiff != 0"
                    class="ml-1 text-xs text-dullish-blue leading-normal"
                  >({{ priceDiff > 0 ? '+' : '-'}}£{{ priceDiff > 0 ? (priceDiff).toFixed(2) : (-priceDiff).toFixed(2) }})</div>
                </div>
              </div>
            </template>
            <div v-if="confirmationType == 'cancel' && ticket.price != 0" class="m-4">
              <p class="text-xs leading-normal text-center">
                You will be refunded
                <span class="font-bold">£{{ existingPrice }}</span>.
              </p>
            </div>
            <div class="m-4">
              <button
                @click.prevent="confirmButtonEvent"
                class="dusk-ticket-confirm-button w-full font-bold text-small-btn tracking-fat mb-1 uppercase bg-turquoise hover:opacity-90 p-2-1/2 block text-white rounded-sm"
              >{{ confirmButtonText }}</button>
              <button
                @click.prevent="cancelConfirmation"
                class="w-full font-bold text-small-btn tracking-fat mb-1 uppercase p-2-1/2 bg-red-600 hover:bg-red-700 block text-white rounded-sm"
              >Nevermind</button>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="view === 'add-payment'">
        <AddPayment></AddPayment>
      </template>
      <template v-else-if="view === 'payment'">
        <Payment></Payment>
      </template>
    </transition>
  </div>
</template>

<script>
import VatReceipt from "../objects/VatReceipt.vue";
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import SimpleButton from "../objects/Button.vue";
import Confirm from "../objects/Confirm.vue";
import Payment from "../sections/Payment.vue";
import moment from "moment";
import jQuery from "jquery";
import AddPayment from "../sections/NewPayment.vue";

export default {
  mounted() {
    this.$root.$data.store.showLoader();

    this.$http.get(`/ticket/load/${ticketId}`).then(
      response => {
        console.log('response', response);
        this.ticket = response.body.ticket;
        this.pmsPermit = response.body.pms_permit;
        this.customer = response.body.customer;
        this.existingPrice = response.body.ticket.price;
        this.initialRegistration = response.body.ticket.registration;
        this.initialEntryDate = response.body.ticket.valid_from;
        this.initialExitDate = response.body.ticket.valid_till;

        let entryDate = moment(response.body.ticket.valid_from);
        let exitDate = moment(response.body.ticket.valid_till);
        const days = moment(exitDate).diff(entryDate, "days");
        const hours = moment(exitDate).diff(entryDate, "hours");

        this.oldDuration = {
          days,
          hours
        };

        this.calculatePrice();

        $ = jQuery.noConflict();

        $(document).ready(() => {
          $("#edit-entry-time")
            .datetimepicker({
              format: "yyyy-mm-dd hh:00",
              maxView: 3,
              minView: 1,
              minuteStep: 0
            })
            .on("changeDate", ev => {
              this.entryDate = moment(ev.date).format("YYYY-MM-DD HH:00:00");
              this.priceCalculating = true;
              this.calculatePrice();
              this.datepicker = "";
              $("#edit-entry-time").datetimepicker("hide");
            });

          $("#edit-exit-time")
            .datetimepicker({
              format: "yyyy-mm-dd hh:00",
              maxView: 3,
              minView: 1,
              minuteStep: 0
            })
            .on("changeDate", ev => {
              this.exitDate = moment(ev.date).format("YYYY-MM-DD HH:00:00");
              this.priceCalculating = true;
              this.calculatePrice();
              this.datepicker = "";
              $("#edit-exit-time").datetimepicker("hide");
            });
        });
        /*
            setTimeout(() => {
                $('#edit-entry-time').datetimepicker({
                    format: 'yyyy-mm-dd hh:00',
                    maxView: 3,
                    minView: 1,
                    minuteStep: 0,
                })
                .on('changeDate', (ev) => {
                    this.entryDate = moment(ev.date).format('YYYY-MM-DD HH:00:00')
                    this.priceCalculating = true
                    this.calculatePrice()
                    $('#edit-entry-time').datetimepicker('hide');
                })

                $('#edit-exit-time').datetimepicker({
                    format: 'yyyy-mm-dd hh:00',
                    maxView: 3,
                    minView: 1,
                    minuteStep: 0,
                })
                .on('changeDate', (ev) => {
                    this.exitDate = moment(ev.date).format('YYYY-MM-DD HH:00:00')
                    this.priceCalculating = true
                    this.calculatePrice()
                    $('#edit-exit-time').datetimepicker('hide');
                })
            }, 1000)
            */
        this.$root.$data.store.hideLoader();

        this.loading = false;
      },
      err => {
        this.loading = false;
        this.$root.$data.store.hideLoader();
        this.$root.$data.store.showMessage(err.body.error, "bg-red-600");
      }
    );
  },
  data() {
    return {
      loading: true,
      editing: false,
      entrance: "",
      payment: false,
      confirming: false,
      confirmationType: "",
      priceCalculating: false,
      oldDuration: null,
      view: "ticket",
      now: moment(),
      datepicker: "",
      datePickerTitle: "",
      ticketProcessing: false,
      cancelButtonLoading: false,
      updateButtonLoading: false,
      initialEntryDate: null,
      initialExitDate: null,
      initialRegistration: null
    };
  },
  computed: {
    active() {
      return (
        moment().isSameOrBefore(
          moment(this.initialEntryDate).subtract(10, "minutes")
        ) && this.ticket.status === "valid"
      );
    },
    confirmButtonText() {
      if (this.confirmationType == "update") {
        if (this.priceDiff == 0) {
          return "Confirm Changes";
        } else if (this.priceDiff > 0) {
          return `Confirm & Pay £${this.priceDiff.toFixed(2)}`;
        } else {
          return `Confirm & Refund £${Math.abs(this.priceDiff).toFixed(2)}`;
        }
      } else {
        return "Cancel Ticket";
      }
    },
    ticket: {
      get() {
        return this.$root.$data.store.existingTicket;
      },
      set(value) {
        this.$root.$data.store.setObject("existingTicket", value);
      }
    },
    pmsPermit: {
      get() {
        return this.$root.$data.store.pmsPermit;
      },
      set(value) {
        this.$root.$data.store.setObject("pmsPermit", value);
      }
    },
    customer: {
      get() {
        return this.$root.$data.store.customer;
      },
      set(value) {
        this.$root.$data.store.setObject("customer", value);
      }
    },
    status() {
      let status = "";
      if (this.ticket.status === "valid") {
        if (moment(this.ticket.valid_till).isAfter(moment())) {
          status = "Active";
        } else {
          status = "Expired";
        }
      } else {
        status = "Cancelled";
      }

      return status;
    },
    duration: {
      get() {
        if (this.entryDate === "" || this.exitDate === "" || !this.validDate())
          return null;

        let duration = "";
        let entryDate = moment(this.entryDate);
        let exitDate = moment(this.exitDate);
        const days = moment(exitDate).diff(entryDate, "days");
        const hours = moment(exitDate).diff(entryDate, "hours");

        if (days === 0) {
          duration = hours === 1 ? `${hours} hour` : `${hours} hours`;
        } else {
          let remainingHours = hours - days * 24;
          if (days === 1) {
            if (remainingHours === 0) {
              duration = `${days} day`;
            } else if (remainingHours === 1) {
              duration = `${days} day, ${remainingHours} hour`;
            } else {
              duration = `${days} day, ${remainingHours} hours`;
            }
          } else {
            if (remainingHours === 0) {
              duration = `${days} days`;
            } else if (remainingHours === 1) {
              duration = `${days} days, ${remainingHours} hour`;
            } else {
              duration = `${days} days, ${remainingHours} hours`;
            }
          }
        }

        return duration;
      }
    },
    saveButtonActive() {
      if (
        this.entryDate !== this.initialEntryDate ||
        this.exitDate !== this.initialExitDate ||
        this.registration !== this.initialRegistration
      ) {
        if (this.validDate() && this.registrationValid()) {
          return true;
        }
        return false;
      }

      return false;
    },
    priceDiff() {
      return parseFloat(this.ticket.price) - parseFloat(this.existingPrice);
    },
    hoursDiff() {
      let assignment = "";
      let dummyStartDate, dummyEndDate, duration;
      const oldDuration = this.oldDuration;
      const currentDuration = {
        days: null,
        hours: null
      };

      // calculate difference
      const diffInHours =
        oldDuration.hours + moment(this.entryDate).diff(this.exitDate, "hours");

      // create date object
      if (diffInHours > 0) {
        dummyStartDate = moment("2000-01-01 00:00:00");
        dummyEndDate = moment("2000-01-01 00:00:00").add(diffInHours, "hours");
        assignment = "+";
      } else {
        dummyStartDate = moment("2000-01-01 00:00:00");
        dummyEndDate = moment("2000-01-01 00:00:00").add(diffInHours, "hours");
        assignment = "-";
      }
      // get days diff
      const days = moment(dummyStartDate).diff(dummyEndDate, "days");
      const hours = moment(dummyStartDate).diff(dummyEndDate, "hours");

      if (days === 0) {
        duration = hours === 1 ? `${hours} hour` : `${hours} hours`;
      } else {
        let remainingHours = hours - days * 24;
        if (days === 1) {
          if (remainingHours === 0) {
            duration = `${days} day`;
          } else if (remainingHours === 1) {
            duration = `${days} day, ${remainingHours} hour`;
          } else {
            duration = `${days} day, ${remainingHours} hours`;
          }
        } else {
          if (remainingHours === 0) {
            duration = `${days} days`;
          } else if (remainingHours === 1) {
            duration = `${days} days, ${remainingHours} hour`;
          } else {
            duration = `${days} days, ${remainingHours} hours`;
          }
        }
      }

      return diffInHours < 0 ? "+" + duration : duration;
    },
    totalPrice: {
      get() {
        return this.ticket.price;
      },
      set(value) {
        this.$root.$data.store.setField("existingTicket", "price", value);
      }
    },
    existingPrice: {
      get() {
        return this.ticket.existingPrice;
      },
      set(value) {
        this.$root.$data.store.setField(
          "existingTicket",
          "existingPrice",
          value
        );
      }
    },
    initialPrice: {
      get() {
        return this.ticket.initialPrice;
      },
      set(value) {
        this.$root.$data.store.setField(
          "existingTicket",
          "initialPrice",
          value
        );
      }
    },
    registration: {
      get() {
        return this.ticket.registration;
      },
      set(value) {
        value = value.toUpperCase();
        this.$root.$data.store.setField(
          "existingTicket",
          "registration",
          value
        );
      }
    },
    entryDate: {
      get() {
        return this.ticket.valid_from;
      },
      set(value) {
        this.$root.$data.store.setField("existingTicket", "valid_from", value);
      }
    },
    exitDate: {
      get() {
        return this.ticket.valid_till;
      },
      set(value) {
        this.$root.$data.store.setField("existingTicket", "valid_till", value);
      }
    },
    entryDateDisplay() {
      return moment(this.$root.$data.store.existingTicket.valid_from).format(
        "Do MMMM YYYY"
      );
    },
    entryTimeDisplay() {
      return moment(this.$root.$data.store.existingTicket.valid_from).format(
        "HH:mm"
      );
    },
    exitDateDisplay() {
      return moment(this.$root.$data.store.existingTicket.valid_till).format(
        "Do MMMM YYYY"
      );
    },
    exitTimeDisplay() {
      return moment(this.$root.$data.store.existingTicket.valid_till).format(
        "HH:mm"
      );
    },
    dateValid() {
      if (!this.active) return true;

      let currentDate = moment().subtract(1, "hour");
      let entryDate = moment(this.entryDate);
      let exitDate = moment(this.exitDate);

      if (
        entryDate.isBefore(currentDate) ||
        exitDate.isBefore(entryDate) ||
        exitDate.isSame(entryDate)
      ) {
        return false;
      }

      return true;
    },
    receipt() {
      return [
        {
          label: "Date",
          value: moment(this.ticket.order_date).format("Do MMMM YYYY"),
          bold: false
        },
        {
          label: "Registration",
          value: this.initialRegistration,
          bold: false
        },
        {
          label: "Gross",
          value: "£" + (this.existingPrice * 0.8).toFixed(2),
          bold: false
        },
        {
          label: "VAT",
          value:
            "£" + (this.existingPrice - this.existingPrice * 0.8).toFixed(2),
          bold: false
        },
        {
          label: "Total",
          value: "£" + (this.existingPrice * 1).toFixed(2),
          bold: true
        }
      ];
    }
  },
  methods: {
    save(paymentIntent = null) {
      if (this.ticketProcessing && !paymentIntent) return;

      // if (!paymentIntent) {
      //   let message = "";
      //   if (this.ticket.promotion && this.ticket.promotion.discount == 0) {
      //     message += "Please confirm changes to your free booking";
      //   } else if (this.priceDiff == 0) {
      //     message += `You are updating the registration number from ${
      //       this.initialRegistration
      //     } to ${this.registration.toUpperCase()}.`;
      //   } else {
      //     message += `You are updating the registration number from ${
      //       this.initialRegistration
      //     } to ${this.registration.toUpperCase()}.

      //     By making this change to your booking you will be ${
      //       this.priceDiff > 0 ? "charged an additional" : "refunded"
      //     } £${
      //       this.priceDiff == 0
      //         ? this.ticket.price
      //         : this.priceDiff > 0
      //         ? this.priceDiff.toFixed(2)
      //         : (-this.priceDiff).toFixed(2)
      //     }`;
      //   }

      //   var r = confirm(message);

      //   if (!r) {
      //     return;
      //   }
      // }

      this.$root.$data.store.showLoader();

      this.ticketProcessing = true;
      this.updateButtonLoading = true;

      let ticketBody = paymentIntent
        ? {
            id: this.ticket.id,
            data: this.$root.$data.store,
            payment_intent: paymentIntent
          }
        : {
            id: this.ticket.id,
            data: this.$root.$data.store
          };

      this.$http
        .post(`/my-account/ticket/update`, ticketBody, {
          headers: { "X-CSRF-TOKEN": csrfToken }
        })
        .then(
          response => {
            console.log(response);
            if (!response.body) response.body = response.bodyText
            if (response.body.requires_action) {
              return this.handlePaymentIntent(response.body.intent_secret);
            }

            window.location.href = `/my-account/ticket/${this.ticket.id}`;
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(err, "bg-red-500");
            this.ticketProcessing = false;
            this.updateButtonLoading = false;
            console.error(err);
          }
        );
    },
    handlePaymentIntent(secret) {
      let stripe = Stripe(stripePublicKey);

      stripe.handleCardAction(secret).then(result => {
        if (result.error) {
          this.loading = false;
          this.$root.$data.store.showMessage(
            result.error.message,
            "bg-red-500"
          );
          return;
        } else {
          this.save(result);
        }
      });
    },
    cancel() {
      this.$root.$data.store.showLoader();

      this.$http.get(`/my-account/ticket/cancel/${this.ticket.id}`).then(
        response => {
          window.location.href = `/my-account/ticket/${this.ticket.id}`;
        },
        err => {
          this.$root.$data.store.hideLoader();
          this.confirming = false;
          this.ticketProcessing = false;
          this.cancelButtonLoading = false;
          this.$root.$data.store.showMessage(err.body, "bg-red-500");
          console.error(err);
        }
      );
    },
    validDate() {
      if (!this.active) return true;

      let currentDate = moment().subtract(1, "hour");
      let entryDate = moment(this.entryDate);
      let exitDate = moment(this.exitDate);

      if (entryDate.isBefore(currentDate)) {
        this.$root.$data.store.showMessage(
          "Entry date must be after the current hour.",
          "bg-red-500"
        );
        return false;
      }

      if (exitDate.isBefore(entryDate) || exitDate.isSame(entryDate)) {
        this.$root.$data.store.showMessage(
          "Exit date must be after the entry date.",
          "bg-red-500"
        );
        return false;
      }

      return true;
    },
    toggleDatePicker(picker, title) {
      if (!this.active) return;
      this.datepicker = picker;
      this.datePickerTitle = title;
      $ = jQuery.noConflict();
      $("#" + picker).datetimepicker("show");
    },
    momentDate(date, format) {
      return moment(date).format(format);
    },
    calculatePrice() {
      if (this.entryDate === "" || this.exitDate === "" || !this.validDate()) {
        this.priceCalculating = false;
        return;
      }

      this.$http
        .post(
          "/ticket/price",
          {
            startDate: this.entryDate,
            endDate: this.exitDate,
            carPark: this.$root.$data.store.existingTicket.car_park.id,
            ticket: this.ticket
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          price => {
            this.priceCalculating = false;

            if (this.ticket.promotion) {
              this.$http
                .post(
                  `/promotion/apply`,
                  {
                    car_park_id: this.ticket.car_park_id,
                    code: this.ticket.promotion.code,
                    total: price.body,
                    ticket: this.ticket
                  },
                  {
                    headers: { "X-CSRF-TOKEN": csrfToken }
                  }
                )
                .then(
                  response => {
                    console.log(response);
                    this.initialPrice = price.body;
                    this.totalPrice = response.body;
                  },
                  response => {
                    if (!response.body) response.body = response.bodyText;
                    this.$root.$data.store.showMessage(
                      response.body,
                      "bg-turquoise"
                    );
                  }
                );
            } else {
              this.initialPrice = price.body;
              this.totalPrice = price.body;
            }
          },
          err => {
            this.priceCalculating = false;
            console.log(err);
          }
        );
    },
    confirmUpdate() {
      this.confirmationType = "update";
      this.confirming = true;

      if (parseFloat(this.ticket.price) > parseFloat(this.existingPrice)) {
        this.view = "payment";
        return;
      }
    },
    confirmCancel() {
      this.confirmationType = "cancel";
      this.confirming = true;
    },
    cancelConfirmation() {
      this.confirming = false;
    },
    confirmButtonEvent() {
      if (this.confirmationType == "update") {
        this.save();
      } else {
        this.cancel();
      }
    },
    registrationValid() {
      // let regex = RegExp(
      //   "(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)"
      // );

      let regex = new RegExp(/^[A-Za-z0-9\s]{2,}$/);

      return regex.test(this.registration);
    }
  },
  components: {
    VatReceipt,
    SectionTitle,
    SimpleButton,
    TextInput,
    Confirm,
    Payment,
    AddPayment
  }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h1",
    {
      class: [
        _vm.slim ? "slim" : "",
        _vm.underlined ? "underlined" : "",
        _vm.centered ? "centered" : ""
      ]
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0e3cb345", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div class="progress">
    <div class="progress--blocks">
      <div
        class="progress--block"
        v-for="block in blocks"
        :key="block"
        :class="[active(block) ? 'active' : '']"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      blocks: [1, 2, 3, 4, 5, 6, 7]
    };
  },
  computed: {
    progress() {
      return this.$root.$data.store.progress;
    }
  },
  methods: {
    active(block) {
      return this.progress >= block;
    }
  },
  props: ["current"]
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fixed top-0 left-0 w-full h-full z-10" }, [
      _c("div", {
        staticClass: "fixed top-0 left-0 w-full h-full bg-white opacity-75"
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fixed top-0 left-0 w-full h-full scale-up-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "rounded-full w-20 h-20 bg-white justify-center absolute transform-center shadow-lg"
            },
            [
              _c("img", {
                staticClass: "h-auto w-loader absolute transform-center",
                attrs: { src: "/img/loader-logo.png" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded-full w-20 h-20 bg-white justify-center absolute border-loader border-6 border-solid transform-center loader-fade"
            },
            [
              _c("img", {
                staticClass: "h-auto w-loader absolute transform-center loader",
                attrs: { src: "/img/loader-logo.png" }
              })
            ]
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4afaa736", { render: render, staticRenderFns: staticRenderFns })
  }
}
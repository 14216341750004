<template>
    <div :class="['popup', toggle]">
        <div class="popup--bg" @click.prevent="close"></div>
        <div class="popup--wrapper">
            <div class="popup--header">
                <h2>{{ title }}</h2>
                <a href="#" class="popup--exit" @click.prevent="close"><img src="/img/cross.svg" width="20" alt="" /></a>
            </div>
            <div class="popup--body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created () {

    },
    data () {
        return {
            isOpen: false,
            modal: this.$root.$data.store.modals[this.modalId]
        }
    },
    computed: {
        toggle() {
            return this.$root.$data.store.modals[this.modalId].isOpen ? 'popup--open' : 'popup--close'
        }
    },
    methods: {
        close () {
            this.$root.$data.store.closeModal(this.modalId)
        }
    },
    props: ['modalId', 'title']
}
</script>

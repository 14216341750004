var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration section" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.next($event)
            }
          }
        },
        [
          _c("div", {}, [
            _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
              _vm._v("Driver's Name")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.driver,
                  expression: "driver"
                }
              ],
              staticClass:
                "dusk-driver-name w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise text-center xs:text-meta micro:text-lg",
              attrs: { type: "text", placeholder: "Driver's Name" },
              domProps: { value: _vm.driver },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.driver = $event.target.value
                }
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "ButtonFixed",
        { attrs: { active: _vm.nextButtonActive, clickEvent: _vm.next } },
        [_vm._v("Continue")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7bf4ce02", { render: render, staticRenderFns: staticRenderFns })
  }
}
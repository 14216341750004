<template>
  <div
    class="mt-header w-full border-t border-solid border-dull-blue mb-10 dusk-season-ticket"
    v-if="seasonTicket && !loading"
  >
    <div class="bg-blue w-full pb-50">
      <div
        class="max-w-5xl sm:w-2/3 xs:w-auto xs:mx-6 sm:mx-auto flex sm:flex-row xs:flex-col justify-between items-center bg-blue xs:mb-16 sm:mb-20 py-8"
      >
        <div class="flex flex-col xs:justify-center sm:justify-start xs:mb-4 sm:mb-0">
          <a
            class="text-sm text-white font-bold block flex items-center mb-2 xs:justify-center sm:justify-start"
            href="/my-account"
          >
            <img src="/img/small-chevron-white.svg" class="w-2 h-auto mr-1" alt />
            <span class="text-meta mr-1">Back to my account</span>
          </a>
          <h2
            v-if="seasonTicket.season"
            class="text-white font-bold text-lg m-0 text-center"
          >{{ seasonTicket.season.title }} Season Ticket {{ seasonTicket.price == '0' ? '' : ' - £' + parseInt(seasonTicket.price).toFixed(2)}}</h2>
        </div>
        <div
          class="flex sm:flex-row xs:flex-col justify-between xs:w-full sm:w-auto flex sm:flex-row xs:flex-col justify-between"
        >
          <template v-if="beforeStartDate"></template>
          <template v-else-if="seasonTicket.status === 'active'">
            <button
              v-if="seasonTicket.payment_status === 'unauthorized'"
              @click.prevent="retrievePaymentAuthorization"
              class="xs:hidden sm:inline bg-active-green hover:opacity-90 rounded-sm font-bold text-white text-xs xs:px-5 sm:px-5 xs:py-2 sm:py-5 tracking-fat sm:px-3 sm:py-3 xs:mb-2 sm:mb-0"
            >Authorise Renewal</button>
            <button
              @click.prevent="cancel"
              class="dusk-cancel-renewal xs:hidden sm:inline text-white hover:opacity-90 xs:px-5 sm:px-5 xs:py-3 sm:py-5 rounded-sm text-xs font-bold border-white bg-red-600 xs:ml-0 sm:ml-2 tracking-fat"
            >Cancel Renewal</button>
          </template>
          <template v-else>
            <button
              v-if="!pastRenewal"
              @click.prevent="reactivate"
              class="dusk-activate-renewal xs:hidden sm:inline text-white hover:opacity-90 xs:px-5 sm:px-5 xs:py-2 sm:py-5 rounded-sm text-xs font-bold bg-active-green hover:opacity-90 ml-2 tracking-fat"
            >Activate Renewal</button>
          </template>
        </div>
      </div>
    </div>

    <div class="flex sm:flex-row xs:flex-col justify-between -mt-overlap">
      <div
        class="max-w-5xl micro:w-2/3 xs:w-auto mini:mx-6 xs:mx-0 bg-white sm:flex-row xs:flex xs:flex-col-reverse shadow-sm sm:p-10 xs:p-6 micro:mx-auto rounded-sm"
      >
        <div

          class="sm:w-4/12 xs:w-full sm:mr-10 xs:mx-auto xs:mt-6 sm:mt-0 meta:block xs:hidden"
        >


          <template v-if="seasonTicket.barcode">
            <img
              :class="['w-full h-auto p-6 border border-solid border-gray-300 block', !pastRenewal ? 'opacity-100' : 'opacity-25' ]"
            :src="`/qr-code/${seasonTicket.barcode}`"
            alt
          />
          </template>

          <a
            v-if="!pastRenewal"
            :href="'/season-ticket/passbook/' + seasonTicket.id"
            class="ticket--passbook text-center m-auto block"
          >
            <img src="/img/apple-wallet.svg" class="pt-4" width="200" alt />
          </a>
        </div>
        <div class="xs:w-full sm:w-8/12 text-sm">
          <div class="flex items-center mb-1">
            <div
              v-if="beforeStartDate"
              class="p-2 text-white font-bold uppercase text-tiny mr-2 rounded-sm bg-turquoise"
            >Starts On {{ momentDate(seasonTicket.order_date, 'Do MMMM YYYY') }}</div>
            <div
              v-else
              :class="['p-2 font-bold uppercase text-tiny mr-2 rounded-sm', statusColor, status === 'Active' || status === 'Expired' ? 'text-white' : 'text-black']"
            >{{ status }}</div>
            <div
              class="text-xs"
              v-if="!beforeStartDate"
            >{{ dateStatus }} {{ momentDate(seasonTicket.renewal_date, 'Do MMMM YYYY') }}</div>
          </div>
          <h1
            v-if="seasonTicket.car_park"
            class="mb-1 m-0 xs:text-lg tiny:text-lgr text-left"
          >{{ seasonTicket.car_park.name }}</h1>
          <div class="flex mb-2 items-center">
            <div class="mr-1">
              <img src="/img/pin-turquoise.svg" />
            </div>
            <p
              class="leading-tighter text-xs m-0 block"
              v-if="seasonTicket.car_park"
            >{{ seasonTicket.car_park.address_1 }}, {{ seasonTicket.car_park.address_2 }}, {{ seasonTicket.car_park.postcode }}</p>
          </div>
          <p class="mb-4 m-0 p-0"></p>
          <div
            v-if="seasonTicket.car_park.uses_zatpark != 1 && seasonTicket.car_park.uses_license != 1"
            class="sm:w-4/12 xs:w-full sm:mr-10 xs:mx-auto xs:mt-6 sm:mt-0 meta:hidden xs:block"
          >

          <div :class="['w-full h-auto p-6 border border-solid border-gray-300 block', !pastRenewal ? 'opacity-100' : 'opacity-25' ]" v-html="qrCode"></div>
            <a
              v-if="!pastRenewal"
              :href="'/season-ticket/passbook/' + seasonTicket.id"
              class="ticket--passbook text-center mx-auto block mb-6"
            >
              <img src="/img/apple-wallet.svg" class="pt-4" width="200" alt />
            </a>
          </div>
          <h3 class="mb-2 m-0 p-0 font-bold text-xs">Registrations</h3>
          <div
            class="flex xs:flex-col tiny:flex-row justify-between items-center mb-1 bg-gray-200 p-2 xs:w-full lg:w-1/2 rounded-sm"
            v-for="(registration, index) in seasonTicket.registrations"
            :key="registration.id"
          >
            <div class="flex items-center xs:flex-col tiny:flex-row xs:w-full tiny:w-auto">
              <div
                class="xs:w-full meta:w-plate xs:mb-1 meta:mb-0 border-1 bg-yolk text-center font-bold text-black xs:text-xs meta:text-micro tracking-normal meta:p-1 xs:p-3 rounded-sm border-solid border-yolk-dark"
              >{{ registration.registration }}</div>
              <div
                class="meta:mt-0 xs:mt-2 meta:text-meta xs:text-xs ml-2 xs:mb-2 meta:mb-0 font-bold"
              >Vehicle #{{ index + 1 }}</div>
            </div>
            <div class="xs:w-full xs:block tiny:w-auto xs:inline">
              <button
                @click.prevent="openRemoveRegistrationPopup(registration.registration)"
                :class="['dusk-remove-' + index + ' leading-normal rounded-sm px-2 py-1 bg-red-600 text-white font-bold uppercase hover:opacity-90 xs:w-full xs:block tiny:w-auto xs:inline xs:text-meta tiny:text-tiny']"
                v-if="seasonTicket.registrations.length !== 1"
              >Remove</button>
            </div>
          </div>
          <button
            v-if="seasonTicket.registrations.length < 2 && seasonTicket.status === 'active'"
            :class="['dusk-add-registration-open-popup p-2 bg-blue text-white xs:w-full sm:w-1/2 rounded-sm text-tiny font-bold uppercase', additionalRegistrationAllowed ? 'opacity-100' : 'opacity-50 pointer-events-none']"
            type="button"
            :disabled="!additionalRegistrationAllowed"
            @click.prevent="addingRegistration = true"
          >Add Registration</button>
          <p
            class="m-0 p-0 text-micro mt-2 text-gray-500 font-bold"
          >* You may only add an additional registration every 14 days.</p>
          <h3
            class="font-bold mt-4 mb-2 text-xs"
            v-if="seasonTicket.status === 'active' && seasonTicket.card"
          >Payment method</h3>
          <div
            v-if="seasonTicket.status === 'active' && seasonTicket.card"
            class="lg:w-6/12 xs:w-full flex justify-between items-center mb-2 bg-gray-400 p-3 rounded-sm"
          >
            <div class="flex items-center" v-if="seasonTicket.card">
              <img
                :src="'/img/' + seasonTicket.card.brand.replace('_', '-').toLowerCase() + '.svg'"
                alt
                class="w-8 mr-2"
              />
              <div class="font-bold text-xs">
                <span class="capitalize">{{ seasonTicket.card.brand }}</span>
                ending in
                {{ seasonTicket.card.last4 }}
              </div>
            </div>
            <button
              @click="goToPayment()"
              :class="[loading ? 'opacity-50' : '', 'dusk-change-payment leading-normal rounded-sm px-2 py-1 bg-blue text-white text-tiny font-bold uppercase hover:opacity-90']"
            >Change</button>
          </div>
          <p
            class="text-meta text-gray-500 mb-4 leading-normal"
            v-if="seasonTicket.status === 'active' && seasonTicket.payment_status === 'authorized' && seasonTicket.card"
          >Renewal payment will processed at 12am on the {{ momentDate(seasonTicket.renewal_date, 'Do MMMM YYYY') }}. You may receive a notification from your bank 5 days beforehand saying we have taken payment, but this is only an authorisation even if your bank says otherwise. We only capture payments on the specified renewal date.</p>
          <div
            v-if="seasonTicket.promotion"
            class="leading-tight text-xs block flex tiny:mb-0 xs:mb-5 mt-4"
          >
            <div class="mr-2 mt-nudge">
              <img src="/img/voucher.svg" width="14" />
            </div>
            <div class="leading-tight text-xs m-0 block -mt-nudge">
              <p class="mb-2 m-0 font-bold text-xs">Promotion</p>
              <p class="m-0 p-0 text-meta">{{ seasonTicket.promotion.code.toUpperCase() }}</p>
            </div>
          </div>
          <template v-if="beforeStartDate"></template>
          <template v-else-if="seasonTicket.status === 'active'">
            <button
              v-if="seasonTicket.payment_status === 'unauthorized'"
              @click.prevent="retrievePaymentAuthorization"
              class="xs:inline sm:hidden bg-active-green hover:opacity-90 w-full rounded-sm font-bold text-white text-xs xs:px-5 sm:px-5 xs:py-3 sm:py-5 tracking-fat sm:px-3 sm:py-3 xs:mb-2 sm:mb-0"
            >Authorise Renewal</button>
            <button
              @click.prevent="cancel"
              class="xs:inline sm:hidden text-white hover:opacity-90 block w-full xs:px-5 sm:px-5 xs:py-3 sm:py-5 rounded-sm text-xs font-bold border-white bg-red-600 tracking-fat"
            >Cancel Renewal</button>
          </template>
          <template v-else>
            <button
              v-if="!pastRenewal"
              @click.prevent="reactivate"
              class="mt-6 xs:inline sm:hidden text-white hover:opacity-90 w-full block xs:px-5 sm:px-5 xs:py-3 sm:py-5 rounded-sm text-xs font-bold bg-active-green tracking-fat"
            >Activate Renewal</button>
          </template>
        </div>
      </div>
    </div>

    <div
      class="xs:mt-6 tiny:mt-10 lg:w-4/12 sm:w-6/12 xs:w-full xs:p-6 sm:p-0 mx-auto"
      v-if="seasonTicket.card"
    >
      <h2 class="border-b-2 border-blue border-solid pb-3 m-0 text-lg">Invoices</h2>
      <div
        class="flex justify-between border-b border-gray-200 border-solid py-3 items-center text-xs"
        v-for="invoice in seasonTicket.payments"
        :key="invoice.id"
      >
        <div class="flex items-center">
          <div
            class="mr-4 font-bold xs:text-meta tiny:text-xs"
          >£{{ parseInt(invoice.amount).toFixed(2) }}</div>
          <div
            class="xs:text-meta tiny:text-xs"
          >{{ momentDate(invoice.period_start, 'Do MMMM YYYY') }}</div>
        </div>
        <a
          class="bg-blue text-tiny text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start ml-2 hover:opacity-75"
          :href="invoice.invoice_url"
          target="_blank"
        >Download</a>
      </div>
    </div>
    <transition mode="out-in" name="fade-in">
      <template v-if="addingRegistration">
        <div class="fixed top-0 left-0 w-full h-full z-1 fixed flex justify-center items-center">
          <div
            @click.prevent="closePopup"
            class="fixed top-0 left-0 w-full h-full bg-white opacity-75"
          ></div>
          <div
            class="scale-up-center-slow border-2 border-solid border-dull-blue elative z-10 bg-blue rounded-sm text-white shadow-lg"
            style="width: 355px"
          >
            <h2
              class="m-0 p-0 text-white p-3 border-b border-solid border-dull-blue text-center"
            >Add Registration</h2>
            <div class="m-4">
              <input
                autofocus
                v-model="newRegistrationDisplay"
                class="dusk-add-registration-input w-full mx-auto xs:mb-1 meta:mb-0 border-1 bg-yolk text-center font-bold text-black tracking-normal p-3 rounded-sm border-solid border-yolk-dark uppercase text-sm"
              />
              <p
                class="text-white text-micro leading-tight mt-2 font-bold"
                v-if="seasonTicket.registrations.length === 1"
              >*Please note that once you add this registration, you may not add another for 14 days.</p>
            </div>
            <div class="m-4">
              <button
                @click.prevent="addRegistration"
                :disabled="!addNewRegistrationButtonActive"
                :class="['dusk-add-registration-button w-full font-bold text-small-btn tracking-fat mb-1 uppercase bg-turquoise hover:opacity-90 p-2-1/2 block text-white rounded-sm', addNewRegistrationButtonActive ? 'opacity-100' : 'opacity-50 pointer-events-none']"
              >Add Registration</button>
              <button
                @click.prevent="closePopup"
                class="w-full font-bold text-small-btn tracking-fat mb-1 uppercase p-2-1/2 bg-red-600 hover:bg-red-700 block text-white rounded-sm"
              >Nevermind</button>
            </div>
          </div>
        </div>
      </template>
      <template v-if="removingRegistration">
        <div class="fixed top-0 left-0 w-full h-full z-1 fixed flex justify-center items-center">
          <div
            @click.prevent="closePopup"
            class="fixed top-0 left-0 w-full h-full bg-white opacity-75"
          ></div>
          <div
            class="scale-up-center-slow border-2 border-solid border-dull-blue elative z-10 bg-blue rounded-sm text-white shadow-lg"
            style="width: 355px"
          >
            <h2
              class="m-0 p-0 text-white p-3 border-b border-solid border-dull-blue text-center"
            >Remove Registration</h2>
            <div class="m-4">
              <input
                disabled
                v-model="registrationToRemove"
                class="pointers-events-none w-full mx-auto xs:mb-1 meta:mb-0 border-1 bg-yolk text-center font-bold text-black tracking-normal p-3 rounded-sm border-solid border-yolk-dark uppercase text-sm"
              />
              <p
                class="text-white text-micro leading-tight mt-2 font-bold"
                v-if="!additionalRegistrationAllowed"
              >*Please note that once you remove this registration, you may not add another until after {{ momentDate(this.seasonTicket.reg_timer, 'Do MMMM YYYY HH:mm') }}.</p>
            </div>
            <div class="m-4">
              <button
                @click.prevent="removeRegistration()"
                :class="['dusk-remove-registration w-full font-bold text-small-btn tracking-fat mb-1 uppercase bg-turquoise hover:opacity-90 p-2-1/2 block text-white rounded-sm']"
              >Remove Registration</button>
              <button
                @click.prevent="closePopup"
                class="w-full font-bold text-small-btn tracking-fat mb-1 uppercase p-2-1/2 bg-red-600 hover:bg-red-700 block text-white rounded-sm"
              >Nevermind</button>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import SimpleButton from "../objects/Button.vue";
import SectionTitle from "../objects/SectionTitle.vue";
import moment from "moment";
import VatReceipt from "../objects/VatReceipt.vue";

export default {
  components: {
    SectionTitle,
    VatReceipt,
    SimpleButton
  },
  mounted() {
    this.$root.$data.store.showLoader();
    this.$http.get(`/season-ticket/load/${ticketId}`).then(response => {
      this.seasonTicket = response.body.seasonTicket;

      this.registration = this.seasonTicket.registration;

      if (this.$root.$data.store.ticket.card) {
        this.updatePaymentMethod();
      } else {
        this.loading = false;
        this.$root.$data.store.hideLoader();
      }
    });
  },
  data() {
    return {
      loading: true,
      addingRegistration: false,
      newRegistration: "",
      newRegistrationText: "",
      registrationToRemove: null,
      confirmationType: "",
      removingRegistration: false
    };
  },
  computed: {
    additionalRegistrationAllowed() {
      const now = moment();
      const timer = moment(this.seasonTicket.reg_timer);

      if (!this.seasonTicket.reg_timer) return true;

      return now.isAfter(timer);
    },
    addNewRegistrationButtonActive() {
      return (
        this.registrationValid(this.newRegistration) &&
        this.newRegistration.length > 0
      );
    },
    currentRegistrations() {
      return this.$root.$data.store.seasonTicket.registrations;
    },
    newRegistrationDisplay: {
      get() {
        return this.newRegistrationText;
      },
      set(value) {
        this.newRegistrationText = value;
        value = value
          .toUpperCase()
          .replace(" ", "")
          .trim();
        this.newRegistration = value;
      }
    },
    seasonTicket: {
      get() {
        return this.$root.$data.store.seasonTicket;
      },
      set(value) {
        this.$root.$data.store.setObject("seasonTicket", value);
      }
    },
    data() {
      return this.$root.$data.store;
    },
    statusColor() {
      if (moment(this.seasonTicket.renewal_date).isBefore(moment())) {
        return "bg-red-500";
      } else {
        if (this.seasonTicket.status === "cancelled") {
          return "bg-yolk border-solid border-yolk-dark border-1";
        } else {
          return "bg-active-green";
        }
      }
    },
    status() {
      if (moment(this.seasonTicket.renewal_date).isBefore(moment())) {
        return "Expired";
      } else {
        if (this.seasonTicket.status === "cancelled") {
          return `Expiring`;
        } else {
          return "Active";
        }
      }
    },
    dateStatus() {
      if (moment(this.seasonTicket.renewal_date).isBefore(moment())) {
        return "Expired";
      } else {
        if (this.seasonTicket.status === "cancelled") {
          return `Expires`;
        } else {
          return "Renews";
        }
      }
    },
    pastRenewal() {
      return moment(this.seasonTicket.renewal_date).isBefore(moment());
    },
    expired() {
      return moment(this.seasonTicket.renewal_date).isBefore(moment());
    },
    beforeStartDate() {
      return moment(this.seasonTicket.order_date).isAfter(moment());
    },
    receipt() {
      return [
        {
          label: "Date",
          value: moment(this.seasonTicket.order_date).format("Do MMMM YYYY"),
          bold: false
        },
        {
          label: "Registration",
          value: this.seasonTicket.registration,
          bold: false
        },
        {
          label: "Gross",
          value: "£" + (this.seasonTicket.price * 0.8).toFixed(2),
          bold: false
        },
        {
          label: "VAT",
          value:
            "£" +
            (this.seasonTicket.price - this.seasonTicket.price * 0.8).toFixed(
              2
            ),
          bold: false
        },
        {
          label: "Total",
          value: "£" + (this.seasonTicket.price * 1).toFixed(2),
          bold: true
        }
      ];
    }
  },
  methods: {
    registrationValid(registration) {
      // let regex = RegExp(
      //   "(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)"
      // );

      let regex = new RegExp(/^[A-Za-z0-9\s]{2,}$/);

      let noDuplicates =
        this.currentRegistrations.filter(reg => {
          return reg.registration == registration;
        }).length === 0;

      return regex.test(registration) && noDuplicates;
    },
    closePopup() {
      this.addingRegistration = this.removingRegistration = false;
    },
    handlePaymentAuthorization({ client_secret, sca_needed }) {
      if (!sca_needed) {
        return this.confirmPaymentAuthorization();
      }

      let stripe = Stripe(stripePublicKey);

      stripe.handleCardAction(client_secret).then(result => {
        if (result.error) {
          this.$root.$data.store.hideLoader();
          this.$root.$data.store.showMessage(
            result.error.message,
            "bg-red-600"
          );
        } else {
          this.confirmPaymentAuthorization();
        }
      });
    },
    confirmPaymentAuthorization() {
      this.$http
        .post(
          `/season-ticket/confirm-payment-authorization`,
          {
            season_ticket: this.seasonTicket.id
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.$root.$data.store.hideLoader();
            this.seasonTicket = response.body.seasonTicket;
            this.$root.$data.store.showMessage(
              "Payment method successfully authorized.",
              "bg-active-green"
            );
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
          }
        );
    },
    retrievePaymentAuthorization() {
      this.$root.$data.store.showLoader();
      if (this.loading) {
        return;
      }

      this.$http
        .post(
          `/season-ticket/retrieve-payment-authorization`,
          {
            season_ticket: this.seasonTicket.id
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.handlePaymentAuthorization(response.body);
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
          }
        );
    },
    updatePaymentMethod() {
      this.$root.$data.store.showLoader();
      this.$http
        .post(
          `/season-ticket/update-payment-method`,
          {
            season_ticket: this.seasonTicket.id,
            payment_method: this.$root.$data.store.ticket.card.id
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.loading = false;
            this.$root.$data.store.hideLoader();
            this.seasonTicket = response.body.seasonTicket;
            this.$root.$data.store.showMessage(
              "Payment method successfully updated.",
              "bg-active-green"
            );
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
          }
        );
    },
    goToPayment() {
      this.$router.push({ name: "payment" });
    },
    handleSeasonTicketPaymentIntent(secret) {
      let stripe = Stripe(stripePublicKey);

      stripe.handleCardAction(secret).then(result => {
        if (result.error) {
          this.loading = false;
          this.$root.$data.store.showMessage(result.error.message);
        } else {
          this.reactivate(result);
        }
      });
    },
    reactivate(paymentIntent = null) {
      if (!paymentIntent.paymentIntent) {
        var r = confirm("Are you sure you want to reactivate?");

        if (!r) {
          return;
        }
      }

      this.$root.$data.store.showLoader();

      const ticketBody = paymentIntent.paymentIntent
        ? {
            season_ticket: this.seasonTicket.id,
            data: this.data,
            authorization: paymentIntent
          }
        : {
            data: this.data,
            season_ticket: this.seasonTicket.id
          };

      this.$http
        .post(`/season-ticket/reactivate`, ticketBody, {
          headers: { "X-CSRF-TOKEN": csrfToken }
        })
        .then(
          response => {
            if (response.body.client_secret) {
              return this.handleSeasonTicketPaymentIntent(
                response.body.client_secret
              );
            }

            this.loading = false;
            this.$root.$data.store.setField(
              "customer",
              "stripe",
              response.body.stripe
            );
            this.seasonTicket = response.body.seasonTicket;
            this.$root.$data.store.showMessage(
              "Your season ticket has been reactivated successfully!",
              "bg-active-green",
              6000
            );
            this.$root.$data.store.hideLoader();
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
            this.loading = false;
          }
        );
    },
    cancel() {
      this.$root.$data.store.showLoader();

      this.$http
        .post(
          `/season-ticket/cancel`,
          {
            season_ticket: this.seasonTicket.id
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.loading = false;
            this.seasonTicket = response.body.seasonTicket;
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              "Your season ticket has been cancelled successfully. Car park access continues until midnight on the expiry date.",
              "bg-active-green",
              6000
            );
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
            this.loading = false;
          }
        );
    },
    momentDate(date, format) {
      return moment(date).format(format);
    },
    addRegistration() {
      this.$root.$data.store.showLoader();

      this.$http
        .post(
          `/season-ticket/reg/add`,
          {
            season_ticket: this.seasonTicket.id,
            registration: this.newRegistration
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.loading = false;
            this.seasonTicket = response.body.seasonTicket;
            this.$root.$data.store.hideLoader();
            this.closePopup();
            this.$root.$data.store.showMessage(
              "Your vehicle has been added successfully. If your season ticket is active, you can now access the car park immediately.",
              "bg-active-green",
              6000
            );
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
            this.loading = false;
          }
        );
    },
    removeRegistration() {
      this.$root.$data.store.showLoader();

      this.$http
        .post(
          `/season-ticket/reg/remove`,
          {
            season_ticket: this.seasonTicket.id,
            registration: this.registrationToRemove
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.loading = false;
            this.seasonTicket = response.body.seasonTicket;
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              `${this.registrationToRemove} has been removed from this season ticket successfully.`,
              "bg-active-green",
              6000
            );
            this.closePopup();
            this.registrationToRemove = "";
          },
          err => {
            this.$root.$data.store.hideLoader();
            this.$root.$data.store.showMessage(
              err.body.errorMessage,
              "bg-red-600"
            );
            this.loading = false;
          }
        );
    },
    openRemoveRegistrationPopup(registration) {
      this.removingRegistration = true;
      this.registrationToRemove = registration;
    }
  }
};
</script>
import $ from 'jquery';
import Vue from 'vue';

export default {
  alertTimeout: null,
  debug: true,
  autoFill,
  mode,
  progress: 0,
  state: {
    isAdmin: false,
    loggedIn: false,
    existingUser: false,
    message: '',
    terms: false,
    ticketType: null,
    timeEntered: false,
    loading: false,
    alertColor: 'bg-turquoise',
    startDate: null,
    addPaymentRedirect: 'payment',
  },
  customer: {
    id: null,
    first_name: null,
    last_name: null,
    address_1: null,
    address_2: null,
    activeTickets: null,
    inactiveTickets: null,
    mobilePayTickets: null,
    seasonTickets: null,
    tel: null,
    town: null,
    email: null,
    password: null,
    postcode: null,
    stripe: null,
    cards: null,
    tokens: null,
    tickets: null,
  },
  ticket: {
    price: '0.00',
    initialPrice: '0.00',
    location: null,
    carPark: null,
    entryDate: null,
    exitDate: null,
    registration: null,
    promotion: '',
    card: null,
    drivers: [],
  },
  payment: {
    cardName: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCVC: '',
    cardPostcode: '',
    cardToken: null,
  },
  promotion: {
    codeApplied: false,
    codeApplying: false,
  },
  modals: {
    terms: {
      isOpen: false,
    },
  },
  seasonTicket: {
    promotion: '',
    initialPrice: 0,
  },
  existingTicket: null,
  setEmail(email) {
    if (this.debug) console.log('setEmail triggered with', email);
    Vue.set(this.customer, 'email', email);
  },
  setLocations(locations) {
    if (this.debug) console.log('setLocations triggered with', locations);
    Vue.set(this, 'locations', locations);
  },
  setLocation(location) {
    Vue.set(this.ticket, 'location', location);
  },
  setCarParks(carParks) {
    if (this.debug) console.log('setCarParks triggered with', carParks);
    Vue.set(this, 'carParks', carParks);
  },
  setCarPark(carPark) {
    Vue.set(this.ticket, 'carPark', carPark);
  },
  setField(field, name, value) {
    Vue.set(this[field], name, value);
  },
  setState(field, value) {
    Vue.set(this.state, field, value);
  },
  setObject(name, value) {
    Vue.set(this, name, value);
  },
  addCard(card) {
    const cards = this.customer.cards || [];

    cards.push(card);

    Vue.set(this.customer, 'cards', cards);
  },
  addToken(token) {
    const tokens = this.customer.tokens || [];

    tokens.push(token);

    Vue.set(this.customer, 'tokens', tokens);
  },
  showMessage(message, color = 'bg-turquoise', delay = 4000) {
    clearTimeout(this.alertTimeout);

    Vue.set(this.state, 'alertColor', color);
    Vue.set(this.state, 'message', message);

    $('.alert-message').css({
      top: '60px',
    });

    this.alertTimeout = setTimeout(() => {
      $('.alert-message').css({
        top: '-120px',
      });
    }, delay);
  },
  showLoader() {
    Vue.set(this.state, 'loading', true);
  },
  hideLoader() {
    Vue.set(this.state, 'loading', false);
  },
  openModal(id) {
    Vue.set(this.modals[id], 'isOpen', true);
  },
  closeModal(id) {
    Vue.set(this.modals[id], 'isOpen', false);
  },
  storeDriverRegNumber(index, value) {
    Vue.set(this.ticket.driver[index], 'registration', value);
  },
  storeDriverName(index, value) {
    Vue.set(this.ticket.driver[index], 'driver', value);
  },
};

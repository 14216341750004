var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section confirm" },
    [
      _c("SectionTitle", { attrs: { underlined: true } }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "ButtonFixed",
        {
          attrs: {
            buttonClass: _vm.buttonClass,
            loading: _vm.buttonLoading,
            active: true,
            clickEvent: _vm.clickEvent,
            clickEventParams: _vm.clickEventParams
          }
        },
        [_vm._v(_vm._s(_vm.buttonLabel))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-67f4955e", { render: render, staticRenderFns: staticRenderFns })
  }
}
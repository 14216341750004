var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass:
        "top-0 fixed w-full bg-blue text-white flex z-10 font-bold text-xs border-b border-solid border-dull-blue"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "py-3 relative max-w-5xl xs:w-full micro:w-2/3 md:w-2/3 xs:px-6 micro:px-0 md:px-0 mx-auto flex justify-between items-center"
        },
        [
          _c(
            "div",
            { staticClass: "flex xs:w-auto tiny:w-1/3 items-center" },
            [
              _c(
                "transition",
                { attrs: { name: "slide-fade", appear: "", mode: "out-in" } },
                [
                  _vm.$route.path !== "/" &&
                  _vm.$root.$data.store.mode == "create"
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center justify-start hover:opacity-80 -ml-3",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.goBack($event)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "w-top-icon h-auto",
                            attrs: { src: "/img/back.svg", alt: "" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-white" }, [
                            _vm._v("Back")
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade", appear: "", mode: "out-in" } },
                [
                  _vm.$root.$data.store.mode !== "create" &&
                  _vm.$root.$data.store.mode !== "login"
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "flex justify-center md:mr-3 xs:mr-1 items-center text-white hover:opacity-80",
                          attrs: { href: "/my-account" }
                        },
                        [
                          _c("img", {
                            staticClass: "w-top-icon h-auto mr-2",
                            staticStyle: { width: "26px" },
                            attrs: { src: "/img/account.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "md:inline xs:hidden" }, [
                            _vm._v("My Account")
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade", appear: "", mode: "out-in" } },
                [
                  _vm.$root.$data.store.mode !== "create"
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "flex justify-center items-center text-white hover:opacity-80",
                          attrs: { href: "/" }
                        },
                        [
                          _c("img", {
                            staticClass:
                              "w-top-icon h-auto mr-2 tiny:inline xs:hidden",
                            staticStyle: { width: "26px" },
                            attrs: { src: "/img/plus.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "md:inline xs:hidden" }, [
                            _vm._v("Book now")
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex justify-end xs:w-auto tiny:w-1/3 items-center"
            },
            [
              _c(
                "transition",
                { attrs: { name: "slide-fade", appear: "", mode: "out-in" } },
                [
                  _vm.$root.$data.store.mode === "create"
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "flex justify-center items-center text-white hover:opacity-80",
                          attrs: { href: "/my-account" }
                        },
                        [
                          _c("img", {
                            staticClass: "w-top-icon h-auto",
                            staticStyle: { width: "26px" },
                            attrs: { src: "/img/account.svg" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "md:inline xs:hidden ml-2" },
                            [
                              _vm._v(
                                _vm._s(_vm.loggedIn ? "My Account" : "Login")
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade", appear: "", mode: "out-in" } },
                [
                  _vm.loggedIn
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "dusk-logout flex justify-end items-center text-white hover:opacity-80 tiny:ml-3 xs:ml-2 -mr-1",
                          attrs: { href: "/logout" }
                        },
                        [
                          _c("img", {
                            staticClass: "w-top-icon md:mr-2",
                            staticStyle: { width: "26px" },
                            attrs: { src: "/img/logout.svg", width: "100%" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "md:inline xs:hidden" }, [
                            _vm._v("Log out")
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "absolute center xs:w-auto tiny:w-1/3 p-3",
        staticStyle: { position: "absolute" }
      },
      [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            staticClass: "tiny:w-logo xs:w-logo-mobile mx-auto block h-auto",
            attrs: { src: "/img/logo.svg", alt: "" }
          })
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3c16ebe8", { render: render, staticRenderFns: staticRenderFns })
  }
}
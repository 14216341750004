var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button--fixed" }, [
    _c(
      "a",
      {
        class: [
          "btn",
          _vm.loading ? "btn--loading" : "",
          !_vm.active ? "btn--disabled" : "",
          _vm.buttonClass
        ],
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.clickEvent(_vm.clickEventParams)
          }
        }
      },
      [
        _c("span", { staticClass: "btn--text" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _vm._m(0)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "btn--loader" }, [
      _c("img", { attrs: { src: "/img/loader.svg", width: "40", alt: "" } })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5fb4cc00", { render: render, staticRenderFns: staticRenderFns })
  }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login wrapper section" },
    [
      !_vm.isAdmin
        ? _c("h1", { staticClass: "text-blue xs:text-lg micro:text-xl" }, [
            _vm._v(
              _vm._s(
                _vm.$root.$data.store.mode === "create"
                  ? "You already have an account"
                  : "Enter your password"
              )
            )
          ])
        : _c("h1", [_vm._v("Administration")]),
      _vm._v(" "),
      !_vm.isAdmin
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.checkLogin($event)
                }
              }
            },
            [
              _c("TextInput", [
                _c("div", { staticClass: "input-upper" }, [
                  _c("div", { staticClass: "input-upper--left" }, [
                    _vm._v("Password")
                  ])
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  class: [
                    "input--underlined dusk-password",
                    _vm.password !== null &&
                    _vm.password.length === 0 &&
                    _vm.formSubmitted
                      ? "input--invalid"
                      : ""
                  ],
                  attrs: { type: "password", value: "" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAdmin
        ? _c(
            "SimpleButton",
            {
              staticClass: "btn btn--grey btn--block btn--reset center-align",
              attrs: { clickEvent: _vm.reset }
            },
            [_vm._v("Reset Password")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ButtonFixed",
        {
          attrs: {
            loading: _vm.loading,
            active: _vm.nextButtonActive,
            clickEvent: _vm.checkLogin
          }
        },
        [_vm._v("Continue")]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-in", mode: "out-in" } }, [
        _vm.isAdmin === true
          ? _c("div", { staticClass: "users" }, [
              _c("p", { staticClass: "text-center" }, [
                _vm._v("Login as another user or press continue")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userSearch,
                    expression: "userSearch"
                  }
                ],
                staticClass: "input--underlined text-center",
                attrs: {
                  type: "text",
                  placeholder: "Search by last name, email or user id",
                  value: ""
                },
                domProps: { value: _vm.userSearch },
                on: {
                  keyup: _vm.searchUsers,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.userSearch = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.users, function(user) {
                  return _c("div", { key: user.id, staticClass: "user" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(user.first_name) +
                        " " +
                        _vm._s(user.last_name) +
                        " " +
                        _vm._s(user.email) +
                        "\n          "
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.loginInAs(user.id)
                          }
                        }
                      },
                      [_vm._v("Select")]
                    )
                  ])
                }),
                0
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-89869316", { render: render, staticRenderFns: staticRenderFns })
  }
}
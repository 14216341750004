<template>
    <div class="input--checkbox" @click.prevent="toggleCheck">
        <span :style="{opacity: display}">✓</span>
    </div>
</template>

<script>

export default {
    mounted () {
        this.display = this.default ? 1 : 0
    },
    data () {
        return {
            display: 'none'
        }
    },
    methods: {
        toggleCheck () {
            this.display = this.display === 1 ? 0 : 1
            this.$root.$data.store.setField(this.object, this.field, this.display === 1 ? true : false)
        }
    },
    props: ['default', 'object', 'field']
}
</script>

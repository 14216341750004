<template>
  <div
    class="dates wrapper section"
    v-show="isEntryDatePickerSetup && isExitDatePickerSetup"
  >
    <div :class="['datepicker-title', datepicker !== '' ? 'open' : '']">
      <h1 @click.prevent="hideDatePicker">{{ datePickerTitle }}</h1>
    </div>
    <h1 class="text-blue xs:text-lg micro:text-xl">Entry and Exit</h1>
    <p style="line-height: 30px">
      Choose an entry and exit date and time by clicking on them.
    </p>
    <TextInput slim="true">
      <div class="input-upper">
        <div class="input-upper--right">Entry time</div>
        <div class="input-upper--left">Car park entry</div>
      </div>
      <div :class="['input--datepicker', !isEntryDateValid ? 'invalid' : '']">
        <span class="input--datepicker__time">
          {{ moment(entryDate).format('HH:mm') }}
        </span>
        <span class="input--datepicker__date">
          {{ moment(entryDate).format('Do MMMM YYYY') }}
        </span>
        <span
          class="input--datepicker__hitbox"
          @click.prevent="showDatePicker('entry-time', 'Entry Time')"
        ></span>
        <input
          readonly
          type="text"
          :class="[
            'input--underlined',
            !isEntryDateValid ? 'input--invalid' : '',
          ]"
          id="entry-time"
        />
      </div>
    </TextInput>
    <TextInput slim="true">
      <div class="input-upper">
        <div class="input-upper--right">Exit time</div>
        <div class="input-upper--left">Car park exit</div>
      </div>
      <div :class="['input--datepicker', !isExitDateValid ? 'invalid' : '']">
        <span class="input--datepicker__time">
          {{ moment(exitDate).format('HH:mm') }}
        </span>
        <span class="input--datepicker__date">
          {{ moment(exitDate).format('Do MMMM YYYY') }}
        </span>
        <span
          class="input--datepicker__hitbox"
          @click.prevent="showDatePicker('exit-time', 'Exit Time')"
        ></span>
        <input
          readonly
          type="text"
          :class="[
            'input--underlined',
            !isExitDateValid ? 'input--invalid' : '',
          ]"
          id="exit-time"
        />
      </div>
    </TextInput>
    <TextInput slim="true" v-if="duration !== null">
      <div class="input-upper">
        <div class="input-upper--left">Reservation duration</div>
        <div class="clear"></div>
      </div>
      <p class="input--non-underlined">{{ duration }}</p>
    </TextInput>
    <TextInput slim="true">
      <div class="input-upper">
        <div class="input-upper--left">Price</div>
        <div class="clear"></div>
      </div>
      <p class="input--non-underlined" v-if="isCalculatingPrice">
        Calculating...
      </p>
      <p class="input--non-underlined" v-else>£{{ totalPrice }}</p>
    </TextInput>
    <p v-if="exceptionsMet" style="line-height: 30px; color: red">
      We have opening hours at Cheltenham of 09:00 - 18:00. When prebooking a
      parking space for our John Lewis &amp; Partners Car Park Cheltenham,
      please take note of the car parks opening hours. A charge of £50 is
      payable for out of hours release.
    </p>
    <p v-if="isCarParkFull" style="line-height: 30px; color: red">
      Sorry, there are no available spaces for the requested time, please select
      a different time.
    </p>
    <ButtonFixed
      dusk="continuebutton"
      :loading="isCalculatingPrice || isCheckingCapacity"
      :active="isDatesValid"
      :clickEvent="submit"
    >
      Continue
    </ButtonFixed>
  </div>
</template>

<script>
import ButtonFixed from '../objects/ButtonFixed.vue';
import SectionTitle from '../objects/SectionTitle.vue';
import TextInput from '../objects/TextInput.vue';
import jQuery from 'jquery';
import moment from 'moment-timezone';
import Vue from 'vue';

Vue.prototype.moment = moment;

export default {
  components: {
    ButtonFixed,
    SectionTitle,
    TextInput,
  },
  data() {
    return {
      isEntryDatePickerSetup: false,
      isExitDatePickerSetup: false,
      isCalculatingPrice: false,
      isCheckingCapacity: false,
      isCarParkFull: false,
      datepicker: '',
      datePickerTitle: '',
    };
  },
  computed: {
    carPark: {
      get() {
        return this.$root.$data.store.ticket.carPark;
      },
    },
    duration: {
      get() {
        if (!this.isEntryDateValid || !this.isExitDateValid) {
          return null;
        }

        let duration = '';
        let entryDate = moment(this.entryDate);
        let exitDate = moment(this.exitDate);
        const days = moment(exitDate).diff(entryDate, 'days');
        const hours = moment(exitDate).diff(entryDate, 'hours');

        if (days === 0) {
          duration = hours === 1 ? `${hours} hour` : `${hours} hours`;
        } else {
          let remainingHours = hours - days * 24;
          if (days === 1) {
            if (remainingHours === 0) {
              duration = `${days} day`;
            } else if (remainingHours === 1) {
              duration = `${days} day, ${remainingHours} hour`;
            } else {
              duration = `${days} day, ${remainingHours} hours`;
            }
          } else {
            if (remainingHours === 0) {
              duration = `${days} days`;
            } else if (remainingHours === 1) {
              duration = `${days} days, ${remainingHours} hour`;
            } else {
              duration = `${days} days, ${remainingHours} hours`;
            }
          }
        }

        return duration;
      },
    },
    totalPrice: {
      get() {
        var carParkId = this.carPark.id;
        var carParkIds = [5];
        if (carParkIds.includes(carParkId)) {
          return (this.$root.$data.store.ticket.price * 0.8).toFixed(2);
        }
        return this.$root.$data.store.ticket.price;
      },
      set(value) {
        this.$root.$data.store.setField('ticket', 'price', value);
      },
    },
    initialPrice: {
      get() {
        return this.$root.$data.store.ticket.initialPrice;
      },
      set(value) {
        this.$root.$data.store.setField('ticket', 'initialPrice', value);
      },
    },
    promotion: {
      get() {
        return this.$root.$data.store.ticket.promotion;
      },
      set(value) {
        this.$root.$data.store.setField('ticket', 'promotion', value);
      },
    },
    codeApplied: {
      get() {
        return this.$root.$data.store.promotion.codeApplied;
      },
      set(value) {
        this.$root.$data.store.setField('promotion', 'codeApplied', value);
      },
    },
    codeApplying: {
      get() {
        return this.$root.$data.store.promotion.codeApplying;
      },
      set(value) {
        this.$root.$data.store.setField('promotion', 'codeApplying', value);
      },
    },
    entryDate: {
      get() {
        return this.$root.$data.store.ticket.entryDate;
      },
      set(value) {
        this.$root.$data.store.setField('ticket', 'entryDate', value);
      },
    },
    exitDate: {
      get() {
        return this.$root.$data.store.ticket.exitDate;
      },
      set(value) {
        this.$root.$data.store.setField('ticket', 'exitDate', value);
      },
    },
    timeEntered: {
      get() {
        return this.$root.$data.store.state.timeEntered;
      },
      set(value) {
        this.$root.$data.store.setState('timeEntered', value);
      },
    },
    isEntryDateValid() {
      return (
        this.entryDate !== null &&
        moment(this.entryDate).isSameOrAfter(moment().startOf('hour'))
      );
    },
    isExitDateValid() {
      return (
        this.entryDate !== null &&
        this.exitDate !== null &&
        moment(this.exitDate).isAfter(moment(this.entryDate))
      );
    },
    isDatesValid() {
      return (
        this.isEntryDateValid &&
        this.isExitDateValid &&
        !this.isCalculatingPrice &&
        !this.isCheckingCapacity &&
        !this.isCarParkFull &&
        this.totalPrice !== '0.00'
      );
    },
    exceptionsMet() {
      //cheltenham
      if (this.carPark.id == 16) {
        if (
          moment(this.entryDate).isBefore(
            moment(this.entryDate).set('hour', 9)
          ) ||
          moment(this.entryDate).isAfter(
            moment(this.entryDate).set('hour', 18)
          ) ||
          moment(this.exitDate).isAfter(moment(this.exitDate).set('hour', 18))
        ) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    entryDate: function () {
      if (this.isEntryDateValid) {
        this.calculatePrice();
        this.checkCapacity();
      } else {
        this.$root.$data.store.showMessage(
          'Entry date must be after the current hour.'
        );
      }
    },
    exitDate: function () {
      if (this.isExitDateValid) {
        this.calculatePrice();
        this.checkCapacity();
      } else {
        this.$root.$data.store.showMessage(
          'Exit date must be after the entry date.'
        );
      }
    },
  },
  created() {
    this.prefillWithUrlParams();

    this.promotion = '';

    if (this.entryDate === null || this.exitDate === null) {
      this.setDefaultDates();
    }
  },
  mounted() {
    this.$root.$data.store.setObject('progress', 4);

    this.setupEntryDatePicker();
    this.setupExitDatePicker();
  },
  methods: {
    setDefaultDates() {
      this.entryDate = moment()
        .startOf('hour')
        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      this.exitDate = moment(this.entryDate)
        .add(1, 'hours')
        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    },
    calculatePrice() {
      this.isCalculatingPrice = true;

      this.$http
        .post(
          '/ticket/price',
          {
            startDate: this.entryDate,
            endDate: this.exitDate,
            carPark: this.carPark.id,
            ticket: this.$root.$data.store.ticket,
          },
          {
            headers: { 'X-CSRF-TOKEN': csrfToken },
          }
        )
        .then((price) => {
          this.totalPrice = price.body;
          this.initialPrice = price.body;
          this.codeApplied = false;
          this.codeApplying = false;

          this.isCalculatingPrice = false;
        })
        .catch((error) => {
          this.$root.$data.store.showMessage(
            'Sorry, there appears to be an issue. Please refresh the page and try again'
          );
          console.log(error);
        });
    },
    checkCapacity() {
      this.isCheckingCapacity = true;

      this.$http
        .post(
          `/capacity`,
          {
            startDate: this.entryDate,
            endDate: this.exitDate,
            carPark: this.carPark.id,
          },
          {
            headers: { 'X-CSRF-TOKEN': csrfToken },
          }
        )
        .then((response) => {
          if (response.body.full) {
            this.$root.$data.store.showMessage(
              'Sorry, there are no available spaces for the requested time, please select a different time.'
            );
            this.isCarParkFull = true;
          }

          this.isCheckingCapacity = false;
        })
        .catch((error) => {
          console.log('Could not determine capacity.');
        });
    },
    setupEntryDatePicker() {
      $ = jQuery.noConflict();

      const initialDate = moment().startOf('hour').toDate();

      $(document).ready(() => {
        $('#entry-time')
          .datetimepicker({
            format: 'yyyy-mm-dd hh:ii',
            maxView: 3,
            minView: 1,
            minuteStep: 0,
            startDate: initialDate,
            initialDate: initialDate,
          })
          .on('changeDate', (event) => {
            this.entryDate = moment(event.date)
              .startOf('hour')
              .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
            this.exitDate = moment(this.entryDate)
              .add(1, 'hours')
              .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

            this.datepicker = '';
            $('#entry-time').datetimepicker('hide');
          });

        this.isEntryDatePickerSetup = true;
      });
    },
    setupExitDatePicker() {
      $ = jQuery.noConflict();

      const initialDate = moment().add(1, 'hours').startOf('hour').toDate();

      $(document).ready(() => {
        $('#exit-time')
          .datetimepicker({
            format: 'yyyy-mm-dd hh:ii',
            maxView: 3,
            minView: 1,
            minuteStep: 0,
            startDate: initialDate,
            initialDate: initialDate,
          })
          .on('changeDate', (event) => {
            this.exitDate = moment(event.date)
              .startOf('hour')
              .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

            this.datepicker = '';
            $('#exit-time').datetimepicker('hide');
          });

        this.isExitDatePickerSetup = true;
      });
    },
    showDatePicker(picker, title) {
      $ = jQuery.noConflict();
      this.datepicker = '#' + picker;
      this.datePickerTitle = title;
      $(this.datepicker).datetimepicker('show');
    },
    hideDatePicker() {
      $ = jQuery.noConflict();
      $(this.datepicker).datetimepicker('hide');
      this.datepicker = '';
    },
    submit() {
      if (this.isDatesValid) {
        this.timeEntered = true;
        this.$router.push({ name: 'login' });
      }
    },
    prefillWithUrlParams() {
      if (this.entryDate === null && this.exitDate === null) {
        const urlParams = new URLSearchParams(window.location.search);

        if (typeof urlParams.get('entry_date') === 'string') {
          const entryDate = moment.tz(
            urlParams.get('entry_date'),
            'Europe/London'
          );

          if (entryDate.isValid()) {
            this.entryDate = entryDate
              .startOf('hour')
              .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
          }
        }

        if (typeof urlParams.get('exit_date') === 'string') {
          const exitDate = moment.tz(
            urlParams.get('exit_date'),
            'Europe/London'
          );

          if (exitDate.isValid()) {
            this.exitDate = exitDate
              .startOf('hour')
              .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
          }
        }
      }
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        {
          staticClass:
            "mt-header w-full border-t border-solid border-dull-blue mb-10"
        },
        [
          _c(
            "div",
            {
              class: ["datepicker-title", _vm.datepicker !== "" ? "open" : ""]
            },
            [_c("h1", [_vm._v(_vm._s(_vm.datePickerTitle))])]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex sm:flex-row xs:flex-col justify-between -mt-overlap"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "max-w-5xl micro:w-2/3 xs:w-auto mini:mx-6 xs:mx-0 bg-white sm:flex-row shadow-sm sm:p-10 xs:p-6 micro:mx-auto rounded-sm"
                },
                [
                  _c(
                    "div",
                    [
                      _vm.isEditing
                        ? [
                            _c("h2", { staticClass: "mt-0 text-lg" }, [
                              _vm._v("Edit Whitelist")
                            ]),
                            _vm._v(" "),
                            _c(
                              "form",
                              {
                                staticClass: "pb-10",
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.updateCitiPass($event)
                                  }
                                }
                              },
                              [
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Name")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.citiPassToUpdate.name,
                                      expression: "citiPassToUpdate.name"
                                    }
                                  ],
                                  staticClass:
                                    "text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4",
                                  domProps: {
                                    value: _vm.citiPassToUpdate.name
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.citiPassToUpdate,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Email (Optional)")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.citiPassToUpdate.email,
                                      expression: "citiPassToUpdate.email"
                                    }
                                  ],
                                  staticClass:
                                    "text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4",
                                  domProps: {
                                    value: _vm.citiPassToUpdate.email
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.citiPassToUpdate,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Registration")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.citiPassToUpdate.registration,
                                      expression:
                                        "citiPassToUpdate.registration"
                                    }
                                  ],
                                  staticClass:
                                    "text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 uppercase",
                                  domProps: {
                                    value: _vm.citiPassToUpdate.registration
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.citiPassToUpdate,
                                        "registration",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Valid From")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white",
                                    attrs: { id: "edit-entry-time" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleDatePicker(
                                          "edit-entry-time",
                                          "Valid From"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.momentDate(
                                            _vm.citiPassToUpdate.valid_from,
                                            "Do MMMM YYYY HH:mm"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Valid Till")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white",
                                    attrs: { id: "edit-exit-time" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleDatePicker(
                                          "edit-exit-time",
                                          "Valid Till"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.momentDate(
                                            _vm.citiPassToUpdate.valid_till,
                                            "Do MMMM YYYY HH:mm"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h3",
                                  { staticClass: "text-sm font-bold pb-3" },
                                  [_vm._v("Car Park")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.citiPassToUpdate.carpark,
                                        expression: "citiPassToUpdate.carpark"
                                      }
                                    ],
                                    staticClass: "w-full mb-3 block",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.citiPassToUpdate,
                                          "carpark",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", value: "" } },
                                      [_vm._v("Select a car park...")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.carParks, function(carPark) {
                                      return _c(
                                        "option",
                                        {
                                          key: carPark.id,
                                          domProps: { value: carPark.id }
                                        },
                                        [_vm._v(_vm._s(carPark.name))]
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-sm xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue hover:opacity-90",
                                    attrs: { type: "submit" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Update Entry\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "mt-2 text-sm xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-red-700 hover:opacity-90",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.cancelCitiPassEdit($event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Cancel Editing\n            "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : [
                            _c("h2", { staticClass: "mt-0 text-md" }, [
                              _vm._v("Add To Whitelist")
                            ]),
                            _vm._v(" "),
                            _c(
                              "form",
                              {
                                staticClass: "pb-10",
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.addCitiPass($event)
                                  }
                                }
                              },
                              [
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Name")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.citiPassToAdd.name,
                                      expression: "citiPassToAdd.name"
                                    }
                                  ],
                                  staticClass:
                                    "text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4",
                                  domProps: { value: _vm.citiPassToAdd.name },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.citiPassToAdd,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Email (Optional)")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.citiPassToAdd.email,
                                      expression: "citiPassToAdd.email"
                                    }
                                  ],
                                  staticClass:
                                    "text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4",
                                  domProps: { value: _vm.citiPassToAdd.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.citiPassToAdd,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Registration")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.citiPassToAdd.registration,
                                      expression: "citiPassToAdd.registration"
                                    }
                                  ],
                                  staticClass:
                                    "text-xs w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 uppercase",
                                  domProps: {
                                    value: _vm.citiPassToAdd.registration
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.citiPassToAdd,
                                        "registration",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Valid From")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white",
                                    attrs: { id: "edit-entry-time" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleDatePicker(
                                          "edit-entry-time",
                                          "Valid From"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.momentDate(
                                            _vm.citiPassToAdd.valid_from,
                                            "Do MMMM YYYY HH:mm"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("h3", { staticClass: "text-sm font-bold" }, [
                                  _vm._v("Valid Till")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "p-2 text-sm w-full border-t-0 border-l-0 border-r-0 border-solid border-b border-turquoise mb-4 text-blue bg-white",
                                    attrs: { id: "edit-exit-time" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleDatePicker(
                                          "edit-exit-time",
                                          "Valid Till"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.momentDate(
                                            _vm.citiPassToAdd.valid_till,
                                            "Do MMMM YYYY HH:mm"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h3",
                                  { staticClass: "text-sm font-bold pb-3" },
                                  [_vm._v("Car Park")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.citiPassToAdd.carpark,
                                        expression: "citiPassToAdd.carpark"
                                      }
                                    ],
                                    staticClass: "w-full mb-3 block",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.citiPassToAdd,
                                          "carpark",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", value: "" } },
                                      [_vm._v("Select a car park...")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.carParks, function(carPark) {
                                      return _c(
                                        "option",
                                        {
                                          key: carPark.id,
                                          domProps: { value: carPark.id }
                                        },
                                        [_vm._v(_vm._s(carPark.name))]
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-sm xs:w-full tracking-fat text-white p-3 rounded-sm font-bold block bg-blue hover:opacity-90",
                                    attrs: { type: "submit" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Add Entry\n            "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                      _vm._v(" "),
                      _c("h2", { staticClass: "xs:mb-4 text-md" }, [
                        _vm._v("Entries")
                      ]),
                      _vm._v(" "),
                      _vm.entries && _vm.entries.length > 0
                        ? [
                            _c(
                              "div",
                              {},
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                _vm._l(_vm.entries, function(entry) {
                                  return _c(
                                    "div",
                                    {
                                      key: entry.id,
                                      staticClass:
                                        "text-center flex flex-row border-t-0 border-l-0 border-r-0 border-solid border-b py-6 border-turquoise"
                                    },
                                    [
                                      _vm.entries.isEditing ? void 0 : _vm._e(),
                                      _vm._v(" "),
                                      void 0,
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1/6 text-xs leading-snug"
                                        },
                                        [_vm._v(_vm._s(entry.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1/6 px-2 text-xs leading-none"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(entry.registration) +
                                              "\n                "
                                          ),
                                          entry.email != "" &&
                                          entry.barcode &&
                                          entry.barcode != ""
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "text-tiny bg-gray-600 text-white ml-1 px-1 py-1 rounded-sm uppercase font-bold self-start hover:opacity-75",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.resendQR(entry)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  QR\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1/6 px-2 text-xs leading-snug"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.momentDate(
                                                  entry.valid_from,
                                                  "HH:mm Do MMMM YYYY"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1/6 px-2 text-xs leading-snug"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.momentDate(
                                                  entry.valid_till,
                                                  "HH:mm Do MMMM YYYY"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1/6 px-2 text-xs leading-snug"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                entry.car_park
                                                  ? entry.car_park.name
                                                  : "Car park not found"
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1/6 text-xs flex justify-between"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "text-micro mx-auto bg-blue text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start hover:opacity-75",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.editCitiPass(entry)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Edit\n                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "text-small-btn mx-auto bg-red-700 text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start hover:opacity-75",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeCitiPass(
                                                    entry.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Remove\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        : [_c("p", [_vm._v("No entries found.")])],
                      _vm._v(" "),
                      _c(
                        "div",
                        {},
                        [
                          _c("div", { staticClass: "flex flex-row" }, [
                            _vm._m(2),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-1/2" }, [
                              _c(
                                "form",
                                {
                                  staticClass:
                                    "xs:mb-4 sm:mb-4 md:mt-6 xs:mt-6 md:float-right",
                                  attrs: {
                                    action: "/citipass/export",
                                    method: "POST"
                                  }
                                },
                                [
                                  _c("input", {
                                    attrs: { type: "hidden", name: "_token" },
                                    domProps: { value: _vm.csrf }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "text-sm mx-auto bg-blue text-white px-2 py-1-h rounded-sm font-bold block self-start hover:opacity-75",
                                      attrs: { type: "submit" }
                                    },
                                    [_vm._v("Export Historical Logs")]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._m(3),
                          _vm._v(" "),
                          _vm._l(_vm.vehicleLogs, function(log, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "text-center flex flex-row border-t-0 border-l-0 border-r-0 border-solid border-b py-6 border-turquoise"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-1/4 px-2 text-xs leading-snug"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(log.movement) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-1/4 px-2 text-xs leading-snug"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.momentDate(
                                            log.captured_at,
                                            "Do MMM YYYY - HH:mm"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-1/4 px-2 text-xs leading-snug"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(log.vehicle) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-1/4 px-2 text-xs leading-snug"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(log.car_park) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    2
                  )
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-blue w-full pb-50" }, [
      _c(
        "div",
        {
          staticClass:
            "max-w-5xl sm:w-2/3 xs:w-auto xs:mx-6 sm:mx-auto flex sm:flex-row xs:flex-col justify-between items-center bg-blue xs:mb-16 sm:mb-20 py-8"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col xs:justify-center sm:justify-start xs:mb-4 sm:mb-0"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "text-sm text-white font-bold block flex items-center mb-2 xs:justify-center sm:justify-start",
                  attrs: { href: "/my-account" }
                },
                [
                  _c("img", {
                    staticClass: "w-2 h-auto mr-1",
                    attrs: { src: "/img/small-chevron-white.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-meta mr-1" }, [
                    _vm._v("Back to my account")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "text-white font-bold text-lg m-0 text-center" },
                [_vm._v("\n          CitiPass Administration\n        ")]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "flex sm:flex-row xs:flex-col justify-between xs:w-full sm:w-auto flex sm:flex-row xs:flex-col justify-between"
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex flex-row text-center border-l-0 border-r-0 border-solid border-t border-b py-3 border-turquoise"
      },
      [
        _c("div", { staticClass: "w-1/6 font-bold text-sm" }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/6 font-bold px-2 text-sm" }, [
          _vm._v("Registration")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/6 font-bold px-2 text-sm" }, [
          _vm._v("Valid From")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/6 font-bold px-2 text-sm" }, [
          _vm._v("Valid Till")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/6 font-bold px-2 text-sm" }, [
          _vm._v("Car Park")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/6 font-bold text-sm" }, [
          _vm._v("Manage")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-1/2" }, [
      _c("h2", { staticClass: "xs:mb-4 sm:mb-4 md:mt-6 xs:mt-6 text-md" }, [
        _vm._v("\n                Vehicle Logs\n              ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex flex-row text-center border-l-0 border-r-0 border-solid border-t border-b py-3 border-turquoise"
      },
      [
        _c("div", { staticClass: "w-1/4 font-bold text-xs" }, [
          _vm._v("Movement")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/4 font-bold px-2 text-xs" }, [
          _vm._v("Time")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/4 font-bold px-2 text-xs" }, [
          _vm._v("Registration")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/4 font-bold px-2 text-xs" }, [
          _vm._v("Car Park")
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-729d2766", { render: render, staticRenderFns: staticRenderFns })
  }
}
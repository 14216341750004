<template>
  <div class="login wrapper section">
    <h1
      class="text-blue xs:text-lg micro:text-xl"
    >{{ $root.$data.store.mode === 'create' ? 'Create an account or login' : 'Login to your account' }}</h1>
    <transition name="fade-in" mode="out-in">
      <form @submit.prevent="checkLogin" v-show="loggedIn === false">
        <TextInput :slim="true">
          <div class="input-upper">
            <div class="input-upper--left">Email address</div>
          </div>
          <input
            type="text"
            :class="['input--underlined dusk-email', 'margin-bottom-30', !isEmail ? 'input--invalid' : '']"
            value
            autofocus
            tabindex="1"
            v-model="email"
            :style="{textTransform: 'lowercase'}"
          />
        </TextInput>
      </form>
    </transition>
    <transition name="fade-in" mode="out-in">
      <p
        class="slim text-center"
        v-if="loggedIn === true"
      >You are logged in as {{ this.$root.$data.store.customer.first_name }} {{ this.$root.$data.store.customer.last_name }}!</p>
    </transition>
    <transition name="fade-in" mode="out-in">
      <a
        v-if="loggedIn === true"
        href="#"
        class="btn btn--grey btn--block btn--reset"
        @click.prevent="logout"
      >Login as another user</a>
    </transition>

    <ButtonFixed :loading="loading" :active="nextButtonActive" :clickEvent="checkLogin">Continue</ButtonFixed>
  </div>
</template>

<script>
import SectionTitle from "../objects/SectionTitle.vue";
import TextInput from "../objects/TextInput.vue";
import ButtonFixed from "../objects/ButtonFixed.vue";
import AutoFill from "../../store/autofill.js";
import jQuery from "jquery";

export default {
  data() {
    return {
      formSubmitted: false,
      loading: false,
      socialButtonsLoaded: false
    };
  },
  mounted() {
    if (this.$root.$data.store.autoFill) this.autoFill();

    this.$root.$data.store.setObject("progress", 5);

    $ = jQuery.noConflict();
  },
  computed: {
    nextButtonActive: {
      get() {
        if (!this.email || this.email === "") {
          return false;
        }

        return true;
      }
    },
    email: {
      get() {
        return this.$root.$data.store.customer.email;
      },
      set(value) {
        this.$root.$data.store.setField(
          "customer",
          "email",
          value.toLowerCase()
        );
      }
    },
    isEmail() {
      if (!this.formSubmitted) return true;
      return (
        this.email && this.email.length > 0 && this.validateEmail(this.email)
      );
    },
    loggedIn: {
      get() {
        return this.$root.$data.store.state.loggedIn;
      },
      set(value) {
        this.$root.$data.store.setState("loggedIn", value);
      }
    },
    isAdmin: {
      get() {
        return this.$root.$data.store.state.isAdmin;
      },
      set(value) {
        this.$root.$data.store.setState("isAdmin", value);
      }
    },
    ticketType() {
      return this.$root.$data.store.state.ticketType;
    }
  },
  components: {
    SectionTitle,
    TextInput,
    ButtonFixed
  },
  methods: {
    checkLogin() {
      if (this.loggedIn) {
        if (this.isAdmin) {
          this.$router.push({ name: "password" });
        } else {
          if (this.ticketType === "prebook") {
            this.$router.push({ name: "payment" });
          } else {
            this.$router.push({ name: "registration" });
          }
        }
        return;
      }

      this.formSubmitted = true;
      this.loading = true;

      if (this.email && this.email.length === 0) {
        this.$root.$data.store.showMessage("Please enter an email");
        this.loading = false;
        return;
      } else if (!this.validateEmail(this.email)) {
        this.$root.$data.store.showMessage(
          "Please enter a valid email address"
        );
        this.loading = false;
        return;
      }

      this.$http
        .post(
          "/users/unique_email",
          {
            email: this.email
          },
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            if (this.$root.$data.store.mode === "login") {
              if (response.body.unique !== true) {
                this.$router.push({ name: "password" });
              } else {
                this.loading = false;
                this.$root.$data.store.showMessage(
                  "We cannot recognise your email. Please try again."
                );
              }
            } else {
              if (response.body.unique !== true) {
                this.$router.push({ name: "password" });
              } else {
                this.$router.push({ name: "accountDetails" });
              }
            }
          },
          err => {
            this.$root.$data.store.showMessage(err.body.error);
            this.loading = false;
          }
        );
    },
    logout() {
      this.$http
        .post(
          "/logout/ajax",
          {},
          {
            headers: { "X-CSRF-TOKEN": csrfToken }
          }
        )
        .then(
          response => {
            this.email = "";
            this.loggedIn = false;
          },
          err => {}
        );
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    autoFill() {
      this.$root.$data.store.setField(
        "customer",
        "email",
        AutoFill.customer.email
      );
    }
  }
};
</script>

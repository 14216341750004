var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["popup", _vm.toggle] }, [
    _c("div", {
      staticClass: "popup--bg",
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.close($event)
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "popup--wrapper" }, [
      _c("div", { staticClass: "popup--header" }, [
        _c("h2", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "popup--exit",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.close($event)
              }
            }
          },
          [
            _c("img", {
              attrs: { src: "/img/cross.svg", width: "20", alt: "" }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup--body" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ad5976a", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <header
    class="top-0 fixed w-full bg-blue text-white flex z-10 font-bold text-xs border-b border-solid border-dull-blue"
  >
    <div
      class="py-3 relative max-w-5xl xs:w-full micro:w-2/3 md:w-2/3 xs:px-6 micro:px-0 md:px-0 mx-auto flex justify-between items-center"
    >
      <div class="flex xs:w-auto tiny:w-1/3 items-center">
        <transition name="slide-fade" appear mode="out-in">
          <a
            href="#"
            class="flex items-center justify-start hover:opacity-80 -ml-3"
            v-if="$route.path !== '/' && $root.$data.store.mode == 'create'"
            @click.prevent="goBack"
          >
            <img class="w-top-icon h-auto" src="/img/back.svg" alt />
            <span class="text-white">Back</span>
          </a>
        </transition>
        <transition name="slide-fade" appear mode="out-in">
          <a
            v-if="$root.$data.store.mode !== 'create' && $root.$data.store.mode !== 'login'"
            href="/my-account"
            class="flex justify-center md:mr-3 xs:mr-1 items-center text-white hover:opacity-80"
          >
            <img src="/img/account.svg" class="w-top-icon h-auto mr-2" style="
    width: 26px;
" />
            <span class="md:inline xs:hidden">My Account</span>
          </a>
        </transition>
        <transition name="slide-fade" appear mode="out-in">
          <a
            v-if="$root.$data.store.mode !== 'create'"
            href="/"
            class="flex justify-center items-center text-white hover:opacity-80"
          >
            <img
              src="/img/plus.svg"
              class="w-top-icon h-auto mr-2 tiny:inline xs:hidden"
              style="
    width: 26px;
"
            />
            <span class="md:inline xs:hidden">Book now</span>
          </a>
        </transition>
      </div>
      <div class="absolute center xs:w-auto tiny:w-1/3 p-3" style="position: absolute;">
        <a href="/">
          <img src="/img/logo.svg" alt class="tiny:w-logo xs:w-logo-mobile mx-auto block h-auto" />
        </a>
      </div>
      <div class="flex justify-end xs:w-auto tiny:w-1/3 items-center">
        <transition name="slide-fade" appear mode="out-in">
          <a
            v-if="$root.$data.store.mode === 'create'"
            href="/my-account"
            class="flex justify-center items-center text-white hover:opacity-80"
          >
            <img src="/img/account.svg" class="w-top-icon h-auto" style="
    width: 26px;
" />
            <span class="md:inline xs:hidden ml-2">{{ loggedIn ? 'My Account' : 'Login' }}</span>
          </a>
        </transition>
        <transition name="slide-fade" appear mode="out-in">
          <a
            v-if="loggedIn"
            href="/logout"
            class="dusk-logout flex justify-end items-center text-white hover:opacity-80 tiny:ml-3 xs:ml-2 -mr-1"
          >
            <img
              src="/img/logout.svg"
              width="100%"
              class="w-top-icon md:mr-2"
              style="
    width: 26px;
"
            />
            <span class="md:inline xs:hidden">Log out</span>
          </a>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  mounted() {
    this.isLoggedIn();
  },
  data() {
    return {
      mobileMenuOpen: false,
      showMenu: false
    };
  },
  computed: {
    loggedIn: {
      get() {
        return this.$root.$data.store.state.loggedIn;
      },
      set(value) {
        this.$root.$data.store.setState("loggedIn", value);
      }
    },
    isAdmin: {
      get() {
        return this.$root.$data.store.state.isAdmin;
      },
      set(value) {
        this.$root.$data.store.setState("isAdmin", value);
      }
    }
  },
  methods: {
    goBack() {
      if (
        this.$route.path === "/dates" &&
        this.$root.$children[3].$data.datepicker != ""
      ) {
        this.$root.$children[3].hideDatePicker();
        return;
      }

      const root =
        this.$root.$data.store.mode != "mobile-ticket" ? "/" : "/payment";

      window.history.length > 1 ? this.$router.go(-1) : this.$router.push(root);
    },
    isLoggedIn() {
      if (this.loggedIn) return;

      this.$http.get("/my-account/check-login").then(
        response => {
          if (!response.body) response.body = response.bodyText;
          if (response.body != "NOT_LOGGED_IN") {
            this.$root.$data.store.setObject(
              "customer",
              Object.assign(this.$root.$data.store.customer, response.body)
            );
            this.isAdmin = response.body.isAdmin;
            this.loggedIn = true;
          } else {
            this.loggedIn = false;
          }

          this.showMenu = true;
        },
        response => {
          this.loggedIn = false;
          this.showMenu = true;
        }
      );
    }
  },
  watch: {
    $route(to, from) {
      this.isLoggedIn();
    }
  }
};
</script>

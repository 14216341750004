<template>
    <a :class="className" @click.prevent="clickEvent(clickEventParams)" href="#">
        <slot></slot>
    </a>
</template>

<script>

export default {
    data () {
        return {
        }
    },
    props: {
        className: {
            type: String,
            required: false
        },
        clickEvent: {
            type: Function
        },
        clickEventParams: [Object, Array, String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

import Loader from './components/objects/Loader.vue';
import Progress from './components/objects/Progress.vue';
import AccountDetails from './components/sections/AccountDetails.vue';
import Account from './components/sections/AccountNew.vue';
import AdditionalRegNumber from './components/sections/AdditionalRegNumber.vue';
import Alert from './components/sections/Alert.vue';
import AppHeader from './components/sections/AppHeaderNew.vue';
// import AppHeader from "./components/sections/AppHeader.vue"
import CarPark from './components/sections/CarPark.vue';
import CitiPass from './components/sections/CitiPass.vue';
import Confirmation from './components/sections/Confirmation.vue';
import Dates from './components/sections/Date.vue';
import Driver from './components/sections/Driver.vue';
import Drivers from './components/sections/Drivers.vue';
import Locations from './components/sections/Location.vue';
import Login from './components/sections/Login.vue';
import NewPayment from './components/sections/NewPayment.vue';
import Password from './components/sections/Password.vue';
import Payment from './components/sections/Payment.vue';
import RegNumber from './components/sections/RegNumber.vue';
import ResetPassword from './components/sections/ResetPassword.vue';
import Seasons from './components/sections/Season.vue';
// import SeasonTicket from "./components/sections/SeasonTicket.vue"
import SeasonTicket from './components/sections/SeasonTicketNew.vue';
import StartDate from './components/sections/StartDate.vue';
import Ticket from './components/sections/TicketNew.vue';
// import Ticket from "./components/sections/Ticket.vue"
import TicketType from './components/sections/Type.vue';
import './lib/bootstrap-datetimepicker.min.js';
import store from './store/store.js';
import jquery from 'jquery';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
Vue.use(VueResource);

Vue.component(AppHeader);

const routes = [
  { path: '/', component: TicketType, name: 'ticketType' },
  { path: '/location', component: Locations, name: 'location' },
  { path: '/location/:location/car-park', component: CarPark, name: 'carPark' },
  { path: '/registration', component: RegNumber, name: 'registration' },
  {
    path: '/account-details',
    component: AccountDetails,
    name: 'accountDetails',
  },
  { path: '/account', component: Account, name: 'account' },
  { path: '/citipass', component: CitiPass, name: 'citipass' },
  { path: '/confirmation', component: Confirmation, name: 'confirmation' },
  { path: '/dates', component: Dates, name: 'dates' },
  { path: '/login', component: Login, name: 'login' },
  { path: '/password', component: Password, name: 'password' },
  { path: '/payment', component: Payment, name: 'payment' },
  { path: '/ticket', component: Ticket, name: 'ticket' },
  { path: '/season-ticket', component: SeasonTicket, name: 'seasonTicket' },
  { path: '/reset-password', component: ResetPassword, name: 'reset' },
  { path: '/add-payment', component: NewPayment, name: 'addPayment' },
  { path: '/seasons', component: Seasons, name: 'seasons' },
  { path: '/drivers', component: Drivers, name: 'drivers' },
  { path: '/start-date', component: StartDate, name: 'startDate' },
  {
    path: '/additional-registration',
    component: AdditionalRegNumber,
    name: 'additionalRegistration',
  },
  { path: '/driver', component: Driver, name: 'driver' },
];

const router = new VueRouter({
  routes,
  mode: 'abstract',
});

Vue.use(VueAnalytics, {
  id: 'UA-32767308-11',
  router,
  ecommerce: {
    enabled: true,
  },
});

const app = new Vue({
  mounted() {
    this.loadModeRoute();
  },
  router,
  data: {
    store,
    transitionName: 'fade-in',
  },
  computed: {
    loading() {
      return this.$root.$data.store.state.loading;
    },
    alertColor() {
      return this.$root.$data.store.state.alertColor;
    },
  },
  components: {
    appheader: AppHeader,
    loader: Loader,
    progressbar: Progress,
    alert: Alert,
  },
  methods: {
    loadModeRoute() {
      switch (this.store.mode) {
        case 'create':
          this.$router.push('/');
          break;
        case 'login':
          this.$router.push('/login');
          break;
        case 'account':
          this.$router.push('/account');
          break;
        case 'ticket':
          this.$router.push('/ticket');
          break;
        case 'season-ticket':
          this.$router.push('/season-ticket');
          break;
        case 'citipass':
          this.$router.push('/citipass');
          break;
      }
    },
  },
}).$mount('#app');

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.seasonTicket && !_vm.loading
    ? _c(
        "div",
        {
          staticClass:
            "mt-header w-full border-t border-solid border-dull-blue mb-10 dusk-season-ticket"
        },
        [
          _c("div", { staticClass: "bg-blue w-full pb-50" }, [
            _c(
              "div",
              {
                staticClass:
                  "max-w-5xl sm:w-2/3 xs:w-auto xs:mx-6 sm:mx-auto flex sm:flex-row xs:flex-col justify-between items-center bg-blue xs:mb-16 sm:mb-20 py-8"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col xs:justify-center sm:justify-start xs:mb-4 sm:mb-0"
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm.seasonTicket.season
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "text-white font-bold text-lg m-0 text-center"
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.seasonTicket.season.title) +
                                " Season Ticket " +
                                _vm._s(
                                  _vm.seasonTicket.price == "0"
                                    ? ""
                                    : " - £" +
                                        parseInt(
                                          _vm.seasonTicket.price
                                        ).toFixed(2)
                                )
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex sm:flex-row xs:flex-col justify-between xs:w-full sm:w-auto flex sm:flex-row xs:flex-col justify-between"
                  },
                  [
                    _vm.beforeStartDate
                      ? void 0
                      : _vm.seasonTicket.status === "active"
                      ? [
                          _vm.seasonTicket.payment_status === "unauthorized"
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "xs:hidden sm:inline bg-active-green hover:opacity-90 rounded-sm font-bold text-white text-xs xs:px-5 sm:px-5 xs:py-2 sm:py-5 tracking-fat sm:px-3 sm:py-3 xs:mb-2 sm:mb-0",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.retrievePaymentAuthorization(
                                        $event
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Authorise Renewal")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "dusk-cancel-renewal xs:hidden sm:inline text-white hover:opacity-90 xs:px-5 sm:px-5 xs:py-3 sm:py-5 rounded-sm text-xs font-bold border-white bg-red-600 xs:ml-0 sm:ml-2 tracking-fat",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.cancel($event)
                                }
                              }
                            },
                            [_vm._v("Cancel Renewal")]
                          )
                        ]
                      : [
                          !_vm.pastRenewal
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "dusk-activate-renewal xs:hidden sm:inline text-white hover:opacity-90 xs:px-5 sm:px-5 xs:py-2 sm:py-5 rounded-sm text-xs font-bold bg-active-green hover:opacity-90 ml-2 tracking-fat",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.reactivate($event)
                                    }
                                  }
                                },
                                [_vm._v("Activate Renewal")]
                              )
                            : _vm._e()
                        ]
                  ],
                  2
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex sm:flex-row xs:flex-col justify-between -mt-overlap"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "max-w-5xl micro:w-2/3 xs:w-auto mini:mx-6 xs:mx-0 bg-white sm:flex-row xs:flex xs:flex-col-reverse shadow-sm sm:p-10 xs:p-6 micro:mx-auto rounded-sm"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "sm:w-4/12 xs:w-full sm:mr-10 xs:mx-auto xs:mt-6 sm:mt-0 meta:block xs:hidden"
                    },
                    [
                      _vm.seasonTicket.barcode
                        ? [
                            _c("img", {
                              class: [
                                "w-full h-auto p-6 border border-solid border-gray-300 block",
                                !_vm.pastRenewal ? "opacity-100" : "opacity-25"
                              ],
                              attrs: {
                                src: "/qr-code/" + _vm.seasonTicket.barcode,
                                alt: ""
                              }
                            })
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.pastRenewal
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "ticket--passbook text-center m-auto block",
                              attrs: {
                                href:
                                  "/season-ticket/passbook/" +
                                  _vm.seasonTicket.id
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "pt-4",
                                attrs: {
                                  src: "/img/apple-wallet.svg",
                                  width: "200",
                                  alt: ""
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "xs:w-full sm:w-8/12 text-sm" },
                    [
                      _c("div", { staticClass: "flex items-center mb-1" }, [
                        _vm.beforeStartDate
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "p-2 text-white font-bold uppercase text-tiny mr-2 rounded-sm bg-turquoise"
                              },
                              [
                                _vm._v(
                                  "Starts On " +
                                    _vm._s(
                                      _vm.momentDate(
                                        _vm.seasonTicket.order_date,
                                        "Do MMMM YYYY"
                                      )
                                    )
                                )
                              ]
                            )
                          : _c(
                              "div",
                              {
                                class: [
                                  "p-2 font-bold uppercase text-tiny mr-2 rounded-sm",
                                  _vm.statusColor,
                                  _vm.status === "Active" ||
                                  _vm.status === "Expired"
                                    ? "text-white"
                                    : "text-black"
                                ]
                              },
                              [_vm._v(_vm._s(_vm.status))]
                            ),
                        _vm._v(" "),
                        !_vm.beforeStartDate
                          ? _c("div", { staticClass: "text-xs" }, [
                              _vm._v(
                                _vm._s(_vm.dateStatus) +
                                  " " +
                                  _vm._s(
                                    _vm.momentDate(
                                      _vm.seasonTicket.renewal_date,
                                      "Do MMMM YYYY"
                                    )
                                  )
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.seasonTicket.car_park
                        ? _c(
                            "h1",
                            {
                              staticClass:
                                "mb-1 m-0 xs:text-lg tiny:text-lgr text-left"
                            },
                            [_vm._v(_vm._s(_vm.seasonTicket.car_park.name))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex mb-2 items-center" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _vm.seasonTicket.car_park
                          ? _c(
                              "p",
                              {
                                staticClass: "leading-tighter text-xs m-0 block"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.seasonTicket.car_park.address_1) +
                                    ", " +
                                    _vm._s(
                                      _vm.seasonTicket.car_park.address_2
                                    ) +
                                    ", " +
                                    _vm._s(_vm.seasonTicket.car_park.postcode)
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-4 m-0 p-0" }),
                      _vm._v(" "),
                      _vm.seasonTicket.car_park.uses_zatpark != 1 &&
                      _vm.seasonTicket.car_park.uses_license != 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "sm:w-4/12 xs:w-full sm:mr-10 xs:mx-auto xs:mt-6 sm:mt-0 meta:hidden xs:block"
                            },
                            [
                              _c("div", {
                                class: [
                                  "w-full h-auto p-6 border border-solid border-gray-300 block",
                                  !_vm.pastRenewal
                                    ? "opacity-100"
                                    : "opacity-25"
                                ],
                                domProps: { innerHTML: _vm._s(_vm.qrCode) }
                              }),
                              _vm._v(" "),
                              !_vm.pastRenewal
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "ticket--passbook text-center mx-auto block mb-6",
                                      attrs: {
                                        href:
                                          "/season-ticket/passbook/" +
                                          _vm.seasonTicket.id
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "pt-4",
                                        attrs: {
                                          src: "/img/apple-wallet.svg",
                                          width: "200",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "h3",
                        { staticClass: "mb-2 m-0 p-0 font-bold text-xs" },
                        [_vm._v("Registrations")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.seasonTicket.registrations, function(
                        registration,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: registration.id,
                            staticClass:
                              "flex xs:flex-col tiny:flex-row justify-between items-center mb-1 bg-gray-200 p-2 xs:w-full lg:w-1/2 rounded-sm"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center xs:flex-col tiny:flex-row xs:w-full tiny:w-auto"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "xs:w-full meta:w-plate xs:mb-1 meta:mb-0 border-1 bg-yolk text-center font-bold text-black xs:text-xs meta:text-micro tracking-normal meta:p-1 xs:p-3 rounded-sm border-solid border-yolk-dark"
                                  },
                                  [_vm._v(_vm._s(registration.registration))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "meta:mt-0 xs:mt-2 meta:text-meta xs:text-xs ml-2 xs:mb-2 meta:mb-0 font-bold"
                                  },
                                  [_vm._v("Vehicle #" + _vm._s(index + 1))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "xs:w-full xs:block tiny:w-auto xs:inline"
                              },
                              [
                                _vm.seasonTicket.registrations.length !== 1
                                  ? _c(
                                      "button",
                                      {
                                        class: [
                                          "dusk-remove-" +
                                            index +
                                            " leading-normal rounded-sm px-2 py-1 bg-red-600 text-white font-bold uppercase hover:opacity-90 xs:w-full xs:block tiny:w-auto xs:inline xs:text-meta tiny:text-tiny"
                                        ],
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.openRemoveRegistrationPopup(
                                              registration.registration
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Remove")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.seasonTicket.registrations.length < 2 &&
                      _vm.seasonTicket.status === "active"
                        ? _c(
                            "button",
                            {
                              class: [
                                "dusk-add-registration-open-popup p-2 bg-blue text-white xs:w-full sm:w-1/2 rounded-sm text-tiny font-bold uppercase",
                                _vm.additionalRegistrationAllowed
                                  ? "opacity-100"
                                  : "opacity-50 pointer-events-none"
                              ],
                              attrs: {
                                type: "button",
                                disabled: !_vm.additionalRegistrationAllowed
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.addingRegistration = true
                                }
                              }
                            },
                            [_vm._v("Add Registration")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "m-0 p-0 text-micro mt-2 text-gray-500 font-bold"
                        },
                        [
                          _vm._v(
                            "* You may only add an additional registration every 14 days."
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.seasonTicket.status === "active" &&
                      _vm.seasonTicket.card
                        ? _c(
                            "h3",
                            { staticClass: "font-bold mt-4 mb-2 text-xs" },
                            [_vm._v("Payment method")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seasonTicket.status === "active" &&
                      _vm.seasonTicket.card
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "lg:w-6/12 xs:w-full flex justify-between items-center mb-2 bg-gray-400 p-3 rounded-sm"
                            },
                            [
                              _vm.seasonTicket.card
                                ? _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c("img", {
                                        staticClass: "w-8 mr-2",
                                        attrs: {
                                          src:
                                            "/img/" +
                                            _vm.seasonTicket.card.brand
                                              .replace("_", "-")
                                              .toLowerCase() +
                                            ".svg",
                                          alt: ""
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "font-bold text-xs" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "capitalize" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.seasonTicket.card.brand
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n              ending in\n              " +
                                              _vm._s(
                                                _vm.seasonTicket.card.last4
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  class: [
                                    _vm.loading ? "opacity-50" : "",
                                    "dusk-change-payment leading-normal rounded-sm px-2 py-1 bg-blue text-white text-tiny font-bold uppercase hover:opacity-90"
                                  ],
                                  on: {
                                    click: function($event) {
                                      return _vm.goToPayment()
                                    }
                                  }
                                },
                                [_vm._v("Change")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seasonTicket.status === "active" &&
                      _vm.seasonTicket.payment_status === "authorized" &&
                      _vm.seasonTicket.card
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-meta text-gray-500 mb-4 leading-normal"
                            },
                            [
                              _vm._v(
                                "Renewal payment will processed at 12am on the " +
                                  _vm._s(
                                    _vm.momentDate(
                                      _vm.seasonTicket.renewal_date,
                                      "Do MMMM YYYY"
                                    )
                                  ) +
                                  ". You may receive a notification from your bank 5 days beforehand saying we have taken payment, but this is only an authorisation even if your bank says otherwise. We only capture payments on the specified renewal date."
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seasonTicket.promotion
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "leading-tight text-xs block flex tiny:mb-0 xs:mb-5 mt-4"
                            },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "leading-tight text-xs m-0 block -mt-nudge"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-2 m-0 font-bold text-xs"
                                    },
                                    [_vm._v("Promotion")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "m-0 p-0 text-meta" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.seasonTicket.promotion.code.toUpperCase()
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.beforeStartDate
                        ? void 0
                        : _vm.seasonTicket.status === "active"
                        ? [
                            _vm.seasonTicket.payment_status === "unauthorized"
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "xs:inline sm:hidden bg-active-green hover:opacity-90 w-full rounded-sm font-bold text-white text-xs xs:px-5 sm:px-5 xs:py-3 sm:py-5 tracking-fat sm:px-3 sm:py-3 xs:mb-2 sm:mb-0",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.retrievePaymentAuthorization(
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Authorise Renewal")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "xs:inline sm:hidden text-white hover:opacity-90 block w-full xs:px-5 sm:px-5 xs:py-3 sm:py-5 rounded-sm text-xs font-bold border-white bg-red-600 tracking-fat",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.cancel($event)
                                  }
                                }
                              },
                              [_vm._v("Cancel Renewal")]
                            )
                          ]
                        : [
                            !_vm.pastRenewal
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "mt-6 xs:inline sm:hidden text-white hover:opacity-90 w-full block xs:px-5 sm:px-5 xs:py-3 sm:py-5 rounded-sm text-xs font-bold bg-active-green tracking-fat",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.reactivate($event)
                                      }
                                    }
                                  },
                                  [_vm._v("Activate Renewal")]
                                )
                              : _vm._e()
                          ]
                    ],
                    2
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.seasonTicket.card
            ? _c(
                "div",
                {
                  staticClass:
                    "xs:mt-6 tiny:mt-10 lg:w-4/12 sm:w-6/12 xs:w-full xs:p-6 sm:p-0 mx-auto"
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "border-b-2 border-blue border-solid pb-3 m-0 text-lg"
                    },
                    [_vm._v("Invoices")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.seasonTicket.payments, function(invoice) {
                    return _c(
                      "div",
                      {
                        key: invoice.id,
                        staticClass:
                          "flex justify-between border-b border-gray-200 border-solid py-3 items-center text-xs"
                      },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mr-4 font-bold xs:text-meta tiny:text-xs"
                            },
                            [
                              _vm._v(
                                "£" +
                                  _vm._s(parseInt(invoice.amount).toFixed(2))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "xs:text-meta tiny:text-xs" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.momentDate(
                                    invoice.period_start,
                                    "Do MMMM YYYY"
                                  )
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "bg-blue text-tiny text-white px-2 py-1-h rounded-sm uppercase font-bold block self-start ml-2 hover:opacity-75",
                            attrs: {
                              href: invoice.invoice_url,
                              target: "_blank"
                            }
                          },
                          [_vm._v("Download")]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { mode: "out-in", name: "fade-in" } },
            [
              _vm.addingRegistration
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "fixed top-0 left-0 w-full h-full z-1 fixed flex justify-center items-center"
                      },
                      [
                        _c("div", {
                          staticClass:
                            "fixed top-0 left-0 w-full h-full bg-white opacity-75",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.closePopup($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "scale-up-center-slow border-2 border-solid border-dull-blue elative z-10 bg-blue rounded-sm text-white shadow-lg",
                            staticStyle: { width: "355px" }
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "m-0 p-0 text-white p-3 border-b border-solid border-dull-blue text-center"
                              },
                              [_vm._v("Add Registration")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newRegistrationDisplay,
                                    expression: "newRegistrationDisplay"
                                  }
                                ],
                                staticClass:
                                  "dusk-add-registration-input w-full mx-auto xs:mb-1 meta:mb-0 border-1 bg-yolk text-center font-bold text-black tracking-normal p-3 rounded-sm border-solid border-yolk-dark uppercase text-sm",
                                attrs: { autofocus: "" },
                                domProps: { value: _vm.newRegistrationDisplay },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.newRegistrationDisplay =
                                      $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.seasonTicket.registrations.length === 1
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-white text-micro leading-tight mt-2 font-bold"
                                    },
                                    [
                                      _vm._v(
                                        "*Please note that once you add this registration, you may not add another for 14 days."
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-4" }, [
                              _c(
                                "button",
                                {
                                  class: [
                                    "dusk-add-registration-button w-full font-bold text-small-btn tracking-fat mb-1 uppercase bg-turquoise hover:opacity-90 p-2-1/2 block text-white rounded-sm",
                                    _vm.addNewRegistrationButtonActive
                                      ? "opacity-100"
                                      : "opacity-50 pointer-events-none"
                                  ],
                                  attrs: {
                                    disabled: !_vm.addNewRegistrationButtonActive
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.addRegistration($event)
                                    }
                                  }
                                },
                                [_vm._v("Add Registration")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "w-full font-bold text-small-btn tracking-fat mb-1 uppercase p-2-1/2 bg-red-600 hover:bg-red-700 block text-white rounded-sm",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.closePopup($event)
                                    }
                                  }
                                },
                                [_vm._v("Nevermind")]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.removingRegistration
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "fixed top-0 left-0 w-full h-full z-1 fixed flex justify-center items-center"
                      },
                      [
                        _c("div", {
                          staticClass:
                            "fixed top-0 left-0 w-full h-full bg-white opacity-75",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.closePopup($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "scale-up-center-slow border-2 border-solid border-dull-blue elative z-10 bg-blue rounded-sm text-white shadow-lg",
                            staticStyle: { width: "355px" }
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "m-0 p-0 text-white p-3 border-b border-solid border-dull-blue text-center"
                              },
                              [_vm._v("Remove Registration")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registrationToRemove,
                                    expression: "registrationToRemove"
                                  }
                                ],
                                staticClass:
                                  "pointers-events-none w-full mx-auto xs:mb-1 meta:mb-0 border-1 bg-yolk text-center font-bold text-black tracking-normal p-3 rounded-sm border-solid border-yolk-dark uppercase text-sm",
                                attrs: { disabled: "" },
                                domProps: { value: _vm.registrationToRemove },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.registrationToRemove =
                                      $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              !_vm.additionalRegistrationAllowed
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-white text-micro leading-tight mt-2 font-bold"
                                    },
                                    [
                                      _vm._v(
                                        "*Please note that once you remove this registration, you may not add another until after " +
                                          _vm._s(
                                            _vm.momentDate(
                                              this.seasonTicket.reg_timer,
                                              "Do MMMM YYYY HH:mm"
                                            )
                                          ) +
                                          "."
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-4" }, [
                              _c(
                                "button",
                                {
                                  class: [
                                    "dusk-remove-registration w-full font-bold text-small-btn tracking-fat mb-1 uppercase bg-turquoise hover:opacity-90 p-2-1/2 block text-white rounded-sm"
                                  ],
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeRegistration()
                                    }
                                  }
                                },
                                [_vm._v("Remove Registration")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "w-full font-bold text-small-btn tracking-fat mb-1 uppercase p-2-1/2 bg-red-600 hover:bg-red-700 block text-white rounded-sm",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.closePopup($event)
                                    }
                                  }
                                },
                                [_vm._v("Nevermind")]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "text-sm text-white font-bold block flex items-center mb-2 xs:justify-center sm:justify-start",
        attrs: { href: "/my-account" }
      },
      [
        _c("img", {
          staticClass: "w-2 h-auto mr-1",
          attrs: { src: "/img/small-chevron-white.svg", alt: "" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "text-meta mr-1" }, [
          _vm._v("Back to my account")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-1" }, [
      _c("img", { attrs: { src: "/img/pin-turquoise.svg" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-2 mt-nudge" }, [
      _c("img", { attrs: { src: "/img/voucher.svg", width: "14" } })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-420c7467", { render: render, staticRenderFns: staticRenderFns })
  }
}